import UnarchiveFilledIcon from "@material-ui/icons/Unarchive";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import UnarchiveRoundedIcon from "@material-ui/icons/UnarchiveRounded";
import UnarchiveSharpIcon from "@material-ui/icons/UnarchiveSharp";
import UnarchiveTwoToneIcon from "@material-ui/icons/UnarchiveTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const unarchiveIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return UnarchiveFilledIcon;
        case IconStyle.Outlined:
            return UnarchiveOutlinedIcon;
        case IconStyle.Rounded:
            return UnarchiveRoundedIcon;
        case IconStyle.TwoTone:
            return UnarchiveTwoToneIcon;
        case IconStyle.Sharp:
            return UnarchiveSharpIcon;
    }
};

export const UnarchiveIcon = createIconComponent(unarchiveIconSelector);
