import { useCallback } from "react";
import { useQueryStringKey } from "use-route-as-state";

export function useQueryStringKeyArray(
    key: string,
    defaultValue?: string[]
): [string[], React.Dispatch<React.SetStateAction<string[]>>] {
    const [value, setValue] = useQueryStringKey(key, defaultValue);
    const valueStringArray = value as string[];
    const setValueStringArray: React.Dispatch<React.SetStateAction<string[]>> = useCallback(
        (valueOrFunction) => {
            if (typeof valueOrFunction === "function") {
                return setValue((prevValue) => valueOrFunction(prevValue as string[]));
            }
            return setValue(valueOrFunction);
        },
        [setValue]
    );
    return [valueStringArray, setValueStringArray];
}
