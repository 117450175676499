import { useMediaQuery } from "@material-ui/core";
import { EditorDidMount } from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import { useUserSettings } from "./Api";
import { MonacoTheme } from "./UserSettings";

type MonacoThemeState = { name: string; data?: monaco.editor.IStandaloneThemeData };

export const fixThemeName = (name: MonacoTheme): string => name.replace(/[\s_()]/g, "");

const loadTheme = async (theme: MonacoTheme): Promise<monaco.editor.IStandaloneThemeData> =>
    (await import(`monaco-themes/themes/${theme}.json`)) as monaco.editor.IStandaloneThemeData;

const builtInThemes = [MonacoTheme.Dark, MonacoTheme.Light, MonacoTheme.HighContrast];

const useMonacoTheme = (): MonacoThemeState | undefined => {
    const { monacoTheme } = useUserSettings();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true });
    const [theme, setTheme] = useState<MonacoThemeState>();

    useEffect(() => {
        (async function loadThemeEffect() {
            const theme =
                monacoTheme !== MonacoTheme.OperatingSystem
                    ? monacoTheme
                    : prefersDarkMode
                    ? MonacoTheme.Zenburnesque
                    : MonacoTheme.GitHub;
            if (builtInThemes.includes(theme)) {
                setTheme({ name: monacoTheme });
            } else {
                const data = await loadTheme(theme);
                setTheme({ name: fixThemeName(theme), data });
            }
        })();
    }, [monacoTheme, prefersDarkMode]);

    return theme;
};

export const useMonaco = (): {
    editorProps: { editorDidMount: EditorDidMount; theme: string };
    editor?: monaco.editor.IStandaloneCodeEditor;
} => {
    const theme = useMonacoTheme();
    return useMonacoHardCoded(theme);
};

export const useMonacoHardCoded = (props?: {
    name: string;
    data?: monaco.editor.IStandaloneThemeData;
}): {
    editorProps: { editorDidMount: EditorDidMount; theme: string };
    editor?: monaco.editor.IStandaloneCodeEditor;
} => {
    const name = props?.name;
    const data = props?.data;
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
    const editor = editorRef.current;
    const [isEditorReady, setIsEditorReady] = useState(false);

    const handleEditorDidMount: EditorDidMount = (getEditorValue, editor) => {
        editorRef.current = editor as monaco.editor.IStandaloneCodeEditor;
        setIsEditorReady(true);
    };

    const setThemeEffect = (): void => {
        if (!(name && editor)) {
            return;
        }
        data && monaco.editor.defineTheme(name, data);
        monaco.editor.setTheme(name);
    };
    useEffect(setThemeEffect, [editorRef, name, data, isEditorReady, editor]);

    const editorProps = { editorDidMount: handleEditorDidMount, theme: name || "" };
    return { editorProps, editor };
};
