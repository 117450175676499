import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAtom } from "jotai";
import * as React from "react";
import { useEffect } from "react";
import { AppConfig } from "../../AppConfig";
import { Auth } from "../Auth";
import { authAtom } from "../authAtom";
import { logAuth } from "../logAuth";

export const AzureAdAuthAdapterProvider: React.FC<React.PropsWithChildren<{ appConfig: AppConfig }>> = ({
    appConfig,
    children,
}) => {
    const { scopes } = appConfig;
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();
    const account = accounts?.[0];
    const [auth, setAuth] = useAtom(authAtom);

    logAuth("[AzureAdAuthAdapterProvider]", "inProgress", inProgress);
    const isLoginInProgress =
        !isAuthenticated &&
        (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect);
    logAuth("[AzureAdAuthAdapterProvider]", "isLoginInProgress", isLoginInProgress);

    useEffect(() => {
        logAuth("[AzureAdAuthAdapterProvider]", "account", account);
        setAuth({
            isAccessTokenNeeded: true,
            isLoginInProgress,
            isUserLoggedIn: isAuthenticated,
            login() {
                return instance.loginRedirect({ scopes });
            },
            logout() {
                if (account) {
                    return instance.logout({ account: instance.getActiveAccount() });
                }
            },
            getUserRoles() {
                return Promise.resolve([]);
            },
            async getAccessToken() {
                if (!account) {
                    throw new Error("Failed to get access token because user is not logged in.");
                }

                const response = await instance.acquireTokenSilent({
                    scopes,
                    account,
                });

                return response.accessToken;
            },
            account: { name: account?.name, userName: account?.username },
        } as Auth);
    }, [account, isLoginInProgress, instance, isAuthenticated, scopes, setAuth]);

    return auth ? <>{children}</> : null;
};
