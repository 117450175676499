import { TwinOakAppPropsStrict } from "./types";

/**
 * This state-related variable should only be used internally.  They should not be used directly by customer apps.
 */
export const defaultTwinOakAppProps: TwinOakAppPropsStrict = {
    shortSiteName: "",
    headerLogo: undefined,
    buildPalette: (palette) => palette,
    buildRoutes: (routes) => routes,
    buildMainMenuElements: (menuElements) => menuElements,
    apiInterceptors: {
        rpgMenuTree: (menuTree) => menuTree,
    },
};
