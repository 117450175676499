import ky from "ky";
import md5 from "md5";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { FallbackAvatar } from "../UserSettings";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";

type Parameters = {
    email: string;
    fallbackAvatar: FallbackAvatar;
};

export function getStandardGravatarUrl({ email, fallbackAvatar }: Parameters): string {
    return `https://www.gravatar.com/avatar/${md5(email?.toLowerCase() || "")}.png?d=${fallbackAvatar}`;
}

export const gravatarUrlQueryKey = "gravatar-url";

/** This is used to prevent displaying a broken img tag. */
export function useVerifiedGravatarUrlQuery({ email, fallbackAvatar }: Parameters) {
    return useQuery(
        useMemo(
            () => ({
                queryKey: [gravatarUrlQueryKey, email, fallbackAvatar],
                queryFn: async () => {
                    const url = getStandardGravatarUrl({ email, fallbackAvatar });
                    try {
                        await ky(url);
                        return url;
                    } catch {
                        return "";
                    }
                },
                queryOptionsDataChangesInfrequently,
            }),
            [email, fallbackAvatar]
        )
    );
}
