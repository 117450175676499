import * as React from "react";
import { useMemo } from "react";
import { createLogger } from "./log";
import { Snack } from "./Snack";
import { useSetSnack, useSnack } from "./SnackContext";

export const log = createLogger("SnackContainer");

export const SnackContainer: React.FC = () => {
    const { setSnack } = useSetSnack();
    const snack = useSnack();
    return useMemo(() => <Snack {...snack} onClose={() => setSnack(undefined)} />, [setSnack, snack]);
};
