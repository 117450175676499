import queryString from "query-string";
import { useMemo } from "react";
import { useAppConfig } from "../AppConfig";
import { useAuth } from "../Auth";
import { ExtendedRequestInit } from "./ExtendedRequestInit";
import { FetchFunc } from "./fetchFuncTypes";

/**
 * Add the current access token to a fetch init parameter.
 * @param init The init argument used with a call to fetch.
 */
function withAuthHeaders(init: RequestInit | undefined, accessToken: string) {
    const headers = { Authorization: `Bearer ${accessToken}` };
    if (!init) {
        init = { headers };
    } else if (!init.headers) {
        init.headers = headers;
    } else {
        init.headers = {
            ...init.headers,
            ...headers,
        };
    }
    return init;
}

/**
 * React hook that returns an enhanced version of the built-in js `fetch` function that adds an auth token to the header.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * @see https://fetch.spec.whatwg.org/
 */
export const useAuthorizedFetch = (): {
    authorizedFetch: FetchFunc;
} => {
    const { apiBaseUrl } = useAppConfig();
    const { isAccessTokenNeeded, getAccessToken } = useAuth();
    return useMemo(() => {
        async function authorizedFetch(relativeUrlPath: string, init?: ExtendedRequestInit): Promise<Response> {
            const qs = init?.query === undefined ? "" : `?${queryString.stringify(init?.query)}`;
            const apiBaseUrlObject = new URL(apiBaseUrl);
            const url = new URL(`${relativeUrlPath}${qs}`, apiBaseUrlObject).toString();
            if (isAccessTokenNeeded) {
                const accessToken = await getAccessToken();
                init = await withAuthHeaders(init, accessToken);
            }
            return fetch(url, init);
        }

        return { authorizedFetch };
    }, [apiBaseUrl, isAccessTokenNeeded, getAccessToken]);
};
