import queryString from "query-string";

const convertToLocalStorageVariableName = (name: string) =>
    `TwinOak.FeatureFlags.${name.substr(0, 1).toUpperCase()}${name.substr(1)}`;

export function ensureLocalStorageVariableExists(name: string): void {
    const variableName = convertToLocalStorageVariableName(name);
    if (localStorage.getItem(variableName) === null) {
        localStorage.setItem(variableName, "false");
    }
}

function isLocalStorageVariableTrue(name: string) {
    const variableName = convertToLocalStorageVariableName(name);
    const variableValue = localStorage.getItem(variableName);
    return variableValue === true.toString();
}

function queryParameterExistsIgnoreCase(name: string) {
    const query = queryString.parse(window.location.search);
    const parameters = Object.keys(query);
    const hasParameter = parameters.some((p) => p.toLowerCase() === name.toLowerCase());
    return hasParameter;
}

export function isFlagEnabled(name: string) {
    return isLocalStorageVariableTrue(name) || queryParameterExistsIgnoreCase(name);
}

// http://localhost:3000/?whyDidYouRender

interface FeatureFlags {
    whyDidYouRender: boolean;
    reactQueryDevtools: boolean;
    reactQueryDisableRefetchOnWindowFocus: boolean;
    logSpinReasons: boolean;
    logSearch: boolean;
    logAuth: boolean;
    logDateTimePicker: boolean;
    mockChecklistCreation: boolean;
    enableSortPrintJobsByCreatorName: boolean;
    logProcedurePage: boolean;
    logMergeOverlaidScreens: boolean;
    logGreenScreenComponent: boolean;
    logConnectivity: boolean;
    disableAppInsights: boolean;
    enableReactQueryOfflineCache: boolean;
    showPrinterListInMainMenu: boolean;
    enableFavorites: boolean;
    enableAmazonStyleChecklistSummaryQuery: boolean;
    enableChecklistSummaryQueryPresets: boolean;
}

/**
 * For all diagnostic feature flags, the default is false.  This is the behavior expected in production.  Setting a
 * flag to true indicates you want more diagnostics or a more developer friendly configuration.
 *
 * Why create an object where all the values are false?  It simplifies the implementation of buildFeatureFlagObject.
 */
const defaultFeatureFlags: FeatureFlags = {
    whyDidYouRender: false,
    reactQueryDevtools: false,
    reactQueryDisableRefetchOnWindowFocus: false,
    logSpinReasons: false,
    logSearch: false,
    logAuth: false,
    logDateTimePicker: false,
    mockChecklistCreation: false,
    enableSortPrintJobsByCreatorName: false,
    logProcedurePage: false,
    logMergeOverlaidScreens: false,
    logGreenScreenComponent: false,
    logConnectivity: false,
    disableAppInsights: false,
    enableReactQueryOfflineCache: false,
    showPrinterListInMainMenu: false,
    enableFavorites: false,
    enableAmazonStyleChecklistSummaryQuery: false,
    enableChecklistSummaryQueryPresets: false,
};

/**
 * Read feature flag settings from local storage and the current url.  The values are only ever read once when the app
 * starts.  A flag is enabled if either of the following are true:
 *
 * - a query parameter exists with the same name as the flag (case-insensitive), or
 * - a local storage variable exists named TwinOak.Debug.{flag name pascal case} is set to the exact string "true".
 *
 * The naming of the local storage variables is a weird vanity thing, so let's initialize them all to "false" so it's
 * easier for a developer to find them.
 */
function buildFeatureFlagObject(): FeatureFlags {
    const flagNames = Object.keys(defaultFeatureFlags).map((x) => x as keyof FeatureFlags);
    flagNames.sort();
    flagNames.forEach((k) => ensureLocalStorageVariableExists(k));
    const flagKvps = flagNames.map((name) => ({ name, value: isFlagEnabled(name) }));
    const enabledFlagNames = flagKvps.filter((f) => f.value).map((f) => f.name);
    console.log(`Diagnostic feature${enabledFlagNames.length > 1 ? "s" : ""} enabled: ${enabledFlagNames.join(", ")}`);
    return flagKvps.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {}) as FeatureFlags;
}

export const featureFlags = buildFeatureFlagObject();
