import { StringifiableRecord } from "query-string";
import { useCallback } from "react";
import { QueryObserverResult, useMutation, useQuery } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { buildUrlWithQuery } from "./buildUrlWithQuery";
import { downloadReponseToDisk } from "./downloadReponseToDisk";
import { PagedFileResult } from "./PagedResult";

export enum DataFileSource {
    MyFiles = "myFiles",
    DataFiles = "dataFiles",
}

export enum DataFileConversion {
    NoConversion = "noConversion",
    Unpack = "unpack",
}

export interface FileQueryParameters extends StringifiableRecord {
    fileSource?: DataFileSource;
    pageSize?: number;
    pageNumber?: number;
    conversion?: DataFileConversion;
    usePrimaryKeyIndex?: boolean;
    asDownload?: boolean;
}

function apiUrl(fileName: string, parameters?: FileQueryParameters) {
    const relativeUrl = `files/${encodeURIComponent(fileName)}`;
    return parameters ? buildUrlWithQuery(relativeUrl, parameters) : relativeUrl;
}

export function useDataFile(fileName: string, parameters?: FileQueryParameters): QueryObserverResult<PagedFileResult> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const getDataFile = useCallback(
        async (fileName: string, parameters?: FileQueryParameters) => {
            const url = apiUrl(fileName, parameters);
            const response = await authorizedFetch(url);
            if (response.status >= 200 && response.status < 300) {
                return (await response.json()) as PagedFileResult;
            } else {
                showError(ErrorMessage.FailedToLoadDataFile, response);
                throw new Error(ErrorMessage.FailedToLoadDataFile);
            }
        },
        [authorizedFetch, showError]
    );

    const query = useQuery(["file", fileName, parameters], () => getDataFile(fileName, parameters), {
        staleTime: 1 * 60 * 1000,
    });

    const url = apiUrl(fileName, parameters);
    useSpinnerEffect(query.isLoading, url);

    return query;
}

interface DownloadDataFileParameters {
    fileName: string;
    fileSource?: DataFileSource;
}

export function useDownloadDataFileMutation() {
    const { authorizedFetch } = useAuthorizedFetch();

    const downloadDataFile = useCallback(
        async ({ fileName, fileSource }: DownloadDataFileParameters) => {
            const downloadUrl = apiUrl(fileName, { fileSource, asDownload: true });
            const response = await authorizedFetch(downloadUrl);
            await downloadReponseToDisk(response);
        },
        [authorizedFetch]
    );

    const mutation = useMutation(downloadDataFile);

    return mutation;
}
