import * as React from "react";
import { featureFlags } from "./featureFlags";

/**
 * Load why-did-you-render if the feature flag is enabled.
 *
 * https://github.com/welldone-software/why-did-you-render#setup
 */
export async function setupWdyr() {
    if (featureFlags.whyDidYouRender) {
        const whyDidYouRender = await import("@welldone-software/why-did-you-render");
        whyDidYouRender.default(React, {
            trackAllPureComponents: true,
        });
    }
}
