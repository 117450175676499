import * as React from "react";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { checklistQueryKey } from "../types";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistId: string;
    checklistStepId: string;
    checklistStepName?: string;
}

export function useCompleteChecklistStepMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(completeChecklistStep, {
        onSuccess: (data, mutationVariables) => {
            const queryKey: QueryKey = checklistQueryKey(mutationVariables.checklistId);
            if (data) {
                queryCache.setQueryData(queryKey, data);
            } else {
                queryCache.invalidateQueries(queryKey);
            }
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    async function completeChecklistStep({ checklistId, checklistStepId, checklistStepName }: Parameters) {
        const apiUrl = `checklists/${checklistId}/steps/${checklistStepId}/complete`;
        const response = await authorizedFetch(apiUrl, {
            method: "POST",
        });
        if (response.status >= 200 && response.status < 300) {
            setSnack(checklistStepName ? <>Completed step: &ldquo;{checklistStepName}&rdquo;.</> : `Completed step.`);
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
                throw ex;
            }
        } else {
            showError(ErrorMessage.FailedToCompleteStep, response);
        }
    }

    return mutation;
}
