import React, { lazy, Suspense } from "react";
import { Spinner } from "../Spinner";

const fallback = <Spinner spinning={true} />;

function withSpinSuspense<P = {}>(Component: React.ComponentType<P>): React.ComponentType<P> {
    const enhanced: React.FC<P> = (props) => (
        <Suspense fallback={fallback}>
            <Component {...props} />
        </Suspense>
    );
    return enhanced;
}

export const ApiDashboard = withSpinSuspense(lazy(() => import("./ApiDashboard")));
export const ChecklistCreationParameterPage = withSpinSuspense(lazy(() => import("./ChecklistCreationParameterPage")));
export const ChecklistCreationTemplatePickerPage = withSpinSuspense(
    lazy(() => import("./ChecklistCreationTemplatePickerPage"))
);
export const ChecklistDetailPage = withSpinSuspense(lazy(() => import("./ChecklistDetailPage")));
export const ChecklistListPage = withSpinSuspense(lazy(() => import("./ChecklistListPage")));
export const DataFilePage = withSpinSuspense(lazy(() => import("./DataFilePage")));
export const Explorer = withSpinSuspense(lazy(() => import("./Explorer")));
export const Help = withSpinSuspense(lazy(() => import("./Help")));
export const Home = withSpinSuspense(lazy(() => import("./Home")));
export const HubPage = withSpinSuspense(lazy(() => import("./HubPage")));
export const MenuListPage = withSpinSuspense(lazy(() => import("./MenuListPage")));
export const MenuPage = withSpinSuspense(lazy(() => import("./MenuPage")));
export const PrinterListPage = withSpinSuspense(lazy(() => import("./PrinterListPage")));
export const PrinterPage = withSpinSuspense(lazy(() => import("./PrinterPage")));
export const PrintJobListPage = withSpinSuspense(lazy(() => import("./PrintJobListPage")));
export const PrintJobPage = withSpinSuspense(lazy(() => import("./PrintJobPage")));
export const ProcedurePage = withSpinSuspense(lazy(() => import("./ProcedurePage")));
export const Profile = withSpinSuspense(lazy(() => import("./Profile")));
export const ScreenListPage = withSpinSuspense(lazy(() => import("./ScreenListPage")));
export const ScreenPage = withSpinSuspense(lazy(() => import("./ScreenPage")));
