const adjustForNan = (num: number): number => (isNaN(num) ? 0 : num);
const adjustForTooSmall = (num: number): number => (num < 1 ? 1 : num);
const adjustForTooLarge =
    (max: number) =>
    (num: number): number =>
        num > max ? max : num;

const adjustLineNumber = (num: number, max: number): number =>
    adjustForTooLarge(max)(adjustForTooSmall(adjustForNan(num)));

const normalizeLineEndingsLikeATextAreaDoes = (text: string): string => text.replace(/\r\n|\r|\n/g, "\n");

/**
 * Get a range selection for a text area that selects a line of text.  It does its best to hightlight something that
 * makes sense.
 * @param options.value the text
 * @param options.startLineNumber first line number to highlight
 * @param options.endLineNumber last line number to highlight
 */
export const getRangeForLineSelection: (options: { value: string; startLineNumber: number; endLineNumber: number }) => {
    start: number;
    end: number;
} = (options) => {
    const value = normalizeLineEndingsLikeATextAreaDoes(options.value);
    const lines = value.split("\n");

    if (options.startLineNumber > lines.length && options.endLineNumber > lines.length) {
        return {
            start: value.length,
            end: value.length,
        };
    }

    const adjustedStart = adjustLineNumber(options.startLineNumber, lines.length);
    const adjustedEnd = adjustLineNumber(options.endLineNumber, lines.length);

    const startLineNumber = Math.min(adjustedStart, adjustedEnd);
    const endLineNumber = Math.max(adjustedStart, adjustedEnd);

    let lineNumber = 1,
        start = 0;
    while (lineNumber < startLineNumber) {
        const eolAdjustment = lineNumber === lines.length ? 0 : 1;
        start += lines[lineNumber - 1].length + eolAdjustment;
        lineNumber++;
    }

    if (endLineNumber > lines.length) {
        return {
            start,
            end: value.length,
        };
    }

    let end = start;
    while (lineNumber < endLineNumber + 1) {
        const eolAdjustment = lineNumber === lines.length ? 0 : 1;
        end += lines[lineNumber - 1].length + eolAdjustment;
        lineNumber++;
    }

    return { start, end };
};
