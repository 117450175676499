import { Button, createStyles, makeStyles, Theme, Toolbar } from "@material-ui/core";
import { EditorProps } from "@monaco-editor/react";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { useUserSettings } from "./Api";
import { AppTerminalWorkspace } from "./AppLayout";
import { getRangeForLineSelection } from "./getRangeForLineSelection";
import { PrintIcon, SaveAltIcon } from "./Icons";
import { createLogger } from "./log";
import { ResizableMonacoEditor } from "./ResizableMonacoEditor";
import { useMonaco } from "./useMonaco";

export const log = createLogger("Report");

const useMonacoLineHighlightStyles = makeStyles(() =>
    createStyles({
        inline: {
            fontWeight: "bold",
            fontStyle: "oblique",
        },
        linesDecorations: {
            background: "lightblue",
            width: "5px !important",
            marginLeft: "3px",
        },
    })
);

interface ReportProps {
    content: string;
    language?: string;
    firstHighlightedLine?: number;
    lastHighlightedLine?: number;
    onDownload?(): void;
    onPrint?(): void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarButton: {
            marginRight: theme.spacing(2),
        },
        textarea: {
            whiteSpace: "pre-wrap",
            width: "100%",
            height: "100%",
        },
    })
);

const defaultMonacoDecorations: string[] = [];

export const Report: React.FC<ReportProps> = (props) => {
    const { content, language, firstHighlightedLine, lastHighlightedLine, onDownload, onPrint } = props;
    const [refocusTextArea, setRefocusTextArea] = useState(false);
    const editorActionButtonsDisabled = !content;

    const classes = useStyles();
    const [monacoDecorations, setMonacoDecorations] = useState<string[]>(defaultMonacoDecorations);

    const monacoLineHighlightClasses = useMonacoLineHighlightStyles();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const textArea = textAreaRef.current;

    // This is needed to make the selection visible.
    useEffect(
        function refocusTextAreaEffect() {
            if (!refocusTextArea) {
                return;
            }
            textArea?.blur();
            textArea?.focus();
            setRefocusTextArea(false);
        },
        [refocusTextArea, textArea]
    );

    const { enableMonaco } = useUserSettings();
    const { editor, editorProps } = useMonaco();

    useEffect(
        function handleContentChanged() {
            editor?.focus();
            textArea?.focus();
        },
        [content, editor, props, textArea]
    );

    const monacoProps: EditorProps = useMemo(
        () => ({
            language,
            value: content,
            line: firstHighlightedLine,
            ...editorProps,
            options: {
                readOnly: false,
                automaticLayout: false,
            },
        }),
        [content, editorProps, firstHighlightedLine, language]
    );

    useEffect(
        function highlightLinesEffect() {
            if (!(editor || textArea)) {
                return;
            }

            if (firstHighlightedLine) {
                const rangeStartLine = firstHighlightedLine;
                const rangeEndLine = lastHighlightedLine === undefined ? firstHighlightedLine : lastHighlightedLine;
                if (enableMonaco) {
                    if (!editor) {
                        throw new Error("highlightLinesEffect !editor");
                    }
                    const range = new monaco.Range(rangeStartLine, 1, rangeEndLine, Number.MAX_SAFE_INTEGER);
                    const decorations = editor.deltaDecorations(monacoDecorations, [
                        {
                            range,
                            options: {
                                isWholeLine: true,
                                inlineClassName: monacoLineHighlightClasses.inline,
                                linesDecorationsClassName: monacoLineHighlightClasses.linesDecorations,
                            },
                        },
                    ]);
                    setMonacoDecorations((prevState) => (isEqual(prevState, decorations) ? prevState : decorations));

                    editor.setSelection(range);
                    editor.revealRangeInCenter(range, monaco.editor.ScrollType.Smooth);
                } else {
                    if (!textArea) {
                        throw new Error("highlightLinesEffect !textArea");
                    }
                    const { start, end } = getRangeForLineSelection({
                        value: content,
                        startLineNumber: rangeStartLine,
                        endLineNumber: rangeEndLine,
                    });
                    textArea.setSelectionRange(start, end);

                    setRefocusTextArea(true);
                }
            } else {
                if (enableMonaco) {
                    if (!editor) {
                        throw new Error("!editor");
                    }
                    const decorations = editor.deltaDecorations(monacoDecorations, []);
                    setMonacoDecorations(decorations);

                    const range = new monaco.Range(1, 1, 1, 1);
                    editor.setSelection(range);
                    editor.revealRangeAtTop(range, monaco.editor.ScrollType.Smooth);
                } else {
                    if (!textArea) {
                        throw new Error("!textArea");
                    }
                    textArea.setSelectionRange(0, 0);
                    setRefocusTextArea(true);
                }
            }
        },
        [
            firstHighlightedLine,
            lastHighlightedLine,
            editor,
            textArea,
            content,
            enableMonaco,
            monacoDecorations,
            monacoLineHighlightClasses.inline,
            monacoLineHighlightClasses.linesDecorations,
        ]
    );

    return useMemo(() => {
        if (!content) {
            return null;
        }

        const myEditor = !enableMonaco ? (
            <textarea
                value={content}
                className={classes.textarea}
                ref={textAreaRef}
                readOnly
                style={{ whiteSpace: "pre" }}
            />
        ) : (
            <ResizableMonacoEditor {...monacoProps} />
        );

        return (
            <AppTerminalWorkspace>
                <Toolbar>
                    <Button
                        variant="contained"
                        className={classes.toolbarButton}
                        startIcon={<SaveAltIcon />}
                        disabled={editorActionButtonsDisabled || !onDownload}
                        onClick={() => {
                            onDownload?.();
                        }}
                    >
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<PrintIcon />}
                        disabled={editorActionButtonsDisabled || !onPrint}
                        onClick={() => {
                            onPrint?.();
                        }}
                    >
                        Print
                    </Button>
                </Toolbar>
                {myEditor}
            </AppTerminalWorkspace>
        );
    }, [
        classes.textarea,
        classes.toolbarButton,
        content,
        editorActionButtonsDisabled,
        enableMonaco,
        monacoProps,
        onDownload,
        onPrint,
    ]);
};
