import { useCallback, useMemo } from "react";
import { QueryObserverResult, useMutation, useQuery, UseQueryOptions } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { downloadReponseToDisk } from "./downloadReponseToDisk";
import { encodeURIPath } from "./encodeURIPath";
import { useKy } from "./useKy";

function apiUrl(container: string, path: string) {
    return `blobs/${container}/${encodeURIPath(path)}`;
}

export function useBlob(
    container: string,
    path: string,
    queryOptions?: Omit<UseQueryOptions<string, unknown, string, string[]>, "queryKey" | "queryFn">
): QueryObserverResult<string> {
    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();

    const getBlob = useCallback(
        async (container: string, path: string) => {
            const url = apiUrl(container, path);
            const response = await authorizedFetch(url);

            if (response.status >= 200 && response.status < 300) {
                return (await response.text()) as string;
            } else {
                showError(ErrorMessage.FailedToLoadDataFile, response);
                throw new Error(ErrorMessage.FailedToLoadChecklist);
            }
        },
        [authorizedFetch, showError]
    );

    const query = useQuery(["blob", container, path], () => getBlob(container, path), {
        staleTime: 1 * 60 * 1000,
        ...queryOptions,
    });

    const url = apiUrl(container, path);
    useSpinnerEffect(query.isLoading, url);

    return query;
}

interface DownloadBlobParameters {
    container: string;
    path: string;
}

export function useDownloadBlobMutation() {
    const ky = useKy();
    const showError = useShowError();
    return useMutation<void, Error, DownloadBlobParameters>(
        useMemo(
            () => ({
                mutationFn: async ({ container, path }: DownloadBlobParameters) => {
                    const downloadUrl = apiUrl(container, path);
                    const response = await ky(downloadUrl, { searchParams: { asAttachment: true } });
                    await downloadReponseToDisk(response);
                },
                onError: (error) => {
                    showError(ErrorMessage.FailedToLoadBlob, error);
                },
            }),
            [ky, showError]
        )
    );
}
