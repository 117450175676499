import RemoveFilledIcon from "@material-ui/icons/Remove";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import RemoveTwoToneIcon from "@material-ui/icons/RemoveTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const removeIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return RemoveFilledIcon;
        case IconStyle.Outlined:
            return RemoveOutlinedIcon;
        case IconStyle.Rounded:
            return RemoveRoundedIcon;
        case IconStyle.TwoTone:
            return RemoveTwoToneIcon;
        case IconStyle.Sharp:
            return RemoveSharpIcon;
    }
};

export const RemoveIcon = createIconComponent(removeIconSelector);
