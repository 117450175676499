export enum ReportListOrderBy {
    CreatedOn = "createdOn",
    Name = "name",
    CreatedByUserId = "createdByUserId",
    CreatedByProcedureLibrary = "createdByProcedureLibrary",
    CreatedByProcedureName = "createdByProcedureName",
    CreatedByProgramLibrary = "createdByProgramLibrary",
    CreatedByProgramName = "createdByProgramName",
}

export enum PrintJobListOrderBy {
    CreatedDate = "createdDate",
    Name = "name",
    CreatorName = "creatorName",
    CreatedByProcedureLibrary = "createdByProcedureLibrary",
    CreatedByProcedureName = "createdByProcedureName",
    CreatedByProgramLibrary = "createdByProgramLibrary",
    CreatedByProgramName = "createdByProgramName",
    Status = "status",
}

export enum OrderByDirection {
    Ascending = "ascending",
    Descending = "descending",
}

export type ReportListParameters = {
    pageNumber: number;
    pageSize: number;
    orderBy?: ReportListOrderBy;
    orderDirection?: OrderByDirection;
    createdByUserId?: string;
};

export type PrintJobListParameters = {
    pageNumber: number;
    pageSize: number;
    orderBy?: PrintJobListOrderBy;
    orderDirection?: OrderByDirection;
    creatorId?: string;
};

export interface RpgProcedure {
    library: string;
    name: string;
}

export interface RpgProgram {
    library: string;
    name: string;
}

export interface CreateReportInfo {
    name: string;
    createdByUserId: string;
    createdByProcedure: RpgProcedure;
    createdByProgram: RpgProgram;
}

export interface ReportInfo extends CreateReportInfo {
    id: string;
    createdOn: Date;
}

export enum PrintFormat {
    Ascii = "ascii",
    Asa = "asa",
}

enum ModernPrinterDuplex {
    Default,
    Simplex,
    Vertical,
    Horizontal,
}

export interface ModernPrinterSettings {
    duplex?: ModernPrinterDuplex;
    collate?: boolean;
    printerName: string;
    paperSourceName?: string;
    paperSizeCustomWidth?: number;
    paperSizeCustomHeight?: number;
    printFileName?: string;
    printToFile?: boolean;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
}

export interface ModernFont {
    familyName: string;
    size: number;
}

export interface Printer {
    id: string;
    name: string;
    physicalName: string;
    supportedFormats: PrintFormat[];
    printerSettings: ModernPrinterSettings;
    font: ModernFont;
    overwriteFile: boolean;
}

export enum PrintDisposition {
    /** Don't print it yet, just create the job. */
    Hold = "hold",

    /** Create the job, print it now, and then delete the job if it printed. */
    ReleaseAndDelete = "releaseAndDelete",

    /** Create the job, print it now, and then hold the job. */
    ReleaseAndSave = "releaseAndHold",
}

export enum PrintJobStatus {
    /** Do not print. */
    Held = "held",

    /** A print request has put in the Windows print queue. */
    Queueing = "queueing",

    /** A print request has put in the Windows print queue. */
    Queued = "queued",

    /** A printer is servicing this request now. */
    Printing = "printing",

    /** Request has been printed. */
    Printed = "printed",

    /** Job has completed with success. */
    Succeeded = "succeeded",

    /** Print was attempted and failed.  There was a problem. */
    Failed = "failed",

    /** Soft delete. */
    Deleted = "deleted",
}

export interface ByLogicalNamePrinterSpecification {
    printerLogicalName: string;
    _type: "logicalName";
}

export interface ByIdPrinterSpecification {
    printerId: string;
    _type: "id";
}

export type PrinterSpecification = ByLogicalNamePrinterSpecification | ByIdPrinterSpecification;

export function isByLogicalNamePrinterSpecification(x: PrinterSpecification): x is ByLogicalNamePrinterSpecification {
    return "printerLogicalName" in x;
}

export function isByIdPrinterSpecification(x: PrinterSpecification): x is ByIdPrinterSpecification {
    return "printerId" in x;
}

export interface PrintJob {
    id: string;
    name: string;
    createdDate: Date;
    creatorId: string;
    printDocumentDescriptorId: string;
    printerSpecification: PrinterSpecification;
    disposition: PrintDisposition;
    status: PrintJobStatus;
}

export interface CreatePrintJobAggregate {
    printJobId: string;
    printJobName: string;
    printJobCreatedDate: Date;
    printJobCreatorId: string;
    printerSpecification: PrinterSpecification;
    disposition: PrintDisposition;
    status: PrintJobStatus;
    statusDetails: string;
    numberOfCopies: number;
    printJobCreatedByProcedure: RpgProcedure;
    printJobCreatedByProgram: RpgProgram;
    printDocumentDescriptorId: string;
    printDocumentDescriptorName: string;
    printDocumentDescriptorCreatedDate: Date;
    format: PrintFormat;
    printDocumentId: string;
    printDocumentDescriptorCreatedByProcedure: RpgProcedure;
    printDocumentDescriptorCreatedByProgram: RpgProgram;
}

export interface PrintJobAggregate extends CreatePrintJobAggregate {
    id: string;
    createdDate: Date;
}
