import queryString from "query-string";
import { useEffect, useState } from "react";
import { RouteProps, useLocation, useRouteMatch } from "react-router-dom";
import { buildUrlWithQuery, encodeURIPath } from "./Api";
import {
    DataFileMenuItemIdentifier,
    DataFileMode,
    DataFileProcedureMode,
    qbePrefix,
    RecordAbsolutePosition,
    RecordDirection,
    RecordId,
} from "./DataFile/DataFileTypes";

/**
 * A less awful version of useRouteMatch.  Based on the url below with added TypeScript types.
 *
 * https://github.com/remix-run/react-router/issues/7059#issuecomment-628783747
 */
export function useStableRouteMatch<Params extends { [K in keyof Params]?: string } = {}>(
    path: string | string[] | RouteProps
) {
    const _match = useRouteMatch<Params>(path);
    const location = useLocation();
    const [match, setMatch] = useState(_match);
    useEffect(() => {
        setMatch(_match);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return match;
}

//
// Main menu items
//

export const urlToHomePage = "/";
export const routeToHomePage: RouteProps = { path: "/", exact: true };
export const useRouteMatchHomePage = () => useStableRouteMatch(routeToHomePage);

export const urlToHelpPage = "/help";
export const routeToHelpPage: RouteProps = { path: "/help", exact: true };
export const useRouteMatchHelpPage = () => useStableRouteMatch(routeToHelpPage);

export const urlToProfilePage = "/profile";
export const routeToProfilePage: RouteProps = { path: "/profile", exact: true };

export const urlToMenuListPage = "/menus";
export const routeToMenuListPage: RouteProps = { path: "/menus", exact: true };

export const urlToMenuPage = ({ library, menu }: MenuPageParams) => `/menus/${library}/${menu}`;
export const routeToMenuPage: RouteProps = { path: "/menus/:library/:menu", exact: true };
export interface MenuPageParams {
    library: string;
    menu: string;
}
export const useRouteMatchMenuPage = () => useStableRouteMatch<MenuPageParams>(routeToMenuPage);

export const urlToProcedurePage = ({
    library,
    procedure,
    procedureParameters,
}: ProcedurePageParams & { procedureParameters?: string }) =>
    buildUrlWithQuery(`/procedures/${library}/${procedure}`, { procedureParameters });
export interface ProcedurePageParams {
    library: string;
    procedure: string;
}
export const routeToProcedurePage: RouteProps = { path: "/procedures/:library/:procedure", exact: true };
export const useRouteMatchProcedurePage = () => useStableRouteMatch<ProcedurePageParams>(routeToProcedurePage);

export const urlToScreenListPage = "/screens";
export const routeToScreenListPage: RouteProps = { path: "/screens", exact: true };

export const urlToScreenPage = ({ library, screen }: ScreenPageParams) => `/screens/${library}/${screen}`;
export interface ScreenPageParams {
    library: string;
    screen: string;
}
export const routeToScreenPage: RouteProps = { path: "/screens/:library/:screen", exact: true };
export const useRouteMatchScreenPage = () => useStableRouteMatch<ScreenPageParams>(routeToScreenPage);

export const urlToApiDashboardPage = "/api-dashboard";
export const routeToApiDashboardPage: RouteProps = { path: "/api-dashboard" };

export const urlToPrintJobListPage = "/print-jobs";
export const routeToPrintJobListPage: RouteProps = { path: "/print-jobs", exact: true };

export const urlToPrintJobPage = (printJobId: string) => `/print-jobs/${printJobId}`;
export const routeToPrintJobPage: RouteProps = { path: "/print-jobs/:printJobId", exact: true };
export const useRouteMatchPrintJobPage = () => useStableRouteMatch(routeToPrintJobPage);

export const urlToExplorerMainPage = "/explorer";

export const urlToExplorerPage = (path: string) => `/explorer/${encodeURIPath(path)}`;
export const routeToExplorerPage: RouteProps = { path: "/explorer/:path*" };
export const useRouteMatchExplorerPage = () => useStableRouteMatch(routeToExplorerPage);

//
// Checklists
//

export const routeToHubPage: RouteProps = { path: "/hubs/:hubPageId" };
export const urlToHubPage = (hubPageId: string) => `/hubs/${hubPageId}`;
export interface HubPageParams {
    hubPageId: string;
}
export const useRouteMatchHubPage = () => useStableRouteMatch(routeToHubPage);

export const routeToChecklistCreationTemplatePickerPage: RouteProps = {
    path: "/checklists/create",
    exact: true,
};
export const routeToChecklistCreationParameterPage: RouteProps = {
    path: "/checklists/create/:template",
    exact: true,
};

export const urlToCreateChecklistPage: (checklistTemplateId?: string) => string = (checklistTemplateId) =>
    checklistTemplateId ? `/checklists/create/${checklistTemplateId}` : "/checklists/create";

export const routeToChecklistDetailPage: RouteProps = { path: "/checklists/:checklistId" };
export const urlToChecklistDetailPage: (checklistId: string) => string = (checklistId) => `/checklists/${checklistId}`;

export const routeToConnectivityPage: RouteProps = { path: "/connectivity" };
export const urlToConnectivityPage = "/connectivity";

export const routeToChecklistListPage: RouteProps = { path: "/checklists", exact: true };
export const urlToChecklistListPage = "/checklists";

//
// Data files
//

export const routesToDataFilePages: Array<Omit<RouteProps, "path"> & { path: string }> = [
    "/data-files",
    "/data-files/:dfuName/:fileName",
].map((path) => ({
    path,
    exact: true,
}));

/** Parameters needed to create a url to a data file when procedure resume is *not* needed. */
export type UrlToDataFileParametersWithoutProcedureResume = DataFileMenuItemIdentifier & {
    /** Which tab should be selected in the DFU page? */
    dataFileMode?: DataFileMode;
};

/** Parameters needed to create a url to a data file when procedure resume *is* needed. */
export type UrlToDataFileParametersWithProcedureResume = DataFileMenuItemIdentifier & {
    /** library of the procedure we were invoked from */
    returnToLibrary: string;
    /** name of the procedure we were invoked from */
    returnToProcedure: string;
    /** parameters initially passed to the procedure we were invoked from */
    returnToProcedureParameters?: string;
    /** most recent step path of the procedure we were invoked from */
    returnToStepPath?: string;
    /** how was the dfu was invoked from a procedure: Inquery, update, or enter? */
    dataFileProcedureMode: DataFileProcedureMode;
};

export function isUrlToDataFileParametersWithProcedureResume(
    x: UrlToDataFileParameters
): x is UrlToDataFileParametersWithProcedureResume {
    return "returnToProcedure" in x;
}

export interface DataFilePageParams {
    dfuName: string;
    fileName: string;
}

/**
 * Parameters needed to create a url to a data file.  Sometimes this type includes parameters needed to resume
 * procedure execution.
 */
export type UrlToDataFileParameters =
    | UrlToDataFileParametersWithoutProcedureResume
    | UrlToDataFileParametersWithProcedureResume;

export const urlToDataFile: (
    options: UrlToDataFileParameters & {
        recordNumber?: number;
        recordId?: RecordId;
        recordDirection?: RecordDirection;
        recordAbsolutePosition?: RecordAbsolutePosition;
    }
) => string = (options) => {
    const { dfuName, fileName, ...queryObj } = options;
    const qs = queryString.stringify(queryObj);
    const url = `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}${qs ? `?${qs}` : ""}`;
    return url;
};

export const urlToDataFileSearchByExample: (
    options: DataFileMenuItemIdentifier,
    recordType?: string,
    example?: Record<string, string>
) => string = ({ dfuName, fileName, ...queryObj }, recordType, example) => {
    const qbe =
        example === undefined || recordType === undefined
            ? undefined
            : Object.keys(example).reduce((acc, next) => ({ ...acc, [`${qbePrefix}${next}`]: example[next] }), {
                  recordType,
              });

    const queryStringObj = { ...qbe, ...queryObj };
    const qs = Object.keys(queryStringObj).length === 0 ? "" : `?${queryString.stringify(queryStringObj)}`;
    return ["/data-files/", encodeURIComponent(dfuName), "/", encodeURIComponent(fileName), qs].join("");
};

export const urlToDataFileCanonical: (
    options: UrlToDataFileParameters & {
        recordId?: RecordId;
    }
) => string = (options) => {
    // TODO: Consider whether some query parameters should be removed.  For example, when navigating to the "first" or
    // "last" record, maybe we should just preserve the record id.  We need to keep parameters related to resuming a
    // procedure.
    const { dfuName, fileName, ...queryObj } = options;
    const qs = queryString.stringify(queryObj);
    return `/data-files/${encodeURIComponent(dfuName)}/${encodeURIComponent(fileName)}?${qs}`;
};

/*
Possibly urls:

"You figure it out" - all I know is what dfu/file to work on.  Page decides how to initialize mode.
http://localhost:3000/data-files/utcust/UT.CUST

http://localhost:3000/data-files/utcust/UT.CUST/searchByRecordNumber

http://localhost:3000/data-files/utcust/UT.CUST/searchByExample
*/

export const urlToPrinterListPage = "/printers";
export const routeToPrinterListPage: RouteProps = { path: "/printers", exact: true };

export const urlToPrinterPage = (printerId: string) => `/printers/${printerId}`;
export type PrinterPageParams = {
    printerId: string;
};
export const routeToPrinterPage: RouteProps = { path: "/printers/:printerId" };

const addPrinterUrlSegment = "add-printer";
export const urlToAddPrinterPage = `/printers/${addPrinterUrlSegment}`;
export const isAddPrinterSpecialId = (printerId: string) => printerId === addPrinterUrlSegment;
