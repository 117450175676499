import { atom, useAtomValue, useSetAtom } from "jotai";
import { defaultTwinOakAppProps } from "./defaultTwinOakAppProps";

const appCustomizationsAtom = atom(defaultTwinOakAppProps);

/**
 * These state-related functions should only be used internally.  They should not be used directly by customer apps.
 */
export function useAppCustomizations() {
    return useAtomValue(appCustomizationsAtom);
}

/**
 * These state-related functions should only be used internally.  They should not be used directly by customer apps.
 */
export function useSetAppCustomizations() {
    return useSetAtom(appCustomizationsAtom);
}
