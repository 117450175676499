import {
    ChecklistSummariesQuery,
    ChecklistSummariesSimpleQuery,
    ChecklistSummaryOrderBy,
    InboxContainerProps,
} from "../types";
import { csharpIntMaxValue } from "./csharpIntMaxValue";

type InboxData = Pick<InboxContainerProps, "title" | "apiUrl" | "isLegacyApi">;

const defaultHubPageSimpleQuery: ChecklistSummariesSimpleQuery = {
    filterType: "simple",
    orderBy: ChecklistSummaryOrderBy.CompletedDate,
    pageNumber: 1,
    pageSize: csharpIntMaxValue,
};

// const defaultHubPageLogicalQuery: ChecklistSummariesLogicalQuery = {
//     filterType: "logical",
//     orderBy: ChecklistSummaryOrderBy.CompletedDate,
//     pageNumber: 1,
//     pageSize: csharpIntMaxValue,
//     expression: undefined,
// };

const myTasksInboxes: InboxData[] = [
    {
        title: "Not completed",
        filter: "notCompleted",
    },
    {
        title: "Completed",
        filter: "completed",
    },
    {
        title: "All",
        filter: "all",
    },
].map(({ title, filter }) => ({
    title,
    apiUrl: `checklist-summaries/mylist?filter=${filter}`,
    isLegacyApi: true,
}));

const departmentInboxes: InboxData[] = [
    {
        title: "Utility",
        dept: "utility",
    },
    {
        title: "Computer",
        dept: "computer",
    },
    {
        title: "Administration",
        dept: "administration",
    },
    {
        title: "Mail room",
        dept: "mailRoom",
    },
].map(({ title, dept }) => ({
    title,
    apiUrl: `checklist-summaries/hub?dept=${dept}`,
    isLegacyApi: true,
}));

// // Use this code to generate the list further below.
// const supervisorInboxes: InboxData[] = ["active", "completed", "all"]
//     .map((filter) =>
//         ["late", "onTime", "all"].map((lateFilter) => ({
//             title: `filter: ${filter} lateFilter: ${lateFilter}`,
//             apiUrl: `checklist-summaries?filter=${filter}&lateFilter=${lateFilter}`,
//         }))
//     )
//     .flat();

// console.log("const supervisorInboxes: InboxData[] = ", JSON.stringify(supervisorInboxes, null, 4));

const unarchivedQueryParameters: Partial<ChecklistSummariesQuery> = {
    isArchived: false,
};

const activeQueryParameters: Partial<ChecklistSummariesQuery> = {
    isStarted: true,
    isCompleted: false,
};

const completedQueryParameters: Partial<ChecklistSummariesQuery> = {
    isCompleted: true,
};

const lateQueryParameters: Partial<ChecklistSummariesQuery> = {
    isLate: true,
};

const onTimeQueryParameters: Partial<ChecklistSummariesQuery> = {
    isLate: false,
};

const supervisorInboxes: InboxData[] = [
    {
        title: "Active late",
        apiUrl: {
            ...defaultHubPageSimpleQuery,
            ...unarchivedQueryParameters,
            ...activeQueryParameters,
            ...lateQueryParameters,
        },
    },
    {
        title: "Active on-time",
        apiUrl: {
            ...defaultHubPageSimpleQuery,
            ...unarchivedQueryParameters,
            ...activeQueryParameters,
            ...onTimeQueryParameters,
        },
    },
    {
        title: "Active",
        apiUrl: { ...defaultHubPageSimpleQuery, ...unarchivedQueryParameters, ...activeQueryParameters },
    },
    {
        title: "Completed late",
        apiUrl: {
            ...defaultHubPageSimpleQuery,
            ...unarchivedQueryParameters,
            ...completedQueryParameters,
            ...lateQueryParameters,
        },
    },
    {
        title: "Completed on-time",
        apiUrl: {
            ...defaultHubPageSimpleQuery,
            ...unarchivedQueryParameters,
            ...completedQueryParameters,
            ...onTimeQueryParameters,
        },
    },
    {
        title: "Completed",
        apiUrl: { ...defaultHubPageSimpleQuery, ...unarchivedQueryParameters, ...completedQueryParameters },
    },
    {
        title: "Late",
        apiUrl: { ...defaultHubPageSimpleQuery, ...unarchivedQueryParameters, ...lateQueryParameters },
    },
    {
        title: "On-time",
        apiUrl: { ...defaultHubPageSimpleQuery, ...unarchivedQueryParameters, ...onTimeQueryParameters },
    },
    {
        title: "All",
        apiUrl: { ...defaultHubPageSimpleQuery, ...unarchivedQueryParameters },
    },
];

const unscheduledInboxes: InboxData[] = [
    {
        title: "Unscheduled",
        apiUrl: { ...defaultHubPageSimpleQuery, isArchived: false, isScheduled: false },
    },
    {
        title: "Scheduled, but not started",
        apiUrl: { ...defaultHubPageSimpleQuery, isArchived: false, isScheduled: true, isStarted: false },
    },
];

export interface HubPageData {
    spaUrlParam: string;
    pageTitle: string;
    menuItemText: string;
    inboxes: InboxData[];
    showBadgeWithCountFromUrlOrQuery?: string | ChecklistSummariesQuery;
    isLegacyApi?: boolean;
}

/**
 * Static data describing the hub pages.
 */
const hubPages: HubPageData[] = [
    {
        spaUrlParam: "my-tasks",
        pageTitle: "My Tasks",
        menuItemText: "My Tasks",
        inboxes: myTasksInboxes,
        showBadgeWithCountFromUrlOrQuery: "checklist-summaries/mylist?filter=notCompleted",
    },
    {
        spaUrlParam: "departments",
        pageTitle: "Hub",
        menuItemText: "Hub",
        inboxes: departmentInboxes,
        showBadgeWithCountFromUrlOrQuery: "checklist-summaries/hub",
    },
    {
        spaUrlParam: "supervisor",
        pageTitle: "Supervisor",
        menuItemText: "Supervisor",
        inboxes: supervisorInboxes,
    },
    {
        spaUrlParam: "unscheduled",
        pageTitle: "Unscheduled",
        menuItemText: "Unscheduled",
        inboxes: unscheduledInboxes,
        showBadgeWithCountFromUrlOrQuery:
            "checklist-summaries/simple-query?isArchived=false&isScheduled=false&pageNumber=1&pageSize=9999",
    },
];

export const useHubPages: () => HubPageData[] = () => hubPages;

export const getHubPages: () => HubPageData[] = () => hubPages;
