import { useAtom } from "jotai";
import { User, UserManager, UserManagerEvents, UserManagerSettings } from "oidc-client";
import * as React from "react";
import { useEffect, useState } from "react";
import { AppConfig } from "../../AppConfig";
import { authAtom } from "../authAtom";
import { logAuth } from "../logAuth";

const signInPathname = "/signin-oidc";
const signOutPathname = "/signout-callback-oidc";

export const OidcAuthProvider: React.FC<React.PropsWithChildren<{ appConfig: AppConfig }>> = ({
    appConfig,
    children,
}) => {
    const [auth, setAuth] = useAtom(authAtom);

    const [userManager] = useState(() => {
        const url = window.location.origin;

        const settings: UserManagerSettings = {
            authority: appConfig.authority,
            client_id: appConfig.clientId,
            redirect_uri: url + signInPathname,
            post_logout_redirect_uri: url + signOutPathname,
            response_type: "code",
            scope: [
                ...appConfig.scopes,
                ...(appConfig.scopes.includes("openid") ? [] : ["openid"]),
                ...(appConfig.scopes.includes("profile") ? [] : ["profile"]),
            ].join(" "),

            popup_redirect_uri: url,
            popup_post_logout_redirect_uri: url,

            silent_redirect_uri: url,
            automaticSilentRenew: false,
            validateSubOnSilentRenew: true,

            filterProtocolClaims: true,
            loadUserInfo: true,
            revokeAccessTokenOnSignout: true,
        };

        logAuth("creating a new user manager", settings);

        return new UserManager(settings);
    });

    const [user, setUser] = useState<User>();

    const handleUserLoaded: UserManagerEvents.UserLoadedCallback = (user: User) => {
        logAuth("[event]", "UserLoaded event", user);
        setUser(user);
    };
    const handleUserUnloaded: UserManagerEvents.UserUnloadedCallback = () => {
        logAuth("[event]", "UserUnloaded");
    };
    const handleSilentRenewError: UserManagerEvents.SilentRenewErrorCallback = (error: Error) => {
        logAuth("[event]", "SilentRenewError", error);
    };
    const handleUserSignedOut: UserManagerEvents.UserSignedOutCallback = () => {
        logAuth("[event]", "UserSignedOut");
    };
    const handleUserSessionChanged: UserManagerEvents.UserSessionChangedCallback = () => {
        logAuth("[event]", "UserSessionChanged");
    };

    useEffect(
        function registeringEventHandlersEffect() {
            logAuth("registering event handlers", userManager);

            userManager.events.addUserLoaded(handleUserLoaded);
            userManager.events.addUserUnloaded(handleUserUnloaded);
            userManager.events.addSilentRenewError(handleSilentRenewError);
            userManager.events.addUserSignedOut(handleUserSignedOut);
            userManager.events.addUserSessionChanged(handleUserSessionChanged);

            const loadUser: () => Promise<void> = async () => {
                const user = await userManager.getUser();
                logAuth("loadUser", user);
                if (user) {
                    setUser(user);
                }
            };

            loadUser();

            return () => {
                logAuth("deregistering event handlers", userManager);

                userManager.events.removeUserLoaded(handleUserLoaded);
                userManager.events.removeUserUnloaded(handleUserUnloaded);
                userManager.events.removeSilentRenewError(handleSilentRenewError);
                userManager.events.removeUserSignedOut(handleUserSignedOut);
                userManager.events.removeUserSessionChanged(handleUserSessionChanged);
            };
        },
        [userManager]
    );

    const { pathname } = window.location;

    useEffect(
        function signinRedirectEffect() {
            if (userManager && pathname === signInPathname) {
                userManager.signinRedirectCallback();
            }
        },
        [userManager, pathname]
    );

    const userExpired = user?.expired;
    useEffect(() => {
        setAuth({
            isAccessTokenNeeded: true,
            isLoginInProgress: false,
            isUserLoggedIn: userExpired === false,
            login() {
                return userManager.signinRedirect();
            },
            logout() {
                return userManager.signoutRedirect();
            },
            getUserRoles() {
                return Promise.resolve([]);
            },
            async getAccessToken() {
                const user = await userManager.getUser();
                if (!user) {
                    return "";
                }
                const { access_token } = user;
                return access_token;
            },
            account: { name: "tbd", userName: "tbd" },
        });
    }, [setAuth, userExpired, userManager]);

    logAuth("isUserLoggedIn", user?.expired === false);

    return auth ? <>{children}</> : null;
};
