import { SnackbarProps } from "@material-ui/core";
import { IExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";
import { useCallback } from "react";
import { useAppInsights } from "./AppInsights";
import { ErrorMessage } from "./ErrorMessage";
import { isProblemDetails, ProblemDetails } from "./ProblemDetails";
import { SetSnack, useSetSnack } from "./SnackContext";
import { SnackState } from "./SnackState";
import { HTTPError } from "ky";
import { TwinOakHttpError } from "./Api/TwinOakHttpError";

/**
 * Object that best represents the original error.
 */
export type ErrorSource = ProblemDetails | Error | Response;

export type ShowError = (
    userMessage: ErrorMessage | string,
    source?: ErrorSource,
    snackbarProps?: SnackbarProps
) => Promise<void>;

const defaultErrorSnack: Pick<SnackState, "severity"> = {
    severity: "error",
};

const defaultAppInsightsException: Partial<IExceptionTelemetry> = {
    severityLevel: SeverityLevel.Error,
};

export async function tryGetProblemDetails(error: ErrorSource): Promise<ProblemDetails | undefined> {
    if (isProblemDetails(error)) {
        return error;
    }

    const response =
        error instanceof Response
            ? error
            : error instanceof HTTPError || error instanceof TwinOakHttpError
            ? error.response
            : undefined;
    if (!response) {
        return undefined;
    }

    let text: string;
    try {
        text = await response.text();
    } catch (e: unknown) {
        console.warn("Error loading response content.", e);
        return undefined;
    }

    let obj: unknown;
    try {
        obj = JSON.parse(text);
    } catch (e: unknown) {
        console.warn("Error parsing response content as json.", { text }, e);
        return undefined;
    }

    return isProblemDetails(obj) ? obj : undefined;
}

const showErrorNoDetails = (options: {
    userMessage: string;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, appInsights, setSnack, snackbarProps } = options;
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            properties: { userMessage },
        });
    const snack: SnackState = {
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    };
    setSnack(snack);
};

const showErrorError = (options: {
    userMessage: string;
    error: Error;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, error, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        errorName: error.name,
        errorMessage: error.message,
        stackTrace: error.stack,
    };
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            exception: error,
            properties,
        });
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const showProblemDetailsError = (options: {
    userMessage: string;
    problemDetails: ProblemDetails;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, problemDetails, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        errorStatus: problemDetails.status,
        errorType: problemDetails.type,
        errorTitle: problemDetails.title,
        errorDetail: problemDetails.detail,
        errorException: problemDetails.exception,
    };
    appInsights?.trackException &&
        appInsights.trackException({
            ...defaultAppInsightsException,
            properties,
        });
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const showObjectError = (options: {
    userMessage: string;
    source: Response;
    appInsights: Initialization | undefined;
    setSnack: SetSnack;
    snackbarProps?: SnackbarProps;
}): void => {
    const { userMessage, source: error, appInsights, setSnack, snackbarProps } = options;
    const properties = {
        userMessage,
        ...error,
    };
    try {
        // not the stupidest thing I've ever done
        throw new Error(userMessage);
    } catch (error: any) {
        appInsights?.trackException?.({
            ...defaultAppInsightsException,
            exception: error,
            properties,
        });
    }
    setSnack({
        ...defaultErrorSnack,
        snackbarProps,
        children: userMessage,
    });
};

const consoleMessageSectionSeparator = "--";
const groupHeadingStyle = `color: red;  font-size: 1.25em; font-weight: bold`;
enum LogGroupHeadings {
    ServerException = "Server exception",
    ClientException = "Client exception",
    ClientApiCallObjects = "Client api call objects",
    ServerReturnedProblemDetails = "Server returned ProblemDetails",
}

function consoleGroupCollapsed(label: LogGroupHeadings) {
    console.groupCollapsed(`%c${label}`, groupHeadingStyle);
}

async function logError(userMessage: ErrorMessage | string, source?: ErrorSource, problemDetails?: ProblemDetails) {
    if (source === undefined) {
        console.error(
            "👈 Expand for browser stack trace",
            consoleMessageSectionSeparator,
            `User error message: "${userMessage}"`
        );
        return;
    }

    if (source instanceof Error && source.stack) {
        console.error(
            `User error message: "${userMessage}"`,
            consoleMessageSectionSeparator,
            `Exception message: "${source.message}"`,
            consoleMessageSectionSeparator,
            "More details below. 👇"
        );
    } else if (source) {
        console.error(
            "👈 Expand for (messy) client call stack",
            consoleMessageSectionSeparator,
            `User error message: "${userMessage}"`,
            consoleMessageSectionSeparator,
            "More details below. 👇"
        );
    }

    const serverSideStackTrace = problemDetails ? getServerStackTrace(problemDetails) : undefined;
    if (serverSideStackTrace) {
        consoleGroupCollapsed(LogGroupHeadings.ServerException);
        console.log(serverSideStackTrace);
        console.groupEnd();
        consoleGroupCollapsed(LogGroupHeadings.ServerReturnedProblemDetails);
        console.log(problemDetails);
        console.groupEnd();
    } else if (problemDetails) {
        consoleGroupCollapsed(LogGroupHeadings.ServerReturnedProblemDetails);
        console.log(problemDetails);
        console.groupEnd();
    }

    if (source instanceof HTTPError) {
        const { options, request, response, stack } = source;
        if (stack) {
            consoleGroupCollapsed(LogGroupHeadings.ClientException);
            console.log(stack);
            console.groupEnd();
        }
        consoleGroupCollapsed(LogGroupHeadings.ClientApiCallObjects);
        console.log(
            "Below are the ky options object used initiate the api call along with the request and response objects."
        );
        console.log(options);
        console.log(request);
        console.log(response);
        console.groupEnd();
    } else if (source instanceof TwinOakHttpError) {
        const { input, init, response, stack } = source;
        if (stack) {
            consoleGroupCollapsed(LogGroupHeadings.ClientException);
            console.log(source);
            console.trace("Detailed stack trace");
            console.groupEnd();
        }
        consoleGroupCollapsed(LogGroupHeadings.ClientApiCallObjects);
        console.log(
            "Below are the input and init objects used initiate the api call along with the response, if available."
        );
        console.log(input);
        console.log(init);
        if (response) {
            console.log(response);
        }
        console.groupEnd();
    } else if (source instanceof Error) {
        consoleGroupCollapsed(LogGroupHeadings.ClientException);
        console.log(source);
        console.groupEnd();
    } else if (source instanceof Response) {
        consoleGroupCollapsed(LogGroupHeadings.ClientApiCallObjects);
        console.log(
            "Below is the response object returned by the server.  We would have more details here if this api call were made using ky."
        );
        console.log(source);
        console.groupEnd();
    } else {
        // `source` is a ProblemDetails object.  We would have processed this earlier.
    }
}

function getServerStackTrace(problemDetails?: ProblemDetails) {
    return (problemDetails?.exceptionDetails || []).map((ed) => ed.raw).join("\n\n");
}

export const useShowError = (): ShowError => {
    const appInsights = useAppInsights();
    const { setSnack } = useSetSnack();
    return useCallback(
        async function showError(userMessage, source, snackbarProps) {
            const problemDetails = source ? await tryGetProblemDetails(source) : undefined;
            const defaultArgs = { userMessage, appInsights, setSnack, snackbarProps };
            if (source === undefined) {
                showErrorNoDetails(defaultArgs);
            } else if (isProblemDetails(source)) {
                showProblemDetailsError({ ...defaultArgs, problemDetails: source });
            } else if (problemDetails) {
                showProblemDetailsError({ ...defaultArgs, problemDetails });
            } else if (source instanceof Error) {
                showErrorError({ ...defaultArgs, error: source });
            } else {
                showObjectError({ ...defaultArgs, source });
            }
            await logError(userMessage, source, problemDetails);
        },
        [appInsights, setSnack]
    ) as ShowError;
};

export const useShowErrorDetails = (): ShowError => {
    const showError = useShowError();
    return useCallback(
        async function showErrorDetails(userMessage, source, snackbarProps) {
            const problemDetails = source ? await tryGetProblemDetails(source) : undefined;
            return showError(problemDetails?.detail || userMessage, source, snackbarProps);
        },
        [showError]
    ) as ShowError;
};
