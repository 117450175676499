import { createStyles, fade, InputBase, makeStyles, Theme } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import * as H from "history";
import debounce from "lodash.debounce";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useChecklistSummariesUnpagedImperative, useChecklistTemplates, WebApiChecklistSummary } from "./Api";
import { convertMenuTreeToList, useMenuTree } from "./Api/useMenuTree";
import { Command, isDataFileUtilityCommand, isMenuCommand, isProcedureCommand } from "./Command";
import { useShowError } from "./ErrorContext";
import { featureFlags } from "./featureFlags";
import { SearchIcon } from "./Icons";
import { createLogger, noopLogger } from "./log";
import {
    urlToChecklistDetailPage,
    urlToDataFile,
    urlToMenuPage,
    urlToProcedurePage,
    useRouteMatchHelpPage,
    useRouteMatchHomePage,
    useRouteMatchHubPage,
    useRouteMatchMenuPage,
} from "./Routing";
import { ChecklistSummariesQuery, MenuIdentifier, MenuItem } from "./types";

const log = featureFlags.logSearch ? createLogger("Search") : noopLogger;

export enum SearchResultType {
    NoMatch = "NoMatch",
    Checklist = "Checklist",
    Menu = "Menu",
    MenuItem = "Menu item",
}

export interface SearchResultBase {
    showAsFirstResult?: boolean;
}

export interface ChecklistSearchResult extends SearchResultBase {
    label: string;
    type: SearchResultType.Checklist;
    checklistId: string;
}

export interface MenuSearchResult extends SearchResultBase {
    label: string;
    type: SearchResultType.Menu;
    menu: MenuIdentifier;
}

export interface MenuItemSearchResult extends SearchResultBase {
    label: string;
    type: SearchResultType.MenuItem;
    menu: MenuIdentifier;
    menuItem: MenuItem;
}

export interface NoMatchSearchResult extends SearchResultBase {
    label: string;
    type: SearchResultType.NoMatch;
}

export type SearchResult = MenuSearchResult | MenuItemSearchResult | NoMatchSearchResult | ChecklistSearchResult;

const searchResultTypeOrder: Record<SearchResultType, number> = {
    NoMatch: 0,
    Menu: 1,
    "Menu item": 2,
    Checklist: 3,
};

export const compareSearchResult: (a: SearchResult, b: SearchResult) => number = (a, b) => {
    if (a.showAsFirstResult !== b.showAsFirstResult) {
        return a.showAsFirstResult ? -1 : 1;
    }
    if (a.type !== b.type) {
        return searchResultTypeOrder[a.type] - searchResultTypeOrder[b.type];
    }
    return a.label.localeCompare(b.label);
};

function sortSearchResults(results: Array<SearchResult>) {
    return results.sort(compareSearchResult);
}

const convertMenuToSearchResult: (menu: MenuIdentifier) => MenuSearchResult = (menu) => {
    return { label: menu.title, type: SearchResultType.Menu, menu };
};

const convertMenuItemToSearchResult: (options: {
    menuItem: MenuItem;
    menu: MenuIdentifier;
}) => MenuItemSearchResult = ({ menu, menuItem }) => {
    return { label: menuItem.text, type: SearchResultType.MenuItem, menu, menuItem };
};

const invokeCommand = ({ command, history }: { command: Command; history: H.History }) => {
    if (isMenuCommand(command)) {
        history.push(urlToMenuPage(command));
    } else if (isProcedureCommand(command)) {
        history.push(urlToProcedurePage(command));
    } else if (isDataFileUtilityCommand(command)) {
        history.push(urlToDataFile(command));
    } else {
        console.error("invokeCommand - dont know how to invoke", command);
    }
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch",
            },
        },
    })
);

type SearchFunc = (value: string) => Promise<SearchResult[]>;
type SearchFuncSync = (value: string) => SearchResult[];

const useSearchChecklists: () => { searchChecklists: SearchFunc } = () => {
    const { data: checklistTemplates } = useChecklistTemplates();
    const { queryChecklistSummaries } = useChecklistSummariesUnpagedImperative();
    const parameterNames = useMemo(() => {
        return (checklistTemplates || [])
            .map((ct) => ct.parameters)
            .flat()
            .map((p) => p.name)
            .filter((v, i, r) => r.indexOf(v) === i);
    }, [checklistTemplates]);
    const searchChecklists: SearchFunc = useCallback(
        async (value) => {
            const listArrays: WebApiChecklistSummary[][] = await Promise.all(
                parameterNames.map(async (p) => {
                    const query: Partial<ChecklistSummariesQuery> = {
                        filterType: "simple",
                        parameters: { [p]: value },
                    };
                    const { data: summaries } = await queryChecklistSummaries(query);
                    return summaries;
                })
            );

            return listArrays
                .flat()
                .filter((v, i, r) => r.findIndex((elt) => elt.checklistId === v.checklistId) === i)
                .sort((a, b) => (a.checklistName > b.checklistName ? 1 : -1))
                .map(
                    (cs) =>
                        ({
                            label: cs.checklistName,
                            type: SearchResultType.Checklist,
                            checklistId: cs.checklistId,
                        } as ChecklistSearchResult)
                );
        },
        [parameterNames, queryChecklistSummaries]
    );
    return { searchChecklists };
};

function useCurrentMenuDefinition() {
    const menuMatch = useRouteMatchMenuPage();
    const menuTree = useMenuTree();
    return useMemo(() => {
        if (!menuMatch) {
            return undefined;
        }
        const { library, menu } = menuMatch.params;
        const menuContent = menuTree?.[library]?.[menu];
        if (!menuContent) {
            return undefined;
        }
        return {
            library,
            name: menu,
            ...menuContent,
        } as MenuIdentifier;
    }, [menuMatch, menuTree]);
}

const useSearchMenus: () => { searchMenus: SearchFuncSync } = () => {
    const currentMenu = useCurrentMenuDefinition();
    const menuTree = useMenuTree();

    return useMemo(() => {
        function searchMenus(inputValue: string) {
            const menuDefinitions = convertMenuTreeToList(menuTree);

            let matches: SearchResult[] = [];

            const number = Number(inputValue);
            const matchingMenuItem =
                currentMenu && !isNaN(number)
                    ? currentMenu.items.find((i) => i.shortcut === number.toString())
                    : undefined;
            if (currentMenu && matchingMenuItem) {
                matches = [
                    ...matches,
                    {
                        ...convertMenuItemToSearchResult({
                            menu: currentMenu,
                            menuItem: matchingMenuItem,
                        }),
                        showAsFirstResult: true,
                    },
                ];
            } else {
                const searchTerms = inputValue.split(/\s/).filter((x) => x);
                menuDefinitions.forEach((menu) => {
                    if (
                        menu.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                        searchTerms.every((term) => menu.title.toLowerCase().indexOf(term.toLowerCase()) >= 0)
                    ) {
                        matches = [...matches, convertMenuToSearchResult(menu)];
                    }
                    menu.items.forEach((menuItem) => {
                        if (
                            searchTerms.every((term) => menuItem.text.toLowerCase().indexOf(term.toLowerCase()) !== -1)
                        ) {
                            matches = [...matches, convertMenuItemToSearchResult({ menu, menuItem })];
                        }
                    });
                });
            }

            return matches;
        }
        return { searchMenus };
    }, [currentMenu, menuTree]);
};

function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const setDebouncedValueDebounced = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

    useEffect(() => {
        setDebouncedValueDebounced(value);

        return () => setDebouncedValueDebounced.cancel();
    }, [value, delay, setDebouncedValueDebounced]);

    return debouncedValue;
}

/**
 * @returns a function that searches data cached in the spa.
 */
const useSearchClient = () => {
    const { searchMenus } = useSearchMenus();

    return useMemo(() => ({ searchClient: searchMenus }), [searchMenus]);
};

/**
 * @returns a function that searches via an api call.
 */
const useSearchServer = () => {
    const { searchChecklists } = useSearchChecklists();

    return useMemo(() => ({ searchServer: searchChecklists }), [searchChecklists]);
};

const defaultOptions: SearchResult[] = [];

function useFocusRefOnPagesWithNoInputs(focusRef: React.MutableRefObject<HTMLElement | undefined>) {
    const location = useLocation();
    const isOnMenuPage = !!useRouteMatchMenuPage();
    const isOnHubPage = !!useRouteMatchHubPage();
    const isOnHomePage = !!useRouteMatchHomePage();
    const isOnHelpPage = !!useRouteMatchHelpPage();
    const shouldFocus = isOnHomePage || isOnMenuPage || isOnHubPage || isOnHelpPage;

    const focus = useCallback(() => {
        focusRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(
        function handleChangeUrl() {
            if (shouldFocus) {
                focus();
            }
        },
        [shouldFocus, focus, location]
    );

    return focusRef;
}

const noMatchesSearchResult: SearchResult[] = [{ label: "no matches", type: SearchResultType.NoMatch }];

export const Search: React.FC = () => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState("");
    const debouncedInputValue = useDebounce(inputValue, 500);
    const [clientSearchResults, setClientSearchResults] = useState<SearchResult[]>(defaultOptions);
    const [serverSearchResults, setServerSearchResults] = useState<SearchResult[]>(defaultOptions);
    const options = useMemo(() => {
        const combinedSearchResults = [...clientSearchResults, ...serverSearchResults];
        return sortSearchResults(combinedSearchResults.length === 0 ? noMatchesSearchResult : combinedSearchResults);
    }, [clientSearchResults, serverSearchResults]);

    const showError = useShowError();

    const { searchClient } = useSearchClient();
    const { searchServer } = useSearchServer();
    const isOnMenuPage = !!useRouteMatchMenuPage();

    // Search input is handled two ways:
    // 1. Some searches are super fast and the user wants to have them available ASAP.  A common scenario to help
    // imagine this is users who use their keyboard and the <Search> component to quickly navigate menus and menu items.
    // 2. Some searches take longer to service, so that work is only triggered after the user has stopped typing for a
    // short period of time.  This is a common behavior in software that many users are familiar with.
    useEffect(
        function handleSearchInstant() {
            if (!inputValue) {
                setClientSearchResults(defaultOptions);
                return;
            }
            const searchResults = searchClient(inputValue);
            log("[searchInstant]", { inputValue, searchResults });
            setClientSearchResults(searchResults);
        },
        [inputValue, isOnMenuPage, searchClient]
    );

    useEffect(
        function handleSearchDebounced() {
            if (!debouncedInputValue) {
                setServerSearchResults(defaultOptions);
                return;
            }

            (async function searchDebounced() {
                const searchResults = await searchServer(debouncedInputValue);
                console.log("[searchDebounced]", { debouncedInputValue, searchResults });
                setServerSearchResults(searchResults);
            })();
        },
        [debouncedInputValue, searchServer]
    );

    const history = useHistory();

    const searchElementRef = React.useRef<HTMLElement>();
    useFocusRefOnPagesWithNoInputs(searchElementRef);

    return useMemo(
        () => (
            <Autocomplete
                autoHighlight
                clearOnEscape
                forcePopupIcon={false}
                style={{ width: 400 }}
                filterOptions={(x) => x}
                options={options}
                value={null}
                onChange={(
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    _event: React.ChangeEvent<{}>,
                    newValue: string | SearchResult | null,
                    _reason: AutocompleteChangeReason
                ) => {
                    if (typeof newValue === "string") {
                        // This should not happen while freeSolo is set to false.
                        // https://material-ui.com/api/autocomplete/#props
                        showError(
                            "There was a problem with autocomplete",
                            new Error(`AutoComplete onChange event received an string type for newValue: "${newValue}"`)
                        );
                    } else if (newValue?.type === SearchResultType.Checklist) {
                        const { checklistId } = newValue;
                        const href = urlToChecklistDetailPage(checklistId);
                        href && history.push(href);
                    } else if (newValue?.type === SearchResultType.Menu) {
                        const { library, name: menu } = newValue.menu;
                        history.push(urlToMenuPage({ library, menu }));
                    } else if (newValue?.type === SearchResultType.MenuItem) {
                        const { command } = newValue.menuItem;
                        invokeCommand({ command, history });
                    }
                }}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={({ InputLabelProps: _, InputProps, ...rest }) => (
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <InputBase
                            inputRef={searchElementRef}
                            {...InputProps}
                            {...rest}
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />
                    </div>
                )}
                getOptionLabel={(_option) => ""}
                renderOption={(option) => {
                    if (option.type === SearchResultType.Checklist) {
                        return <div>{`${option.label}`}</div>;
                    }
                    if (option.type === SearchResultType.Menu) {
                        return <div>{`${option.menu.name.toUpperCase()} - ${option.menu.title}`}</div>;
                    }
                    if (option.type === SearchResultType.MenuItem) {
                        return (
                            <div>{`${option.menuItem.shortcut}. ${
                                option.label
                            } - ${option.menu.name.toUpperCase()}, ${option.menu.library.toUpperCase()}`}</div>
                        );
                    }
                    return <></>;
                }}
                groupBy={(option) => option.type}
            />
        ),
        [classes.inputInput, classes.inputRoot, classes.search, classes.searchIcon, history, options, showError]
    );
};
