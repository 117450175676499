import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import * as React from "react";
import { isByLogicalNamePrinterSpecification, PrinterSpecification, usePrinters } from "../Api";
import { ErrorIcon } from "../Icons";

const usePrinterSpecificationStyles = makeStyles((theme) => ({
    error: {
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
    errorTooltipJson: {
        whiteSpace: "pre",
    },
    noMaxWidth: {
        maxWidth: "none",
    },
}));

export const PrinterSpecificationLabel: React.FC<{ printerSpecification: PrinterSpecification }> = ({
    printerSpecification,
}) => {
    const classes = usePrinterSpecificationStyles();
    const { isLoading, data, error } = usePrinters(printerSpecification, {
        showSpinner: false,
        errorMessage: undefined,
        retry: false,
    });
    if (isLoading) {
        return <>Loading...</>;
    }
    if (error || !data?.length) {
        const tooltip = (
            <Typography variant="body1">
                Failed to find any printers matching specification: <br />
                <span className={classes.errorTooltipJson}>{JSON.stringify(printerSpecification, null, 2)}</span>
            </Typography>
        );
        const message = isByLogicalNamePrinterSpecification(printerSpecification)
            ? printerSpecification.printerLogicalName
            : "Printer not found";
        return (
            <div className={classes.error}>
                <span>{message}</span>
                <Tooltip title={tooltip} classes={{ tooltip: classes.noMaxWidth }}>
                    <div>
                        <ErrorIcon fontSize="default" className={classes.errorIcon} />
                    </div>
                </Tooltip>
            </div>
        );
    }
    return <>{data[0].name}</>;
};
