import ArchiveFilledIcon from "@material-ui/icons/Archive";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import ArchiveSharpIcon from "@material-ui/icons/ArchiveSharp";
import ArchiveTwoToneIcon from "@material-ui/icons/ArchiveTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const archiveIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ArchiveFilledIcon;
        case IconStyle.Outlined:
            return ArchiveOutlinedIcon;
        case IconStyle.Rounded:
            return ArchiveRoundedIcon;
        case IconStyle.TwoTone:
            return ArchiveTwoToneIcon;
        case IconStyle.Sharp:
            return ArchiveSharpIcon;
    }
};

export const ArchiveIcon = createIconComponent(archiveIconSelector);
