import ky, { Options } from "ky";
import { useMemo } from "react";
import { useAppConfig } from "../AppConfig";
import { useAuth } from "../Auth";
import { useUnmountSignal } from "./useUnmountSignal";

const authorizationHeaderName = "authorization";
const apiVersionHeaderName = "x-api-version";

/**
 * Get an instance of `ky` that sets the api base url and adds auth headers.
 * @param options.apiVersion Optionally specifies the api version to invoke.
 */
export function useKy(options?: Options & { apiVersion?: string }) {
    const { isAccessTokenNeeded, getAccessToken } = useAuth();
    const { apiBaseUrl } = useAppConfig();
    const signal = useUnmountSignal();

    return useMemo(
        () =>
            ky.create({
                prefixUrl: apiBaseUrl,
                timeout: false,
                retry: 0,
                signal,
                ...options,
                hooks: {
                    ...options?.hooks,
                    beforeRequest: [
                        ...(options?.hooks?.beforeRequest || []),
                        async function addAuthHeaderIfAbsent(request, _options) {
                            if (!isAccessTokenNeeded || request.headers.has(authorizationHeaderName)) {
                                return;
                            }
                            const accessToken = await getAccessToken();
                            var bearer = `Bearer ${accessToken}`;
                            request.headers.append(authorizationHeaderName, bearer);
                        },
                        async function addApiVersionHeaderIfAbsent(request, _options) {
                            if (options?.apiVersion === undefined || request.headers.has(apiVersionHeaderName)) {
                                return;
                            }
                            request.headers.append(apiVersionHeaderName, options?.apiVersion);
                        },
                    ],
                },
            }),
        [apiBaseUrl, getAccessToken, isAccessTokenNeeded, options, signal]
    );
}

const experimentalApiVersion = "2022-01-01";

export function useKyExperimentalApi(options?: Options) {
    return useKy({ ...options, apiVersion: experimentalApiVersion });
}
