import { StringifiableRecord } from "query-string";
import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { buildUrlWithQuery } from "./buildUrlWithQuery";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { PagedResult } from "./PagedResult";
import { parsePagedResultPrintJobInfo } from "./parsePagedResultPrintJobInfo";
import { OrderByDirection, PrintJobAggregate, PrintJobListParameters } from "./types";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";

function convertGetPrintJobParametersToQueryObj(options?: PrintJobListParameters): StringifiableRecord {
    if (options === undefined) {
        return {};
    }
    const { orderBy, orderDirection, ...rest } = options;
    return {
        ...rest,
        orderBy:
            orderBy === undefined
                ? undefined
                : `${orderBy}${orderDirection === OrderByDirection.Descending ? "Desc" : ""}`,
    };
}

export function useFetchPrintJobAggregateList(): {
    fetchPrintJobAggregateList(parameters: PrintJobListParameters): Promise<PagedResult<PrintJobAggregate>>;
} {
    const queryCache = useQueryClient();
    const ky = useKy();
    const showError = useShowError();

    return useMemo(() => {
        function fetchPrintJobAggregateList(parameters: PrintJobListParameters) {
            return queryCache.fetchQuery(["print-job-aggregates", parameters], async function () {
                let response: Response | undefined;
                try {
                    const queryObj = convertGetPrintJobParametersToQueryObj(parameters);
                    const url = buildUrlWithQuery("print-job-aggregates", queryObj);
                    response = await ky(url);
                    const json = await response.text();
                    try {
                        return parsePagedResultPrintJobInfo(json);
                    } catch (ex) {
                        showError(ErrorMessage.FailedToParseReportList, response);
                        throw ex;
                    }
                } catch (ex) {
                    showError(ErrorMessage.FailedToLoadReportList, response);
                    throw ex;
                }
            });
        }

        return {
            fetchPrintJobAggregateList,
        };
    }, [ky, queryCache, showError]);
}

export function usePrintJobAggregates(parameters?: PrintJobListParameters) {
    const ky = useKy();
    return useTwinOakQuery(
        useMemo(() => {
            console.log("[usePrintJobAggregates]");
            return {
                queryKey: ["print-job-aggregates", JSON.stringify(parameters)],
                queryFn: async function fetchPrintJobAggregates() {
                    console.log("[fetchPrintJobAggregates]");

                    const queryObj = convertGetPrintJobParametersToQueryObj(parameters);
                    const url = buildUrlWithQuery("print-job-aggregates", queryObj);
                    return parsePagedResultPrintJobInfo(await ky(url).text());
                },
                errorMessage: ErrorMessage.FailedToLoadPrintJobs,
            };
        }, [ky, parameters])
    );
}
