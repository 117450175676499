import { useMemo } from "react";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useAuth } from "../Auth";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { cleanseUserSettings, UserSettings } from "../UserSettings";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { TwinOakAssemblies, useDoesApiVersionSatisfy } from "./useAssemblyVersionCheck";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";

const urlToUserSettingsApi = "user-data";

type UserSettingsResponseModelTransient = UserSettings & { spa?: UserSettings };

export const userSettingsQueryKey = urlToUserSettingsApi;

export function useUserSettingsMutation() {
    const queryClient = useQueryClient();
    const showError = useShowError();
    const ky = useKy();
    const doesApiUseTheNewUserSettingsModel = useDoesApiVersionSatisfy(TwinOakAssemblies.Runtime, ">=1.4.11");

    return useMutation<UserSettings, Error, UserSettings>(
        useMemo(
            () => ({
                mutationFn: async (userSettings: UserSettings) => {
                    const input = doesApiUseTheNewUserSettingsModel ? { spa: userSettings } : userSettings;
                    const dirtyOutput = (await ky
                        .put(urlToUserSettingsApi, {
                            json: input,
                        })
                        .json()) as UserSettingsResponseModelTransient;
                    const output = migrateUserSettingsFromApi(dirtyOutput);

                    console.log("useUserSettingsMutation()", { userSettings, input, dirtyOutput, output });
                    return output;
                },
                onSuccess: (data) => {
                    const queryKey: QueryKey = [userSettingsQueryKey];
                    if (data) {
                        queryClient.setQueryData(queryKey, data);
                    } else {
                        queryClient.invalidateQueries(queryKey);
                    }
                },
                onError: (error) => {
                    showError(ErrorMessage.FailedToSaveUserPreferences, error);
                },
            }),
            [doesApiUseTheNewUserSettingsModel, ky, queryClient, showError]
        )
    );
}

export function useUserSettingsQuery() {
    const { isUserLoggedIn } = useAuth();
    const ky = useKy();
    return useTwinOakQuery<UserSettings, Error>(
        useMemo(
            () => ({
                ...queryOptionsDataChangesInfrequently,
                queryKey: [userSettingsQueryKey],
                queryFn: async () => {
                    if (!isUserLoggedIn) {
                        return cleanseUserSettings();
                    }

                    const dirtyOutput = (await ky(urlToUserSettingsApi).json()) as UserSettingsResponseModelTransient;
                    const lessDirtyOutput = migrateUserSettingsFromApi(dirtyOutput);
                    const output = cleanseUserSettings(lessDirtyOutput);

                    console.log("useUserSettingsQuery()", { dirtyOutput, lessDirtyOutput, output });
                    return output;
                },
                showSpinner: false,
                errorMessage: ErrorMessage.FailedToLoadUserPreferences,
            }),
            [isUserLoggedIn, ky]
        )
    );
}

/** We are in the process of moving settings from the root to a spa child. */
function migrateUserSettingsFromApi(value: UserSettingsResponseModelTransient): UserSettings {
    // If it has a "spa" property, remove other root children.
    const { spa, ..._garbage } = value;
    const userSettings =
        spa !== undefined && spa !== null && typeof spa === "object" && Object.keys(spa).length > 0 ? spa : value;
    return userSettings;
}

export function useUserSettings() {
    const { data } = useUserSettingsQuery();
    return data || cleanseUserSettings();
}
