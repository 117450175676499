import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import * as React from "react";
import { MyNetworkInformation, useNetworkInformation } from "./useNetworkInformation";

const useStyles = makeStyles({
    tableContainer: {
        maxWidth: "fit-content",
    },
});

type ValueFormatFn = (value: React.ReactNode | undefined) => React.ReactNode;
const defaultFormat: ValueFormatFn = (x) => {
    return x === undefined ? "Unknown" : x;
};

type ConnectionProp = {
    name: keyof MyNetworkInformation;
    description: React.ReactNode;
    value?: React.ReactNode;
    format?: ValueFormatFn;
};

function applySuffixIfNotBlank(suffix: string) {
    return function (x: React.ReactNode | null | undefined): React.ReactNode {
        return x === undefined || x === null || x === "" ? <></> : `${x}${suffix}`;
    };
}

const connectionDetails: ConnectionProp[] = [
    {
        name: "effectiveType",
        description: (
            <>
                The effective type of the connection meaning one of <code>slow-2g</code>, <code>2g</code>,{" "}
                <code>3g</code>, or <code>4g</code>. This value is determined using a combination of recently observed
                round-trip time and downlink values.
            </>
        ),
    },
    {
        name: "downlink",
        description: (
            <>
                Returns the effective bandwidth estimate in megabits per second, rounded to the nearest multiple of 25
                kilobits per seconds.
            </>
        ),
        format: applySuffixIfNotBlank("Mbps"),
    },
    {
        name: "rtt",
        description: (
            <>
                Returns the estimated effective round-trip time of the current connection, rounded to the nearest
                multiple of 25 milliseconds.
            </>
        ),
        format: applySuffixIfNotBlank("ms"),
    },
    {
        name: "saveData",
        description: (
            <>
                <code>True</code> if the user has set a reduced data usage option on the user agent.
            </>
        ),
        format: (x) => `${!!x}`,
    },
    {
        name: "downlinkMax",
        description: (
            <>The maximum downlink speed, in megabits per second (Mbps), for the underlying connection technology.</>
        ),
        format: applySuffixIfNotBlank("Mbps"),
    },
    {
        name: "type",
        description: (
            <>
                <p>
                    The type of connection a device is using to communicate with the network.It will be one of the
                    following values:
                </p>
                <ul>
                    <li>
                        <code>bluetooth</code>
                    </li>
                    <li>
                        <code>cellular</code>
                    </li>
                    <li>
                        <code>ethernet</code>
                    </li>
                    <li>
                        <code>none</code>
                    </li>
                    <li>
                        <code>wifi</code>
                    </li>
                    <li>
                        <code>wimax</code>
                    </li>
                    <li>
                        <code>other</code>
                    </li>
                    <li>
                        <code>unknown</code>
                    </li>
                </ul>
            </>
        ),
    },
];

export const ConnectivityDetails: React.FC = () => {
    const netInfo = useNetworkInformation();
    const classes = useStyles();
    const [showFieldExplanation, setShowFieldExplanation] = React.useState(false);
    const handleChangeShowFieldExplanation = () => {
        setShowFieldExplanation((x) => !x);
    };

    return React.useMemo(
        () => (
            <>
                <div className={classes.tableContainer}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>NetInfo property</TableCell>
                                    <TableCell>Value</TableCell>
                                    {showFieldExplanation && <TableCell>Explanation</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {connectionDetails
                                    .map((x) => {
                                        let raw = netInfo[x.name];
                                        const format1 = x.format ? x.format(raw) : raw;
                                        const format2 = defaultFormat(format1);
                                        return { ...x, value: format2 };
                                    })
                                    .map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row" variant="head">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.value}</TableCell>
                                            {showFieldExplanation && <TableCell>{row.description}</TableCell>}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <FormControlLabel
                    control={<Checkbox checked={showFieldExplanation} onChange={handleChangeShowFieldExplanation} />}
                    label="Show explanation for each field in the table above"
                />
            </>
        ),
        [classes, netInfo, showFieldExplanation]
    );
};
