import { ListItemTextProps } from "@material-ui/core";
import { JsonLogicTree } from "react-awesome-query-builder";
import isEqual from "react-fast-compare";
import { LinkProps } from "react-router-dom";
import { Command } from "./Command";
import { ScreenDefinition } from "./Screen/ScreenDefinition";

/**
 * All the details needed to render a partial screen.
 */
export type OverlaidScreen = ScreenDefinition;

export interface MenuContent {
    title: string;
    isEnabled: boolean;
    isEmphasized: boolean;
    items: MenuItem[];
}

export interface MenuIdentifier extends MenuContent {
    library: string;
    name: string;
}

export interface MenuItem {
    text: string;
    shortcut: string;
    command: Command;
    isEnabled: boolean;
    isEmphasized: boolean;
}

/**
 * Menus organized by library name and menu name.  Root (first) level properties are named for libraries.  Second level
 * properties are named for menus.  The objects in that second level are menu definitions with library name and menu
 * name omitted.  For example:
 *
 * ```
 * {
 *   "utlibr": {
 *     "utb": { title: "Utilities Billing", items: [...] }
 *   }
 * }
 * ```
 */
export type MenuTree = Record<string, Record<string, MenuContent>>;

export type QueryStringValues = Record<string, string | string[]>;

export enum ChecklistSummaryOrderBy {
    CreatedDate = "createdDate",
    CreatedDateDesc = "createdDateDesc",
    DueDate = "dueDate",
    DueDateDesc = "dueDateDesc",
    CompletedDate = "completedDate",
    CompletedDateDesc = "completedDateDesc",
    StartDate = "startDate",
    StartDateDesc = "startDateDesc",
}

export interface ChecklistSummariesSimpleFilter {
    isScheduled?: boolean;
    isStarted?: boolean;
    isAssigned?: boolean;
    isCompleted?: boolean;
    isLate?: boolean;
    isArchived?: boolean;

    minStartDate?: Date;
    maxStartDate?: Date;
    minCompletedDate?: Date;
    maxCompletedDate?: Date;

    assignedToUserId?: string;

    parameters?: Record<string, unknown>;
}

export interface ChecklistSummariesLogicalFilter {
    expression: JsonLogicTree | undefined;
}

export interface ChecklistSummariesQuerySort {
    orderBy: ChecklistSummaryOrderBy;
}

export interface ChecklistSummariesQueryPage {
    pageSize: number;
    pageNumber: number;
}

export interface ChecklistSummariesQueryChooseOutputFields {
    includeChecklistParameters?: boolean;
}

export type ChecklistSummariesSimpleQuery = { filterType: "simple" } & ChecklistSummariesSimpleFilter &
    ChecklistSummariesQuerySort &
    ChecklistSummariesQueryPage &
    ChecklistSummariesQueryChooseOutputFields;

export type ChecklistSummariesLogicalQuery = { filterType: "logical" } & ChecklistSummariesLogicalFilter &
    ChecklistSummariesQuerySort &
    ChecklistSummariesQueryPage &
    ChecklistSummariesQueryChooseOutputFields;

export type ChecklistSummariesQuery = ChecklistSummariesSimpleQuery | ChecklistSummariesLogicalQuery;

export function isChecklistSummariesLogicalQuery(
    x: ChecklistSummariesQuery | string
): x is ChecklistSummariesLogicalQuery {
    // return x !== undefined && x !== null && typeof x === "object" && "expression" in x;
    return x !== undefined && x !== null && typeof x === "object" && x.filterType === "logical";
}

export function isChecklistSummariesLogicalFilter(
    x: ChecklistSummariesSimpleFilter | ChecklistSummariesLogicalFilter
): x is ChecklistSummariesLogicalFilter {
    return x !== undefined && x !== null && typeof x === "object" && "expression" in x;
}

export function checklistSummariesQueryKey(query?: Partial<ChecklistSummariesQuery> | string) {
    return query === undefined ? ["checklist-summaries"] : ["checklist-summaries", query];
}

export function checklistQueryKey(checklistId?: string) {
    return checklistId === undefined ? ["checklists"] : ["checklists", checklistId];
}

function extractChecklistSummariesLogicalFilter<T extends ChecklistSummariesLogicalFilter>(value: T) {
    const { expression } = value;
    const filter: ChecklistSummariesLogicalFilter = {
        expression,
    };
    return filter;
}

function extractChecklistSummariesSimpleFilter<T extends ChecklistSummariesSimpleFilter>(value: T) {
    const {
        isScheduled,
        isStarted,
        isAssigned,
        isCompleted,
        isLate,
        isArchived,
        minStartDate,
        maxStartDate,
        minCompletedDate,
        maxCompletedDate,
        assignedToUserId,
    } = value;
    const filter: ChecklistSummariesSimpleFilter = {
        isScheduled,
        isStarted,
        isAssigned,
        isCompleted,
        isLate,
        isArchived,
        minStartDate,
        maxStartDate,
        minCompletedDate,
        maxCompletedDate,
        assignedToUserId,
    };
    return filter;
}

/** Compare the "filter" portion of these queries.  */
export function isEqualChecklistSummariesQueryFilter<
    T extends ChecklistSummariesSimpleFilter | ChecklistSummariesLogicalFilter
>(a: T, b: T) {
    const filterA = isChecklistSummariesLogicalFilter(a)
        ? extractChecklistSummariesLogicalFilter(a)
        : extractChecklistSummariesSimpleFilter(a);
    const filterB = isChecklistSummariesLogicalFilter(b)
        ? extractChecklistSummariesLogicalFilter(b)
        : extractChecklistSummariesSimpleFilter(b);
    return isEqual(filterA, filterB);
}

export interface InboxContainerProps {
    title: string;
    apiUrl: ChecklistSummariesQuery | string;
    isLegacyApi?: boolean;
    colorIndex: number;
}

export type ChecklistSimpleFilterComponentProps = {
    query: ChecklistSummariesSimpleFilter;
    onChange: React.Dispatch<React.SetStateAction<ChecklistSummariesSimpleFilter>>;
};

export type SimpleChecklistFilterPropMetadata = {
    dataType: "boolean" | "date" | "user";
    label: string;
    simpleFilterKey: keyof ChecklistSummariesSimpleFilter;
};

export type LogicalChecklistFilterPropMetadata = {
    dataType: "boolean" | "date";
    label: string;
    logicalFilterKey: string;
    subfields?: LogicalChecklistFilterPropMetadata[];
};

/** Url query parameters used by the procedure page. */
export type ProcedurePageQueryParameters = Partial<{
    endOfJobUrl: string;
    procedureParameters: string;
    stepPath: string;
}>;

export interface ProcedureIdentifier {
    library: string;
    procedure: string;
}

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export type MainMenuContainerProps = {
    menuItems: MainMenuItemProps[];
    icon: React.ReactElement;
    listItemTextProps?: Omit<ListItemTextProps, "primary">;
    expanded?: boolean;
    onClick?(): void;
    dividerBefore?: boolean;
    dividerAfter?: boolean;
} & Pick<ListItemTextProps, "primary"> &
    React.PropsWithChildren<{}>;

export type MainMenuItemProps = Pick<ListItemTextProps, "primary"> &
    Pick<LinkProps, "to"> & {
        /**
         * The icon portion of the menu item component, normally `Icon`, `SvgIcon`, or a `@material-ui/icons` SVG icon
         * element.
         */
        icon: React.ReactNode;

        dividerBefore?: boolean;
        dividerAfter?: boolean;
    };

export type MainMenuElement = MainMenuContainerProps | MainMenuItemProps;
