import queryString from "query-string";
import { useMemo } from "react";
import { QueryObserverResult } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";

export interface WebApiUser {
    id: string;
    name: string;
    azureADIdentifier: string;
    emailAddress: string;
}

type UsersParameters =
    | {
          userId: string;
      }
    | {
          azureAuthId: string;
      }
    | "self";

const urlToUsersApi = (parameters?: UsersParameters) =>
    parameters === undefined
        ? "users"
        : parameters === "self"
        ? `users?${queryString.stringify({ getSelf: true })}`
        : `users?${queryString.stringify(parameters)}`;

export const usersQueryKey = "users";

export function useUsers(parameters?: UsersParameters): QueryObserverResult<WebApiUser[], unknown> {
    const ky = useKy();
    return useTwinOakQuery(
        useMemo(
            () => ({
                ...queryOptionsDataChangesInfrequently,
                queryKey: [usersQueryKey, ...(parameters ? [parameters] : [])],
                queryFn: () => ky(urlToUsersApi(parameters)).json(),
                errorMessage: ErrorMessage.FailedToLoadUsers,
            }),
            [ky, parameters]
        )
    );
}

export function useUserIdToNameMap(): Record<string, string> {
    const { data: users } = useUsers();
    return useMemo(() => (users || []).reduce((acc, value) => ({ ...acc, [value.id]: value.name }), {}), [users]);
}
