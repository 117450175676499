import { Auth } from "../Auth";

export const unsecuredAuth: Auth = {
    isAccessTokenNeeded: false,
    isLoginInProgress: false,
    isUserLoggedIn: true,
    async login() {},
    async logout() {},
    getUserRoles: () => Promise.resolve([]),
    getAccessToken: () => Promise.reject(),
};
