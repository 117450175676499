import { Theme, ThemeOptions, useMediaQuery } from "@material-ui/core";
import { createMuiTheme, ThemeProvider as MaterialUiThemeProvider } from "@material-ui/core/styles";
import constate from "constate";
import * as React from "react";
import { useState } from "react";
import { useAppCustomizations } from "./Extensibility/state";

const applyDarkModePreference = (themeOptions: ThemeOptions, prefersDarkMode: boolean): ThemeOptions => ({
    ...themeOptions,
    palette: {
        ...themeOptions.palette,
        type: prefersDarkMode ? "dark" : "light",
    },
});

const defaultThemeOptions: ThemeOptions = {};

const useThemeOptions = (): {
    theme: Theme;
    themeOptions: ThemeOptions;
    setThemeOptions: React.Dispatch<React.SetStateAction<ThemeOptions>>;
} => {
    const { buildPalette } = useAppCustomizations();

    const [themeOptions, setThemeOptions] = useState<ThemeOptions>(defaultThemeOptions);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true });
    const theme = React.useMemo(
        () =>
            createMuiTheme(
                applyDarkModePreference(
                    buildPalette === undefined ? themeOptions : buildPalette(themeOptions),
                    prefersDarkMode
                )
            ),
        [buildPalette, themeOptions, prefersDarkMode]
    );
    return { theme, themeOptions, setThemeOptions };
};

const [ThemeOptionsProviderWithLegacyType, useThemeContext] = constate(useThemeOptions);
const ThemeOptionsProvider = ThemeOptionsProviderWithLegacyType as React.FC<React.PropsWithChildren<{}>>;

export { useThemeContext };

export const ThemeOptionsConsumer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { theme } = useThemeContext();
    return <MaterialUiThemeProvider theme={theme}>{children}</MaterialUiThemeProvider>;
};

export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
    <ThemeOptionsProvider>
        <ThemeOptionsConsumer>{children}</ThemeOptionsConsumer>
    </ThemeOptionsProvider>
);
