import queryString from "query-string";
import { useMemo } from "react";
import { QueryKey } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { Printer, PrinterSpecification } from "./types";
import { useKy } from "./useKy";
import { useTwinOakQuery, UseTwinOakQueryOptions } from "./useTwinOakQuery";

const urlToPrintersApi = "printers";
const urlToPrintersSearchApi = "printers/search";

export function usePrinters(
    printerSpecification?: PrinterSpecification,
    queryOptions?: UseTwinOakQueryOptions<Printer[], Error, Printer[]>
) {
    const ky = useKy();
    return useTwinOakQuery<Printer[], Error>(
        useMemo(
            () => ({
                queryKey: ["printers", printerSpecification],
                queryFn: () => {
                    return printerSpecification
                        ? ky.post(urlToPrintersSearchApi, { json: printerSpecification }).json()
                        : ky(urlToPrintersApi).json();
                },
                staleTime: 5 * 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadPrinters,
                ...queryOptions,
            }),
            [ky, printerSpecification, queryOptions]
        )
    );
}

const urlToPrinterPhysicalNamesApi = "printer-physical-names";

export function usePrinterPhysicalNames() {
    const ky = useKy();
    return useTwinOakQuery<string[], Error>(
        useMemo(
            () => ({
                queryKey: [urlToPrinterPhysicalNamesApi],
                queryFn: () => ky(urlToPrinterPhysicalNamesApi).json(),
                staleTime: 5 * 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadPrinterPhysicalNames,
            }),
            [ky]
        )
    );
}

const urlToPrinterPaperSourcesApi = (printerPhysicalName: string) =>
    `printer-paper-source-names?${queryString.stringify({ printerPhysicalName })}`;

export function usePaperSourceNamesByPrinterPhysicalName(
    printerPhysicalName: string,
    options?: UseTwinOakQueryOptions<string[], Error, string[], QueryKey>
) {
    const ky = useKy();
    return useTwinOakQuery<string[], Error>(
        useMemo(() => {
            const apiUrl = urlToPrinterPaperSourcesApi(printerPhysicalName);
            return {
                queryKey: [apiUrl],
                queryFn: () => ky(apiUrl).json(),
                staleTime: 5 * 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadPrinterPaperSourceNames,
                ...options,
            };
        }, [ky, options, printerPhysicalName])
    );
}

const urlToPrinterPaperSizeNamesApi = (printerPhysicalName: string) =>
    `printer-paper-size-names?${queryString.stringify({ printerPhysicalName })}`;

export function usePaperSizeNamesByPrinterPhysicalName(
    printerPhysicalName: string,
    options?: UseTwinOakQueryOptions<string[], Error, string[], QueryKey>
) {
    const ky = useKy();
    return useTwinOakQuery<string[], Error>(
        useMemo(() => {
            const apiUrl = urlToPrinterPaperSizeNamesApi(printerPhysicalName);
            return {
                queryKey: [apiUrl],
                queryFn: () => ky(apiUrl).json(),
                staleTime: 5 * 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadPrinterPaperSizeNames,
                ...options,
            };
        }, [ky, options, printerPhysicalName])
    );
}

const urlToFontFamilyNamesApi = "font-family-names";

export function useFontFamilyNames() {
    const ky = useKy();
    return useTwinOakQuery<string[], Error>(
        useMemo(
            () => ({
                queryKey: [urlToFontFamilyNamesApi],
                queryFn: () => ky(urlToFontFamilyNamesApi).json(),
                staleTime: 5 * 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadPrinterPhysicalNames,
            }),
            [ky]
        )
    );
}

const urlToPrinterApi = (printerId: string) => `printers/${printerId}`;

export function usePrinter(printerId: string, options?: UseTwinOakQueryOptions<Printer, Error>) {
    const ky = useKy();

    return useTwinOakQuery<Printer, Error>(
        useMemo(() => {
            const url = urlToPrinterApi(printerId);
            return {
                queryKey: ["printers", printerId],
                queryFn: () => ky(url).json(),
                errorMessage: ErrorMessage.FailedToLoadPrinter,
                ...options,
            };
        }, [ky, options, printerId])
    );
}
