import CheckFilledIcon from "@material-ui/icons/Check";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CheckTwoToneIcon from "@material-ui/icons/CheckTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const checkIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return CheckFilledIcon;
        case IconStyle.Outlined:
            return CheckOutlinedIcon;
        case IconStyle.Rounded:
            return CheckRoundedIcon;
        case IconStyle.TwoTone:
            return CheckTwoToneIcon;
        case IconStyle.Sharp:
            return CheckSharpIcon;
    }
};

export const CheckIcon = createIconComponent(checkIconSelector);
