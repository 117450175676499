import { HTTPError } from "ky";
import * as React from "react";
import { useEffect, useState } from "react";
import { useKy } from "./Api/useKy";
import { useShowError } from "./ErrorContext";

function delay(ms?: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * This component exercises the logging capabilities of the showError function.  Just put this component on a page,
 * load it, and inspect the console output.  Works well on the Help page or Home page, since they only render once and
 * aren't interactive.
 * @returns
 */
export const ShowErrorTests: React.FC = () => {
    const ky = useKy();
    const showError = useShowError();
    const httpErrorStatusCode = 404;
    const timeBetweenCalls = 2000;
    const [done, setDone] = useState(false);
    const [started, setStarted] = useState(false);

    useEffect(() => {
        (async () => {
            // Guarantee to only run once per mount.
            if (started) {
                return;
            }
            setStarted(true);

            // Scenarios with rich diagnostic results.
            try {
                throw new Error("I'm an error message.");
            } catch (e: any) {
                console.log("This error should yield **rich** logging results.");
                showError(`Plain old javascript exception.`, e);
            }

            await delay(timeBetweenCalls);

            try {
                await ky(`/diagnostics/errors/${httpErrorStatusCode}`).json();
            } catch (e: any) {
                console.log("This error should yield **rich** logging results.");
                showError(`Http ${httpErrorStatusCode} error returned and we're using ky.`, e);
            }

            await delay(timeBetweenCalls);

            try {
                await ky(`/diagnostics/errors/exception`).json();
            } catch (e: any) {
                console.log("This error should yield **rich** logging results.");
                showError(
                    `An unhandled exception occurred on the server.  If running locally, we should have a server call stack.`,
                    e
                );
            }

            await delay(timeBetweenCalls);

            try {
                await fetch("http://non-existent-domain-HWE4zjh6vhyekp/");
            } catch (e: any) {
                console.log(
                    "This error should yield **rich** logging results.  The exception is thrown by fetch, so the options and request objects are not included."
                );
                showError(`Invalid domain HTTP error.`, e);
            }

            await delay(timeBetweenCalls);

            // Scenarios with poor diagnostic results.
            try {
                await ky(`/diagnostics/errors/${httpErrorStatusCode}`).json();
            } catch (e: any) {
                console.log("This error should yield **poor** logging results.");
                if (e instanceof HTTPError) {
                    showError(
                        `Pass response object to showError instead of HTTPError object (anti-pattern).`,
                        e.response
                    );
                }
            }

            setDone(true);
        })();
    }, [ky, showError, started]);

    return (
        <>
            {done
                ? "Done running showError tests.  Results are in the browser console."
                : "Running showError tests now..."}
        </>
    );
};
