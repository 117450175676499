import * as React from "react";
import { SecurityScheme, useAppConfig } from "../AppConfig";
import { areWeTesting } from "../areWeTesting";
import { AzureAdAuthProvider } from "./AzureAd";
import { OidcAuthProvider } from "./Oidc";
import { UnsecuredAuthProvider } from "./Unsecured";

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    if (areWeTesting) {
        throw new Error("Don't use <AppConfigProvider> in unit tests or storybook stories.");
    }

    const appConfig = useAppConfig();
    if (appConfig.securityScheme === SecurityScheme.AzureAD) {
        return <AzureAdAuthProvider appConfig={appConfig}>{children}</AzureAdAuthProvider>;
    }
    if (appConfig.securityScheme === SecurityScheme.Oidc) {
        return <OidcAuthProvider appConfig={appConfig}>{children}</OidcAuthProvider>;
    }
    return <UnsecuredAuthProvider>{children}</UnsecuredAuthProvider>;
};
