import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { PrintJobContainer } from "./PrintJobContainer";

const useLinesParams = (): {
    firstLine: number | undefined;
    lastLine: number | undefined;
} => {
    const { hash } = useLocation();

    const groups = hash.match(/L(?<firstLine>\d+)(-L(?<secondLine>\d+))?/)?.groups;
    if (!groups) {
        return {
            firstLine: undefined,
            lastLine: undefined,
        };
    }
    const { firstLine, secondLine } = groups;
    const firstLineNumber = parseInt(firstLine);
    if (isNaN(firstLineNumber)) {
        return {
            firstLine: undefined,
            lastLine: undefined,
        };
    }
    const secondLineNumber = parseInt(secondLine);
    return {
        firstLine: parseInt(firstLine),
        lastLine: isNaN(secondLineNumber) ? undefined : secondLineNumber,
    };
};

export const PrintJobPage: React.FC = () => {
    const { printJobId } = useParams<{ printJobId: string }>();
    const { firstLine, lastLine } = useLinesParams();

    return (
        <PrintJobContainer printJobId={printJobId} firstHighlightedLine={firstLine} lastHighlightedLine={lastLine} />
    );
};
