import ImportExportFilledIcon from "@material-ui/icons/ImportExport";
import ImportExportOutlinedIcon from "@material-ui/icons/ImportExportOutlined";
import ImportExportRoundedIcon from "@material-ui/icons/ImportExportRounded";
import ImportExportSharpIcon from "@material-ui/icons/ImportExportSharp";
import ImportExportTwoToneIcon from "@material-ui/icons/ImportExportTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const importExportIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ImportExportFilledIcon;
        case IconStyle.Outlined:
            return ImportExportOutlinedIcon;
        case IconStyle.Rounded:
            return ImportExportRoundedIcon;
        case IconStyle.TwoTone:
            return ImportExportTwoToneIcon;
        case IconStyle.Sharp:
            return ImportExportSharpIcon;
    }
};

export const ImportExportIcon = createIconComponent(importExportIconSelector);
