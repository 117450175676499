import { useMemo } from "react";
import {
    QueryFunction,
    QueryKey,
    QueryOptions,
    useQueries,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";
import { ErrorSource, useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSpinnerEffect } from "../SpinnerContext";

/** Default value if the `showSpinner` option is omitted from a `UseTwinOakQueryOptions` object. */
const showSpinnerDefault = true;

export interface UseTwinOakQueryOptions<
    TQueryFnData = unknown,
    TError extends ErrorSource = Error,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
> extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
    /** If true, a spinner is shown if the api call does not finish quickly.  Defaults to `true`. */
    showSpinner?: boolean;
    /** If set, the given message is shown if he api returns an error code. */
    errorMessage?: ErrorMessage | string;
}

// Copypasta from https://github.com/tannerlinsley/react-query/blob/master/src/core/utils.ts#L423-L425

function isQueryKey(value: any): value is QueryKey {
    return typeof value === "string" || Array.isArray(value);
}

// Copypasta from https://github.com/tannerlinsley/react-query/blob/master/src/core/utils.ts#L116-L133

function parseQueryArgs<TOptions extends QueryOptions<any, any, any, TQueryKey>, TQueryKey extends QueryKey = QueryKey>(
    arg1: TQueryKey | TOptions,
    arg2?: QueryFunction<any, TQueryKey> | TOptions,
    arg3?: TOptions
): TOptions {
    if (!isQueryKey(arg1)) {
        return arg1 as TOptions;
    }

    if (typeof arg2 === "function") {
        return { ...arg3, queryKey: arg1, queryFn: arg2 } as TOptions;
    }

    return { ...arg2, queryKey: arg1 } as TOptions;
}

// The useQuery wrapper overloads below are based on https://github.com/tannerlinsley/react-query/blob/master/src/react/useQuery.ts

export function useTwinOakQuery<
    TQueryFnData = unknown,
    TError extends ErrorSource = Error,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(options: UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

export function useTwinOakQuery<
    TQueryFnData = unknown,
    TError extends ErrorSource = Response,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    options?: Omit<UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey">
): UseQueryResult<TData, TError>;

export function useTwinOakQuery<
    TQueryFnData = unknown,
    TError extends ErrorSource = Error,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: Omit<UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey" | "queryFn">
): UseQueryResult<TData, TError>;

export function useTwinOakQuery<
    TQueryFnData,
    TError extends ErrorSource = Error,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    arg1: TQueryKey | UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    arg2?: QueryFunction<TQueryFnData, TQueryKey> | UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    arg3?: UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
    const parsedOptions = parseQueryArgs(arg1, arg2, arg3) as UseTwinOakQueryOptions<
        TQueryFnData,
        TError,
        TData,
        TQueryKey
    >;
    const showError = useShowError();
    const options = useMemo(
        () =>
            ({
                ...parsedOptions,
                onError: function handleTwinOakQueryError(error: TError) {
                    if (parsedOptions.errorMessage !== undefined) {
                        showError(parsedOptions.errorMessage, error);
                    }
                    parsedOptions.onError?.(error);
                },
            } as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>),
        [parsedOptions, showError]
    );
    const query = useQuery(options);
    useSpinnerEffect(
        (parsedOptions.showSpinner ?? showSpinnerDefault) && query.isLoading,
        parsedOptions.queryKey || parsedOptions.queryFn?.name || JSON.stringify(parsedOptions)
    );
    return query;
}

export function useTwinOakQueries<
    TQueryFnData = unknown,
    TError extends ErrorSource = Response,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queries: readonly [...UseTwinOakQueryOptions<TQueryFnData, TError, TData, TQueryKey>[]]
): UseQueryResult<TData, TError>[] {
    const showError = useShowError();

    const result = useQueries<TQueryFnData, TError, TData, TQueryKey>(
        useMemo(
            () =>
                queries.map((q) => ({
                    ...q,
                    onError: function handleTwinOakQueryError(error: TError) {
                        if (q.errorMessage !== undefined) {
                            showError(q.errorMessage, error);
                        }
                        q.onError?.(error);
                    },
                })),
            [queries, showError]
        )
    );
    useSpinnerEffect(
        (queries.some((q) => q.showSpinner) ?? showSpinnerDefault) && result.some((q) => q.isLoading),
        queries
            .map((q) => q.queryKey)
            .reduce<unknown[]>((acc, next) => {
                return Array.isArray(next) ? [...acc, ...next] : [...acc, next];
            }, [])
    );
    return result;
}
