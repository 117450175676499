import { Box, Card, CardContent, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import * as React from "react";
import { useMemo } from "react";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
        errorMessage: {
            display: "flex",
            alignItems: "center",
        },
        errorIcon: {
            marginRight: theme.spacing(1),
        },
    })
);

export const AppConfigLoadError: React.FC<{ urlToConfigSpaApi: string; configLoadFailureCount: number }> = ({
    urlToConfigSpaApi,
    configLoadFailureCount,
}) => {
    const classes = useStyles();
    return useMemo(
        () => (
            <Box p={1}>
                <Grid container>
                    <Grid item xs={12} xl={6} lg={9}>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.errorMessage}>
                                    <ErrorIcon fontSize="large" className={classes.errorIcon} />
                                    <Typography>
                                        Failed to load spa configuration from{" "}
                                        <Link href={urlToConfigSpaApi}>{urlToConfigSpaApi}</Link>. Retries will continue
                                        in the background. Tried {configLoadFailureCount} time
                                        {configLoadFailureCount !== 1 ? "s" : ""} so far.
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        ),
        [classes.cardContent, classes.errorIcon, classes.errorMessage, configLoadFailureCount, urlToConfigSpaApi]
    );
};
