import ClearFilledIcon from "@material-ui/icons/Clear";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import ClearTwoToneIcon from "@material-ui/icons/ClearTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const clearIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ClearFilledIcon;
        case IconStyle.Outlined:
            return ClearOutlinedIcon;
        case IconStyle.Rounded:
            return ClearRoundedIcon;
        case IconStyle.TwoTone:
            return ClearTwoToneIcon;
        case IconStyle.Sharp:
            return ClearSharpIcon;
    }
};

export const ClearIcon = createIconComponent(clearIconSelector);
