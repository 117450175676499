/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * This file describes the responses returned from the apis.
 */

/**
 * Describes the result from calling /checklist-summaries
 *
 * Generated using https://app.quicktype.io?share=0ALhmulu99bP4Shl83Md
 */

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistSummary {
    checklistId: string;
    checklistName: string;
    isLate: boolean;
    dueDate: Date;
    completedDate?: Date;
    startDate?: Date;
    steps: WebApiChecklistSummaryStep[];
    isScheduled: boolean;
    isStarted: boolean;
    isArchived: boolean;
}

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistSummaryStep {
    checklistStepId: string;
    isCurrentStep: boolean;
    sequence: number;
    description: string;
    dueDate: Date;
    approvingDepartment: string;
    isLate: boolean;
    assignedToUserId?: string;
    completedByUserId?: string;
}

/**
 * Describes the result from calling /checklists
 *
 * Generated using https://app.quicktype.io?share=uL8Y5WL4fruPfcwbFCvd
 */
// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklist {
    id: string;
    createdByUserId: string;
    scheduledByUserId?: string;
    name?: string;
    dateCreated: Date;
    startDate?: Date;
    dueDate?: Date;
    completedDate?: Date;
    currentStepId: string;
    steps: WebApiChecklistStep[];
    parameters: WebApiChecklistTemplateParameter[];
    isArchived: boolean;
    isLate: boolean;
    isScheduled: boolean;
    isStarted: boolean;
}

// TODO: Consider separate types for scheduled versus unscheduled.
export interface WebApiChecklistStep {
    id: string;
    description?: string;
    sequence: number;
    dueDate?: Date;
    completedDate?: Date;
    completedByUserId?: string;
    assignmentHistory: unknown;
    annotations?: WebApiChecklistStepAnnotation[];
    attachmentDescriptors: WebApiChecklistStepAttachmentDescriptor[];
    approvingDepartment: string;
    assignedToUserId: string;
    isLate: boolean;
    canComplete: boolean;
    canAssign: boolean;
    canAttachItem: boolean;
    canSetAsCurrent: boolean;
}

export interface WebApiChecklistStepAnnotation {
    id: string;
    note?: string;
    createdDate: Date;
    createdByUserId: string;
}

export interface WebApiChecklistStepAttachmentDescriptor {
    id: string;
    name: string;
    contentType: string;
    attachedDate: Date;
    attachedByUserId: string;
    attachmentId: string;
}

export interface WebApiChecklistTemplate {
    id: string;
    name: string;
    steps: WebApiChecklistTemplateStep[];
    parameters: WebApiChecklistTemplateParameter[];
    instanceNameMacro: string;
}

export interface WebApiChecklistTemplateStep {
    id: string;
    sequence: number;
    description: string;
    daysFromDueDate: number;
    approvingDepartment: string;
}

export type DateParameterTypeKey = "date";
export const DateParameterTypeKey = "date";

export type StringParameterTypeKey = "string";
export const StringParameterTypeKey = "string";

export type NumberParameterTypeKey = "number";
export const NumberParameterTypeKey = "number";

export type BooleanParameterTypeKey = "boolean";
export const BooleanParameterTypeKey = "boolean";

export type DateListParameterTypeKey = "dateList";
export const DateListParameterTypeKey = "dateList";

export type TextAreaParameterTypeKey = "textArea";
export const TextAreaParameterTypeKey = "textArea";

export type HtmlParameterTypeKey = "html";
export const HtmlParameterTypeKey = "html";

export type UserParameterTypeKey = "user";
export const UserParameterTypeKey = "user";

export type WebApiChecklistTemplateParameterTypeKey =
    | DateParameterTypeKey
    | StringParameterTypeKey
    | NumberParameterTypeKey
    | BooleanParameterTypeKey
    | UserParameterTypeKey
    | DateListParameterTypeKey
    | TextAreaParameterTypeKey
    | HtmlParameterTypeKey;

type ParameterValueType<T extends WebApiChecklistTemplateParameterTypeKey> = T extends DateParameterTypeKey
    ? Date
    : T extends DateListParameterTypeKey
    ? Date[]
    : T extends NumberParameterTypeKey
    ? number
    : T extends BooleanParameterTypeKey
    ? boolean
    : string;

export interface WebApiChecklistTemplateParameterValueBase<T extends WebApiChecklistTemplateParameterTypeKey> {
    type: T;
    value?: ParameterValueType<T>;
}

export interface WebApiChecklistTemplateParameterBase<T extends WebApiChecklistTemplateParameterTypeKey>
    extends WebApiChecklistTemplateParameterValueBase<T> {
    name: string;
    label: string;
    isUserQueried: boolean;
    isRequired?: boolean;
}

export type WebApiChecklistTemplateParameterValue =
    | WebApiChecklistTemplateParameterValueBase<DateParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<StringParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<NumberParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<BooleanParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<UserParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<DateListParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<TextAreaParameterTypeKey>
    | WebApiChecklistTemplateParameterValueBase<HtmlParameterTypeKey>;

export type WebApiChecklistTemplateParameter =
    | WebApiChecklistTemplateParameterBase<DateParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<StringParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<NumberParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<BooleanParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<UserParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<DateListParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<TextAreaParameterTypeKey>
    | WebApiChecklistTemplateParameterBase<HtmlParameterTypeKey>;

/** Represents a screen, as returned by the twin oak api. */
export interface WebApiScreen {
    id: string;
    library: string;
    sourceMember: string;
    name: string;
    /**
     * The 1-based position of the row where this screen begins to render.  The first row has the value 1.  The final
     * row has the value 24.
     *
     * The term "index" typically denotes a zero-based ordinal position in a collection, so this property is named
     * with the term "number" instead of "index".
     */
    layoutTopRowNumber: number | "variable";
    /**
     * The number of rows defined by this screen.  A "full page" screen has the value 24.
     */
    layoutRowCount: number;
    commandKeys: string[];
    controls: WebApiScreenControl[];
}

export function cleanseWebApiScreen(screen: WebApiScreen) {
    const { layoutTopRowNumber: originalLayoutTopRowNumber, layoutRowCount: originalLayoutRowCount } = screen;
    const layoutTopRowNumber = originalLayoutTopRowNumber ?? 1;
    const layoutRowCount = originalLayoutRowCount ?? 24;
    return { ...screen, layoutTopRowNumber, layoutRowCount };
}

type BooleanOrIndicator = number | boolean;

export interface WebApiScreenControl {
    /**
     * Line number from the RPG source code.
     */
    sequenceNumber?: number | undefined;
    /** length of the text.  If the text is actually shorter, then space pad the end.  This is visible in titles. */
    length: number;
    /**
     * The row number of the screen coordinate.  The top-most (first) row of the page is row number 1.  Rows numbers
     * increase as you move positions down the page.  The bottom-most (last) row of the page is row number 24.
     */
    row?: number | undefined;
    /**
     * The column number of the screen coordinate.  The left-most (first) column of the page is column number 1.
     * Column numbers increase as you move positions along the page.  The right-most (last) column of the page is
     * column number 80.
     */
    column?: number | undefined;
    /** name of the field associated with the control */
    name: string;
    /** text of the control */
    text: string;
    /** The type of data. */
    dataType: WebApiScreenControlDataType;
    /** index of data start in output screen buffer */
    outputBufferIndex?: number;
    /** index of data start in input screen buffer */
    inputBufferIndex?: number;

    isInput?: boolean;

    protectField?: BooleanOrIndicator | undefined;
    blinkField?: BooleanOrIndicator | undefined;
    highIntensity?: BooleanOrIndicator | undefined;
    nonDisplay?: BooleanOrIndicator | undefined;
    outputData?: BooleanOrIndicator | undefined;
    positionCursor?: BooleanOrIndicator | undefined;
    reverseImage?: BooleanOrIndicator | undefined;
    underline?: BooleanOrIndicator | undefined;
}

export enum WebApiScreenControlDataType {
    /** Type B if you want alphanumeric data only. */
    Alphanumeric = "alphanumeric",

    /** Type A if you want alphabetic data only. */
    Alphabetic = "alphabetic",

    /** Type K to indicate Katakana characters are allowed. */
    Katakana = "katakana",

    /** Type N to indicate the numbers (0-9), plus and minus signs, commas, periods and blanks are allowed. */
    Numeric = "numeric",

    /** Type M to indicate shift numbers (0-9), plus and minus signs, commas, periods and blanks are allowed. */
    ShiftNumeric = "shiftNumeric",

    /** Type S to indicate signed numbers (0-9) are accessed only by pressing a field exit key. */
    SignedNumeric = "signedNumeric",

    /** Type D to indicate only numbers (0-9) are allowed. */
    Digits = "digits",

    /** Type X to indicate only double byte character set characters are allowed. */
    DoubleByteCharacterSet = "doubleByteCharacterSet",

    /** Type E to indicate double byte character set characters or alphanumeric characters are allowed. The default is alphanumeric. */
    DoubleByteCharacterSetOrAlphanumericDefaultToAlphanumeric = "doubleByteCharacterSetOrAlphanumericDefaultToAlphanumeric",

    /** Type F to indicate double byte character set characters or alphanumeric characters are allowed. The default is double byte character set. */
    DoubleByteCharacterSetOrAlphanumericDefaultToDoubleByteCharacterSet = "doubleByteCharacterSetOrAlphanumericDefaultToDoubleByteCharacterSet",

    /** Type O to indicate double byte character set characters and alphanumeric characters are allowed. */
    DoubleByteCharacterSetAndAlphanumeric = "doubleByteCharacterSetAndAlphanumeric",

    /** Type R if you want data read from the magnetic stripe reader. */
    MagneticStripeReader = "magneticStripeReader",
}
