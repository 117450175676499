import { Box, Typography, useTheme } from "@material-ui/core";
import * as React from "react";
import { ErrorIcon } from "./Icons";

export const Error: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
    const theme = useTheme();
    return (
        <Box
            m={2}
            color="error.main"
            display="flex"
            alignItems="center"
            css={{
                "& :first-child": {
                    marginRight: theme.spacing(1),
                },
            }}
        >
            <ErrorIcon fontSize="large" />
            <Typography>{errorMessage}</Typography>
        </Box>
    );
};
