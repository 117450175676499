/**
 * Returns a copy of an object with some properties omitted.  This approach is pure without
 * side-effects.
 */
export function omitProps<T>(obj: T, ...keys: (string | number | symbol)[]) {
    if (obj === undefined || obj === null || typeof obj !== "object") {
        return obj;
    }
    return Object.entries(obj).reduce(
        (acc, [key, value]) => (keys.includes(key) ? acc : { ...acc, [key]: value }),
        {} as T
    );
}
