import ViewColumnFilledIcon from "@material-ui/icons/ViewColumn";
import ViewColumnOutlinedIcon from "@material-ui/icons/ViewColumnOutlined";
import ViewColumnRoundedIcon from "@material-ui/icons/ViewColumnRounded";
import ViewColumnSharpIcon from "@material-ui/icons/ViewColumnSharp";
import ViewColumnTwoToneIcon from "@material-ui/icons/ViewColumnTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const viewColumnIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return ViewColumnFilledIcon;
        case IconStyle.Outlined:
            return ViewColumnOutlinedIcon;
        case IconStyle.Rounded:
            return ViewColumnRoundedIcon;
        case IconStyle.TwoTone:
            return ViewColumnTwoToneIcon;
        case IconStyle.Sharp:
            return ViewColumnSharpIcon;
    }
};

export const ViewColumnIcon = createIconComponent(viewColumnIconSelector);
