import constate from "constate";
import React, { useEffect, useState } from "react";
import { usePageSubTitleContext } from "./PageSubTitleContext";

const usePageTitle = (): {
    pageTitle: React.ReactNode;
    setPageTitle(pageTitle: React.ReactNode): void;
    clearPageTitle: () => void;
} => {
    const { setPageSubTitle } = usePageSubTitleContext();
    const [pageTitle, setPageTitle] = useState("" as React.ReactNode);
    const clearPageTitle = (): void => {
        if (pageTitle) {
            setPageTitle("");
        }
    };
    useEffect(() => {
        setPageSubTitle("");
    }, [pageTitle, setPageSubTitle]);
    return { pageTitle, setPageTitle, clearPageTitle };
};

const [PageTitleProviderWithLegacyType, usePageTitleContext] = constate(usePageTitle);
const PageTitleProvider = PageTitleProviderWithLegacyType as React.FC<React.PropsWithChildren<{}>>;

export { PageTitleProvider, usePageTitleContext };
