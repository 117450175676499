import { useEffect, useState } from "react";
import { featureFlags } from "../featureFlags";
import { createLogger, noopLogger } from "../log";

const log = featureFlags.logConnectivity ? createLogger("useNetworkInformation") : noopLogger;

export type MyNetworkInformation = Omit<NetworkInformation, "onchange" | keyof EventTarget>;

function getConnection(): NetworkInformation {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
}

export function useNetworkInformation(): MyNetworkInformation {
    const [networkInformation, setNetworkInformation] = useState(getConnection());

    useEffect(() => {
        function updateConnectionStatus() {
            const connection = getConnection();
            log("[updateConnectionStatus]", connection);
            setNetworkInformation(connection);
        }

        networkInformation.addEventListener("change", updateConnectionStatus);
        return () => {
            networkInformation.removeEventListener("change", updateConnectionStatus);
        };
    }, [networkInformation]);

    log(networkInformation);
    return networkInformation;
}
