import * as React from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useAuth } from "../Auth";
import { clearReactQueryCache } from "./QueryClientProvider";

export const ClearQueryCacheOnAuthChange: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { isUserLoggedIn } = useAuth();
    const queryClient = useQueryClient();
    useEffect(
        function clearCacheEffect() {
            queryClient.removeQueries();
            clearReactQueryCache();
        },
        [isUserLoggedIn, queryClient]
    );

    return <>{children}</>;
};
