import { compare as jsonPatchCompare, Operation } from "fast-json-patch";
import * as React from "react";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Updater } from "react-query/types/core/utils";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { Printer } from "./types";
import { useKy } from "./useKy";

/** Returns an updater function that adds a printer to a list. */
function addPrinterUpdater(addition: Printer): Updater<Printer[] | undefined, Printer[]> {
    return (prevList) => [...(prevList || []).filter((printer) => printer.id !== addition.id), addition];
}

/** Returns an updater function that replaces a printer in a list. */
function replacePrinterUpdater(replacement: Printer): Updater<Printer[] | undefined, Printer[]> {
    return (prevList) => (prevList || []).map((printer) => (printer.id !== replacement.id ? replacement : printer));
}

/** Returns an updater function that removes a printer from a list. */
function removePrinterUpdater(removal: Pick<Printer, "id">): Updater<Printer[] | undefined, Printer[]> {
    return (prevList) => prevList?.filter((printer) => printer.id !== removal.id) || [];
}

type AddPrinterVariables = Printer;

export function useAddPrinterMutation() {
    const ky = useKy();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const queryCache = useQueryClient();

    return useMutation<Printer, Response, AddPrinterVariables>(
        useMemo(
            () => ({
                mutationFn: function addPrinter({ id: _, ...printer }: Printer) {
                    return ky.post("printers", { json: printer }).json();
                },
                onSuccess: function onSuccessAddPrinter(data, _variables, _context) {
                    queryCache.setQueryData(["printers", data.id], data);
                    queryCache.setQueryData<Printer[]>(["printers"], addPrinterUpdater(data));
                    queryCache.invalidateQueries(["printers"]);

                    const name = data.name || data.printerSettings.printerName;
                    setSnack(name ? <>Created printer: &ldquo;{name}&rdquo;.</> : "Created printer.");
                },
                onError: function onErrorAddPrinter(error, _variables, _context) {
                    showError(ErrorMessage.FailedToCreatePrinter, error);
                },
            }),
            [ky, queryCache, setSnack, showError]
        )
    );
}

type PutPrinterVariables = Printer;

export function useUpdatePrinterMutation() {
    const ky = useKy();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const queryCache = useQueryClient();

    return useMutation<Printer, Response, PutPrinterVariables>(
        useMemo(
            () => ({
                mutationFn: function updatePrinter(printer: PutPrinterVariables) {
                    return ky
                        .put("printers", {
                            json: printer,
                        })
                        .json();
                },
                onSuccess: function onSuccessUpdatePrinter(data, _variables, _context) {
                    // queryCache.setQueryData(["printers", data.id], data);
                    // queryCache.setQueryData<Printer[]>(["printers"], replacePrinterUpdater(data));
                    queryCache.invalidateQueries(["printers"]);

                    const name = data.name || data.printerSettings.printerName;
                    setSnack(name ? `Updated printer: "${name}".` : "Updated printer.");
                },
                onError: function onErrorUpdatePrinter(error, _variables, _context) {
                    showError(ErrorMessage.FailedToUpdatePrinter, error);
                },
            }),
            [ky, queryCache, setSnack, showError]
        )
    );
}

type PatchMutationOperationsVariables = { id: string; ops: Operation[] };
type PatchMutationVariables<T> = PatchMutationOperationsVariables | { source: T; destination: T };

type PatchPrinterVariables = PatchMutationVariables<Printer>;

function isPatchOperations<T>(x: PatchMutationVariables<T>): x is PatchMutationOperationsVariables {
    return "ops" in x;
}

export function usePatchPrinterMutation() {
    const ky = useKy();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const queryCache = useQueryClient();

    return useMutation<Printer, Response, PatchMutationVariables<Printer>>(
        useMemo(
            () => ({
                mutationFn: function patchPrinter(variables: PatchPrinterVariables) {
                    const id = isPatchOperations(variables) ? variables.id : variables.source.id;
                    const ops = isPatchOperations(variables)
                        ? variables.ops
                        : jsonPatchCompare(variables.source, variables.destination);
                    return ky
                        .patch(`printers/${id}`, {
                            json: JSON.stringify(ops),
                        })
                        .json();
                },
                onSuccess: function onSuccessPatchPrinter(data, _variables, _context) {
                    queryCache.setQueryData(["printers", data.id], data);
                    queryCache.setQueryData<Printer[]>(["printers"], replacePrinterUpdater(data));
                    queryCache.invalidateQueries(["printers"]);

                    const name = data.name || data.printerSettings.printerName;
                    setSnack(name ? `Updated printer: "${name}".` : "Updated printer.");
                },
                onError: function onErrorPatchPrinter(error, _variables, _context) {
                    showError(ErrorMessage.FailedToUpdatePrinter, error);
                },
            }),
            [ky, queryCache, setSnack, showError]
        )
    );
}

// type DeletePrinterVariables = string | Pick<Printer, "id" | "name" | "physicalName">;
type DeletePrinterVariables = string | Printer;

function getPrinterId(variables: DeletePrinterVariables) {
    return typeof variables === "string" ? variables : variables.id;
}
function getPrinterName(variables: DeletePrinterVariables) {
    return typeof variables === "string" ? undefined : variables.name || variables.printerSettings.printerName;
}

export function useDeletePrinterMutation() {
    const ky = useKy();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const queryCache = useQueryClient();

    return useMutation<void, Response, DeletePrinterVariables>({
        mutationFn: function deletePrinter(variables: DeletePrinterVariables) {
            return ky.delete(`printers/${getPrinterId(variables)}`).json();
        },
        onSuccess: function onSuccessDeletePrinter(_data, variables, _context) {
            const id = getPrinterId(variables);
            const printerToRemove = { id };
            queryCache.invalidateQueries(["printers", id]);
            queryCache.setQueryData<Printer[]>(["printers"], removePrinterUpdater(printerToRemove));
            queryCache.invalidateQueries(["printers"]);

            const name = getPrinterName(variables);
            setSnack(name ? <>Deleted printer: &ldquo;{name}&rdquo;.</> : "Deleted printer.");
        },
        onError: function onErrorDeletePrinter(error, _variables, _context) {
            showError(ErrorMessage.FailedToUpdatePrinter, error);
        },
    });
}
