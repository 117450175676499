import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";

interface PanelProps {
    title: string;
    helpText?: ReactNode;
    fullWidth?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: { display: "flex", flexDirection: "row", alignItems: "baseline", gap: `${theme.spacing(2)}px` },
        title: {
            margin: theme.spacing(4, 0, 2),
        },
        popoverTypography: {
            padding: theme.spacing(2),
        },
    })
);

export const Panel: React.FC<React.PropsWithChildren<PanelProps>> = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className={classes.titleContainer}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                </div>
                {children}
            </Grid>
        </Grid>
    );
};
