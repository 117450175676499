import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useRef } from "react";
import { QueryKey } from "react-query";
import { featureFlags } from "./featureFlags";
import { bindConsoleLog, noopLogger } from "./log";

const log = featureFlags.logSpinReasons ? bindConsoleLog("useSpinner()") : noopLogger;

interface StopSpinner {
    (): void;
}

function convertQueryKeyToUniqueReasonString(reason: QueryKey) {
    if (typeof reason === "string") {
        return reason;
    } else {
        return reason.map((unk) => `${unk}`).join(", ");
    }
}

const spinReasonsAtom = atom([] as symbol[]);

function useSpinnerState() {
    const [reasons, setReasons] = useAtom(spinReasonsAtom);
    const addSpinReason = useCallback(
        function addSpinReason(reason: symbol) {
            setReasons((prevReasons) => {
                if (prevReasons.indexOf(reason) !== -1) {
                    // throw new Error(`Cannot add a duplicate spin reason: "${reason.description}"`);
                    console.error(`Added a duplicate spin reason: "${reason.description}"`);
                }
                return [...prevReasons, reason];
            });
        },
        [setReasons]
    );
    const removeSpinReason = useCallback(
        function removeSpinReason(reason: symbol) {
            setReasons((prevReasons) => {
                if (prevReasons.indexOf(reason) === -1) {
                    // throw new Error(`Cannot remove spin reason that is not in the reason list: "${reason}"`);
                    console.error(
                        `Tried to remove a spin reason that is not in the reason list: "${reason.description}"`
                    );
                }
                return prevReasons.filter((r) => r !== reason);
            });
        },
        [setReasons]
    );
    return { reasons, addSpinReason, removeSpinReason };
}

export const useSpinner = (): {
    spinning: boolean;
    /** Imperative control over spinner */
    startSpinner(reason: string | QueryKey): StopSpinner;
    reasons: string[];
} => {
    const { reasons, addSpinReason, removeSpinReason } = useSpinnerState();
    const spinning = reasons.length > 0;
    const startSpinner = useCallback(
        (reason: string | QueryKey): StopSpinner => {
            // log(`startSpinner - ${reason}`);
            const reasonSymbol = Symbol(convertQueryKeyToUniqueReasonString(reason));
            addSpinReason(reasonSymbol);
            return () => {
                // log(`stopSpinner - ${reason}`);
                removeSpinReason(reasonSymbol);
            };
        },
        [addSpinReason, removeSpinReason]
    );
    const isFirstReasonChange = useRef(true);
    useEffect(
        function logSpinReason() {
            if (isFirstReasonChange.current) {
                isFirstReasonChange.current = false;
                return;
            }

            if (featureFlags.logSpinReasons) {
                log(
                    `reasons (${reasons.length}): ${JSON.stringify(
                        reasons.map((reason) => (reason.description === undefined ? "" : reason.description))
                    )}`
                );
            }
        },
        [reasons]
    );
    return {
        startSpinner,
        spinning,
        reasons: reasons.map((reason) => (reason.description === undefined ? "" : reason.description)),
    };
};

/** Declarative control over spinner */
export const useSpinnerEffect: (spin: boolean, reason: string | QueryKey) => void = (spin, reason) => {
    const { startSpinner } = useSpinner();
    const stopSpinnerRef = useRef<StopSpinner>();
    useEffect(
        function spinEffect() {
            // log("useSpinnerEffect", { spin, reason });
            function safeStart() {
                if (!stopSpinnerRef.current) {
                    // log("useSpinnerEffect", "safeStart", { spin, reason });
                    stopSpinnerRef.current = startSpinner(reason);
                } else {
                    // log("useSpinnerEffect", "safeStart NOOP", { spin, reason });
                }
            }
            function safeStop() {
                if (stopSpinnerRef.current) {
                    // log("useSpinnerEffect stopping", { spin, reason });
                    stopSpinnerRef.current();
                    stopSpinnerRef.current = undefined;
                } else {
                    // log("useSpinnerEffect stopping NOOP", { spin, reason });
                }
            }
            if (spin) {
                safeStart();
            } else {
                safeStop();
            }

            return () => {
                // log("useSpinnerEffect stopping effect-callback", { spin, reason });
                safeStop();
            };
        },
        [spin, reason, startSpinner, stopSpinnerRef]
    );
};
