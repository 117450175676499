import MonacoEditor, { EditorDidMount, EditorProps } from "@monaco-editor/react";
import { CSSProperties, useRef, useState } from "react";
import * as React from "react";
import useResizeObserver from "use-resize-observer";

interface ResizableMonacoEditorProps {
    outerDivStyle?: CSSProperties;
    innerDivStyle?: CSSProperties;
}

type ObservedSize = {
    width: number | undefined;
    height: number | undefined;
};

type ResizeHandler = (size: ObservedSize) => void;

export const defaultOuterDivStyle: CSSProperties = {
    position: "relative",
    height: "100%",
};

export const defaultInnerDivStyle: CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
};

/**
 * Adds resizing capability to the monaco editor provided by @monaco-editor/react.
 */
export const ResizableMonacoEditor: React.FC<EditorProps & ResizableMonacoEditorProps> = ({
    outerDivStyle,
    innerDivStyle,
    ...editorProps
}) => {
    const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();

    const innerDivRef = useRef<HTMLDivElement>(null);

    const handleEditorDidMount: EditorDidMount = (_, editor) => {
        setEditor(editor);
        editorProps.editorDidMount?.(_, editor);

        const needToSetInitialSize = editorProps.options?.automaticLayout === false;
        if (needToSetInitialSize && editor && innerDivRef.current) {
            const { width, height } = innerDivRef.current.getBoundingClientRect();
            editor.layout({ width, height });
        }
    };

    const handleResizeInnerDiv: ResizeHandler = (size) => {
        const { width, height } = size;
        if (!editor || width === undefined || height === undefined) {
            return;
        }
        editor.layout({ width, height });
    };

    useResizeObserver({ ref: innerDivRef, onResize: handleResizeInnerDiv });

    const actualEditorProps = {
        ...editorProps,
        editorDidMount: handleEditorDidMount,
    };

    const actualOuterDivStyle = outerDivStyle || defaultOuterDivStyle;
    const actualInnerDivStyle = innerDivStyle || defaultInnerDivStyle;

    return (
        <div style={actualOuterDivStyle}>
            <div style={actualInnerDivStyle} ref={innerDivRef}>
                <MonacoEditor {...actualEditorProps} />
            </div>
        </div>
    );
};
