import * as React from "react";
import { useEffect } from "react";
import { usePageTitleContext } from "../PageTitleContext";
import { ConnectivityContainer } from "./ConnectivityContainer";

export const ConnectivityPage: React.FC = () => {
    const { setPageTitle } = usePageTitleContext();
    useEffect(() => setPageTitle("Test Connectivity"), [setPageTitle]);

    return <ConnectivityContainer showDetails />;
};
