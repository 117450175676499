import { useCallback, useMemo } from "react";
import { useQueryStringKey } from "use-route-as-state";

export function useQueryStringKeyInt(key: string, defaultValue?: number): [number, (value: number) => void] {
    return useQueryStringKeyConverter({
        key,
        deserialize: convertStringToInt,
        serialize: convertIntToString,
        defaultValue: defaultValue === undefined ? defaultValue : convertIntToString(defaultValue),
    });
}

function tryParseInt(value: unknown, defaultResult: number) {
    if (typeof value === "string") {
        const result = parseInt(value);
        if (!isNaN(result)) {
            return result;
        }
    }
    return defaultResult;
}

type UseQueryStringKeyConverterOptions<T> = {
    /** key of the query param */
    key: string;
    /** function that converts query param value to state value */
    deserialize: (value: string | string[] | undefined) => T;
    /** function that converts state value to query param value */
    serialize: (value: T) => string | string[];
    /** default value */
    defaultValue?: string | string[];
};

function useQueryStringKeyConverter<T>({
    key,
    deserialize,
    serialize,
    defaultValue,
}: UseQueryStringKeyConverterOptions<T>): [T, (value: T) => void] {
    const [qsValue, setQSValue] = useQueryStringKey(key, defaultValue);
    const value = useMemo(() => {
        return deserialize(qsValue);
    }, [deserialize, qsValue]);
    const set = useCallback(
        (value: T) => {
            setQSValue(serialize(value));
        },
        [serialize, setQSValue]
    );
    return [value, set];
}

function convertStringToInt(value: string | string[] | undefined) {
    return tryParseInt(value, 0);
}

function convertIntToString(value: number): string | string[] {
    return value?.toString();
}
