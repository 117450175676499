import FavoriteBorderFilledIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import FavoriteBorderSharpIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavoriteBorderTwoToneIcon from "@material-ui/icons/FavoriteBorderTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const favoriteBorderIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FavoriteBorderFilledIcon;
        case IconStyle.Outlined:
            return FavoriteBorderOutlinedIcon;
        case IconStyle.Rounded:
            return FavoriteBorderRoundedIcon;
        case IconStyle.TwoTone:
            return FavoriteBorderTwoToneIcon;
        case IconStyle.Sharp:
            return FavoriteBorderSharpIcon;
    }
};

export const FavoriteBorderIcon = createIconComponent(favoriteBorderIconSelector);
