import CssBaseline from "@material-ui/core/CssBaseline";
import * as React from "react";
import { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { AppFrame, AppHeaderSpacer, AppMain } from "./AppLayout";
import { RequireAuth } from "./Auth";
import { ConnectivityPage } from "./Connectivity";
import { useAppCustomizations } from "./Extensibility/state";
import { Header } from "./Header";
import {
    ApiDashboard,
    ChecklistCreationParameterPage,
    ChecklistCreationTemplatePickerPage,
    ChecklistDetailPage,
    ChecklistListPage,
    DataFilePage,
    Explorer,
    Help,
    Home,
    HubPage,
    MenuListPage,
    MenuPage,
    PrinterListPage,
    PrinterPage,
    PrintJobListPage,
    PrintJobPage,
    ProcedurePage,
    Profile,
    ScreenListPage,
    ScreenPage,
} from "./Lazy";
import { NavContainer } from "./NavContainer";
import {
    routesToDataFilePages,
    routeToApiDashboardPage,
    routeToChecklistCreationParameterPage,
    routeToChecklistCreationTemplatePickerPage,
    routeToChecklistDetailPage,
    routeToChecklistListPage,
    routeToConnectivityPage,
    routeToExplorerPage,
    routeToHelpPage,
    routeToHomePage,
    routeToHubPage,
    routeToMenuListPage,
    routeToMenuPage,
    routeToPrinterListPage,
    routeToPrinterPage,
    routeToPrintJobListPage,
    routeToPrintJobPage,
    routeToProcedurePage,
    routeToProfilePage,
    routeToScreenListPage,
    routeToScreenPage,
    useRouteMatchExplorerPage,
    useRouteMatchMenuPage,
    useRouteMatchPrintJobPage,
    useRouteMatchProcedurePage,
    useRouteMatchScreenPage,
} from "./Routing";
import { ShowErrorTests } from "./ShowErrorTests";

export const App: React.FC = () => {
    const menuMatch = useRouteMatchMenuPage();
    const screenMatch = useRouteMatchScreenPage();
    const procedureMatch = useRouteMatchProcedurePage();
    const explorerMatch = useRouteMatchExplorerPage();
    const printJobListMatch = useRouteMatchPrintJobPage();

    const { components, buildRoutes } = useAppCustomizations();

    return useMemo(() => {
        const isUnpaddedPage = Boolean(
            menuMatch ||
                screenMatch ||
                procedureMatch ||
                explorerMatch ||
                (printJobListMatch && !printJobListMatch.isExact)
        );

        const ActualHome = components?.Home ?? Home;

        const customRoutes = buildRoutes([]);

        return (
            <>
                <CssBaseline />
                <AppFrame>
                    <Header />
                    <NavContainer />

                    <AppMain unpadded={isUnpaddedPage}>
                        <AppHeaderSpacer />
                        <Switch>
                            <Route {...routeToHomePage}>
                                <ActualHome />
                            </Route>
                            <Route {...routeToHelpPage}>
                                <RequireAuth>
                                    <Help />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToProfilePage}>
                                <RequireAuth>
                                    <Profile />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToMenuListPage}>
                                <MenuListPage />
                            </Route>
                            <Route {...routeToMenuPage}>
                                <RequireAuth>
                                    <MenuPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToProcedurePage}>
                                <RequireAuth>
                                    <ProcedurePage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToScreenListPage}>
                                <ScreenListPage />
                            </Route>
                            <Route {...routeToScreenPage}>
                                <RequireAuth>
                                    <ScreenPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToApiDashboardPage}>
                                <RequireAuth>
                                    <ApiDashboard />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToPrinterListPage}>
                                <RequireAuth>
                                    <PrinterListPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToPrinterPage}>
                                <RequireAuth>
                                    <PrinterPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToPrintJobListPage}>
                                <RequireAuth>
                                    <PrintJobListPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToPrintJobPage}>
                                <RequireAuth>
                                    <PrintJobPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToExplorerPage}>
                                <RequireAuth>
                                    <Explorer />
                                </RequireAuth>
                            </Route>
                            {routesToDataFilePages.map((routeProps) => (
                                <Route key={routeProps.path} {...routeProps}>
                                    <RequireAuth>
                                        <DataFilePage />
                                    </RequireAuth>
                                </Route>
                            ))}
                            <Route {...routeToChecklistListPage}>
                                <RequireAuth>
                                    <ChecklistListPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToChecklistCreationTemplatePickerPage}>
                                <RequireAuth>
                                    <ChecklistCreationTemplatePickerPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToChecklistCreationParameterPage}>
                                <RequireAuth>
                                    <ChecklistCreationParameterPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToChecklistDetailPage}>
                                <RequireAuth>
                                    <ChecklistDetailPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToHubPage}>
                                <RequireAuth>
                                    <HubPage />
                                </RequireAuth>
                            </Route>
                            <Route {...routeToConnectivityPage}>
                                <ConnectivityPage />
                            </Route>
                            <Route exact path="/test-show-error">
                                <ShowErrorTests />
                            </Route>
                            {customRoutes.map((routeProps, i) => (
                                <Route key={`CustomRoute.${i}`} {...routeProps} />
                            ))}
                        </Switch>
                    </AppMain>
                </AppFrame>
            </>
        );
    }, [menuMatch, screenMatch, procedureMatch, explorerMatch, printJobListMatch, components?.Home, buildRoutes]);
};
