import queryString from "query-string";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { useSpinner } from "../SpinnerContext";
import { checklistQueryKey } from "../types";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";

interface Parameters {
    checklistId: string;
    startDate?: Date;
    dueDate?: Date;
}

export function useScheduleChecklistMutation() {
    const queryCache = useQueryClient();

    const mutation = useMutation(scheduleChecklist, {
        onSuccess: (data, mutationVariables) => {
            const queryKey: QueryKey = checklistQueryKey(mutationVariables.checklistId);
            if (data) {
                queryCache.setQueryData(queryKey, data);
            } else {
                queryCache.invalidateQueries(queryKey);
            }
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const { startSpinner } = useSpinner();

    async function scheduleChecklist({ checklistId, startDate, dueDate }: Parameters) {
        const qs = queryString.stringify({ startDate: startDate?.toJSON(), dueDate: dueDate?.toJSON() });
        const apiUrl = `checklists/${checklistId}/schedule${qs ? `?${qs}` : ""}`;
        const stopSpinner = startSpinner(apiUrl);
        try {
            const response = await authorizedFetch(apiUrl, {
                method: "POST",
            });
            if (response.status >= 200 && response.status < 300) {
                setSnack("Updated checklist schedule.");
                const json = await response.text();
                try {
                    return parseJsonWebApiChecklist(json);
                } catch (ex) {
                    showError(ErrorMessage.FailedToParseChecklist, response);
                    throw ex;
                }
            } else {
                showError(ErrorMessage.FailedToScheduleChecklist, response);
            }
        } finally {
            stopSpinner();
        }
    }

    return mutation;
}
