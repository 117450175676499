import { ActionType, TableInstance, TableState } from "react-table";

// The react-table function `toggleAllRowsSelected` does not work as specified.  This is a workaround I found in a
// github discussion.
// https://github.com/tannerlinsley/react-table/issues/3142#issuecomment-822482864
export function tableStateReducer<T extends object = {}>(
    newState: TableState<T>,
    action: ActionType,
    _previousState: TableState<T>,
    _instance?: TableInstance<T>
) {
    if (action.type === deselectAllRowsKey) {
        return { ...newState, selectedRowIds: {} };
    }

    return newState;
}

export const deselectAllRowsKey = "deselectAllRows";
