import { WebApiScreen } from "./Api";
import { omitProps } from "./omitProps";

/* eslint-disable @typescript-eslint/no-redeclare */
type MenuCommandKey = "menu";
const MenuCommandKey = "menu";
interface MenuCommand {
    type: MenuCommandKey;
    library: string;
    menu: string;
}

type ProcedureCommandKey = "procedure";
const ProcedureCommandKey = "procedure";
interface ProcedureCommand {
    type: ProcedureCommandKey;
    library: string;
    procedure: string;
    procedureParameters: string;
    stepPath: number[];
}

type ScreenCommandKey = "screen";
const ScreenCommandKey = "screen";
export interface ScreenCommand {
    type: ScreenCommandKey;
    library: string;
    sourceMember: string;
    screen: string;
    screenBuffer: string;
    stepPath: number[];
    indicatorsOn?: string[];
    layoutTopRowNumber?: number;
}

type EndOfJobCommandKey = "endofjob";
const EndOfJobCommandKey = "endofjob";
interface EndOfJobCommand {
    type: EndOfJobCommandKey;
}

type PromptInputCommandKey = "promptinput";
const PromptInputCommandKey = "promptinput";
interface PromptInputCommand {
    type: PromptInputCommandKey;
    stepPath: number[];
}

type PromptOutputCommandKey = "promptoutput";
const PromptOutputCommandKey = "promptoutput";
interface PromptOutputCommand {
    type: PromptOutputCommandKey;
    message: string;
    stepPath: number[];
}

export enum DataFileUtilityCommandMode {
    /** Default to add records */
    Enter = "enter",
    /** Read-only view of a DFU */
    Inquiry = "inquiry",
    /** Default behavior */
    Update = "update",
}

type DataFileUtilityCommandKey = "dfu";
const DataFileUtilityCommandKey = "dfu";
interface DataFileUtilityMenuCommand {
    type: DataFileUtilityCommandKey;
    dfuName: string;
    fileName: string;
}

interface DataFileUtilityProcedureCommand extends DataFileUtilityMenuCommand {
    stepPath: number[];
    mode: DataFileUtilityCommandMode;
}

export type Command =
    | MenuCommand
    | ProcedureCommand
    | ScreenCommand
    | EndOfJobCommand
    | PromptInputCommand
    | PromptOutputCommand
    | DataFileUtilityMenuCommand
    | DataFileUtilityProcedureCommand;

export function isMenuCommand(x: Command | undefined | null): x is MenuCommand {
    return x !== undefined && x !== null && typeof x === "object" && "type" in x && x.type === MenuCommandKey;
}

export function isProcedureCommand(x: Command | undefined | null): x is ProcedureCommand {
    return x !== undefined && x !== null && "type" in x && x.type === ProcedureCommandKey;
}

export function isScreenCommand(x: Command | undefined | null): x is ScreenCommand {
    return x !== undefined && x !== null && "type" in x && x.type === ScreenCommandKey;
}

export function isEndOfJobCommand(x: Command | undefined | null): x is EndOfJobCommand {
    return x !== undefined && x !== null && "type" in x && x.type === EndOfJobCommandKey;
}

export function isDataFileUtilityCommand(
    x: Command | undefined | null
): x is DataFileUtilityMenuCommand | DataFileUtilityProcedureCommand {
    return x?.type === DataFileUtilityCommandKey;
}

export function isDataFileUtilityProcedureCommand(x: Command | undefined | null): x is DataFileUtilityProcedureCommand {
    return (
        x !== undefined &&
        x !== null &&
        "type" in x &&
        x.type === DataFileUtilityCommandKey &&
        "stepPath" in x &&
        "mode" in x
    );
}

export function isPromptOutputCommand(x: Command | undefined | null): x is PromptOutputCommand {
    return x !== undefined && x !== null && "type" in x && x.type === PromptOutputCommandKey;
}

export function isPromptInputCommand(x: Command | undefined | null): x is PromptInputCommand {
    return x !== undefined && x !== null && "type" in x && x.type === PromptInputCommandKey;
}

export function cleanseCommand<T extends Command>(command: T) {
    return omitProps(command, "outputType");
}

export type EnhancedScreenCommandKey = "enhanced-screen-command";
export const EnhancedScreenCommandKey = "enhanced-screen-command";
/**
 * Model of all data needed to render a screen.
 */
export interface EnhancedScreenCommand {
    type: EnhancedScreenCommandKey;
    /**
     * Screen command returned by the procedures endpoint.
     */
    command: ScreenCommand;
    /**
     * The static screen data associated with the command.
     */
    webApiScreen: WebApiScreen;
    /**
     * The input buffer that was submitted to this screen.
     */
    inputBuffer?: string;
    /**
     * Is this command a part of the most recent procedure call?
     */
    isFromLatestInvocation: boolean;
}

export function isEnhancedScreenCommand(
    x: Command | EnhancedScreenCommand | undefined | null
): x is EnhancedScreenCommand {
    return x !== undefined && x !== null && "type" in x && x.type === EnhancedScreenCommandKey;
}
