import { useMemo } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { useAppCustomizations } from "../Extensibility/state";
import { MenuIdentifier, MenuTree } from "../types";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { useKy } from "./useKy";
import { useTwinOakQuery, UseTwinOakQueryOptions } from "./useTwinOakQuery";

export function convertMenuTreeToList(menuTree: MenuTree): MenuIdentifier[] {
    return Object.keys(menuTree).flatMap((library) =>
        Object.keys(menuTree[library]).map((name) => {
            const { title, items, isEnabled, isEmphasized } = menuTree[library][name];
            return { library, name, title, items, isEnabled, isEmphasized };
        })
    );
}

function convertMenusToMenuTree(menus: MenuIdentifier[]): MenuTree {
    return menus.reduce((acc, value) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { library, name, ...menuContent } = value;
        return { ...acc, [value.library]: { ...acc[value.library], [value.name]: menuContent } };
    }, {} as MenuTree);
}

export const menuTreeQueryKey = "menu-tree";

const urlToGetMenuListApi = "menus";

const defaultMenuTree: MenuTree = {};

export function useMenuTree(queryOptions?: UseTwinOakQueryOptions<MenuTree>) {
    const ky = useKy();
    const {
        apiInterceptors: { rpgMenuTree },
    } = useAppCustomizations();

    const { data } = useTwinOakQuery(
        useMemo(
            () => ({
                queryKey: [menuTreeQueryKey],
                queryFn: async () => {
                    const menuTree = convertMenusToMenuTree(await ky(urlToGetMenuListApi).json());
                    return rpgMenuTree === undefined ? menuTree : rpgMenuTree(menuTree);
                },
                errorMessage: ErrorMessage.FailedToLoadMenus,
                ...queryOptionsDataChangesInfrequently,
                ...queryOptions,
            }),
            [ky, queryOptions, rpgMenuTree]
        )
    );
    return data || defaultMenuTree;
}
