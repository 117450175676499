import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import { AppConfig } from "../../AppConfig";
import { AzureAdAuthAdapterProvider } from "./AzureAdAuthAdapterProvider";
import { getPublicClientApplication } from "./getPublicClientApplication";

export const AzureAdAuthProvider: React.FC<React.PropsWithChildren<{ appConfig: AppConfig }>> = ({
    appConfig,
    children,
}) => {
    if (!appConfig.clientId) {
        console.error("App config is invalid, because security scheme is set to azureAD and clientId is **not** set.");
        return <></>;
    }
    const publicClientApplication = getPublicClientApplication(appConfig);
    return (
        <MsalProvider instance={publicClientApplication}>
            <AzureAdAuthAdapterProvider appConfig={appConfig}>{children}</AzureAdAuthAdapterProvider>
        </MsalProvider>
    );
};
