export enum DataFileMode {
    Edit = "edit",
    SearchByRecordNumber = "search-by-record-number",
    SearchByExample = "search-by-example",
    Add = "add",
}

export enum DataFileProcedureMode {
    ProcedureInquiry = "procedure-inquiry",
    ProcedureUpdate = "procedure-update",
    ProcedureEnter = "procedure-enter",
}

// These silly little types could be useful later if we decide to change the types.
type DataFileUtilityId = string;
type FileId = string;
type RecordType = string;
type FieldId = string;
type FieldValue = unknown;
export type RecordId = string;
export type RecordNumber = number;

/**
 * Represents which `UPDATE` menu item the user chose.  These values are all derived from the `*##.txt` RPG source
 * files.
 */
export interface DataFileMenuItemIdentifier {
    /** the data file */
    dfuName: string;
    /** the file */
    fileName: string;
}

/** Metadata of a key field in a data file */
export interface KeyFieldMetadata {
    /**
     * An opaue identifier used by the backend to identify the field.  We are making some assumptions about it, since
     * we are also using it in the front-end to reference the fields.  If this is problematic, we can add a mapping
     * layer here.
     */
    name: FieldId;
    /** Label displayed for the user */
    label: string;
    /** The datatype of the field.  If "number", only 0-9 is allowed. */
    type: "string" | "number";
    /** Maximum allowed length of the field */
    length: number;
}

/** Metadata of a non-key field in a data file */
export interface FieldMetadata extends KeyFieldMetadata {
    /**
     * Should the field be rendered as read-only?  May appear as "Output only" in the AS/400's DFU customization UI.
     */
    readonly: boolean;
    /** Should the field be shown to the user?  May appear as "Non-display" in the AS/400's DFU customization UI. */
    hidden: boolean;
}

/**
 * Metadata of a record in a data file.  A data file will have one or more sets of record metadata.
 */
export interface RecordMetadata {
    /** An opque identifier, used to reference this object */
    recordType: RecordType;
    /** Key field metadata */
    keyFields: KeyFieldMetadata[];
    /** Field metadata */
    fields: FieldMetadata[];
}

/**
 * Metadata of a data file.  Includes all possible record metadata. We expect this to be returned from the "data file
 * metadata" web api.
 */
export interface DataFileMetadata {
    /** The data file */
    dataFile: string;
    /** Identifier for the datafile / format tuple. */
    dfuId: DataFileUtilityId;
    /** Identifier for the file. */
    fileId: FileId;
    /** Various data types we expect to encounter as we go through the records. */
    recordMetadata: RecordMetadata[];
}

/**
 * Data from a data file.
 */
export interface RecordData {
    /** id of this record in the data file */
    recordId: RecordId;
    /** id of the record metadata that should be used with this record */
    recordType: RecordType;
    /** the data */
    values: Record<FieldId, FieldValue>;
}

export type RecordDirection = "next" | "previous";
export type RecordAbsolutePosition = "firstRecord" | "lastRecord";

/** various ways to identify a single record */
export type RecordLocator =
    | {
          recordId: RecordId;
          recordDirection?: RecordDirection;
      }
    | {
          recordNumber: number;
      }
    | {
          recordAbsolutePosition: RecordAbsolutePosition;
      };

export const qbePrefix = "qbe-";
