import StarBorderFilledIcon from "@material-ui/icons/StarBorder";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarBorderSharpIcon from "@material-ui/icons/StarBorderSharp";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const starBorderIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return StarBorderFilledIcon;
        case IconStyle.Outlined:
            return StarBorderOutlinedIcon;
        case IconStyle.Rounded:
            return StarBorderRoundedIcon;
        case IconStyle.TwoTone:
            return StarBorderTwoToneIcon;
        case IconStyle.Sharp:
            return StarBorderSharpIcon;
    }
};

export const StarBorderIcon = createIconComponent(starBorderIconSelector);
