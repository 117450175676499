import AddBoxFilledIcon from "@material-ui/icons/AddBox";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import AddBoxSharpIcon from "@material-ui/icons/AddBoxSharp";
import AddBoxTwoToneIcon from "@material-ui/icons/AddBoxTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const addBoxIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return AddBoxFilledIcon;
        case IconStyle.Outlined:
            return AddBoxOutlinedIcon;
        case IconStyle.Rounded:
            return AddBoxRoundedIcon;
        case IconStyle.TwoTone:
            return AddBoxTwoToneIcon;
        case IconStyle.Sharp:
            return AddBoxSharpIcon;
    }
};

export const AddBoxIcon = createIconComponent(addBoxIconSelector);
