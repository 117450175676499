import * as React from "react";
import WifiIndicator, { SignalStrength } from "react-wifi-indicator";
import { ConnectivityStatus } from "./ConnectivityStatus";

function convertToSignalStrength({ effectiveType, downlink }: ConnectivityStatus) {
    return typeof downlink === "number" && downlink <= 0
        ? SignalStrength.DISCONNECTED
        : effectiveType === "slow-2g"
        ? SignalStrength.WEAK
        : effectiveType === "2g"
        ? SignalStrength.OKAY
        : effectiveType === "3g"
        ? SignalStrength.GREAT
        : SignalStrength.EXCELLENT;
}

/**
 * Indicate connectivity using a third party component.
 * @param props
 */
export const ConnectivityIndicator: React.FC<ConnectivityStatus> = (props) => {
    const strength = convertToSignalStrength(props);
    return (
        <div>
            <WifiIndicator strength={strength} />
        </div>
    );
};
