import { Snackbar, SnackbarProps } from "@material-ui/core";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import * as React from "react";

const Alert: React.FC<AlertProps> = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export interface SnackProps {
    severity?: Color;
    onClose(): void;
    snackbarProps?: SnackbarProps;
}

export const Snack: React.FC<React.PropsWithChildren<SnackProps>> = ({
    severity: severityProp,
    onClose,
    snackbarProps,
    children,
}) => {
    const severity = severityProp ?? "info";
    return (
        <Snackbar
            open={!!children}
            autoHideDuration={10000}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            {...snackbarProps}
        >
            {children ? (
                <Alert onClose={onClose} severity={severity}>
                    {children}
                </Alert>
            ) : undefined}
        </Snackbar>
    );
};
