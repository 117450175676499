/**
 * Used in conjunction with JSON.parse to convert date strings into Date objects.
 * @param dateFields
 */
export const dateTimeReviver: <T>(dateFieldNames: string[]) => (this: unknown, key: string, value: T) => T | Date =
    (dateFields) => (key, value) => {
        if (!value || dateFields.indexOf(key) === -1 || typeof value !== "string") {
            return value;
        }
        return new Date(value);
    };
