import { makeStyles, SvgIconProps } from "@material-ui/core";
import * as React from "react";
import { useUserSettings } from "../Api";
import { FavoriteIconEnum } from "../UserSettings";
import { BookmarkBorderIcon } from "./BookmarkBorderIcon";
import { BookmarkIcon } from "./BookmarkIcon";
import { FavoriteBorderIcon } from "./FavoriteBorderIcon";
import { FavoriteIcon } from "./FavoriteIcon";
import { StarBorderIcon } from "./StarBorderIcon";
import { StarIcon } from "./StarIcon";

export const useFavoriteStyles = makeStyles((theme) => ({
    favoriteHeart: {
        color: "Red",
    },
    favoriteStar: {
        color: "Gold",
    },
    favoriteBookmark: {
        color: theme.palette.primary.main,
    },
    unfavoriteHeart: {
        // color: "Red",
    },
    unfavoriteStar: {
        // color: "Gold",
    },
    unfavoriteBookmark: {
        // color: theme.palette.primary.main,
    },
}));

export const MyFavoriteIconContainer: React.FC<SvgIconProps> = (props) => {
    const { favoriteIcon } = useUserSettings();
    return <MyFavoriteIcon favoriteIcon={favoriteIcon} {...props} />;
};

export const MyFavoriteIcon: React.FC<{ favoriteIcon: FavoriteIconEnum } & SvgIconProps> = ({
    favoriteIcon,
    ...props
}) => {
    const classes = useFavoriteStyles();

    let Icon: React.FC<SvgIconProps>;
    let className: string;

    switch (favoriteIcon) {
        default:
        case FavoriteIconEnum.Heart:
            Icon = FavoriteIcon;
            className = classes.favoriteHeart;
            break;
        case FavoriteIconEnum.Bookmark:
            Icon = BookmarkIcon;
            className = classes.favoriteBookmark;
            break;
        case FavoriteIconEnum.Star:
            Icon = StarIcon;
            className = classes.favoriteStar;
            break;
    }

    return <Icon {...props} className={className} />;
};

export const MyUnfavoriteIconContainer: React.FC<SvgIconProps> = (props) => {
    const { favoriteIcon } = useUserSettings();
    return <MyUnfavoriteIcon favoriteIcon={favoriteIcon} {...props} />;
};

export const MyUnfavoriteIcon: React.FC<{ favoriteIcon: FavoriteIconEnum } & SvgIconProps> = ({
    favoriteIcon,
    ...props
}) => {
    const classes = useFavoriteStyles();

    let Icon: React.FC<SvgIconProps>;
    let className: string;

    switch (favoriteIcon) {
        default:
        case FavoriteIconEnum.Heart:
            Icon = FavoriteBorderIcon;
            className = classes.unfavoriteHeart;
            break;
        case FavoriteIconEnum.Bookmark:
            Icon = BookmarkBorderIcon;
            className = classes.unfavoriteBookmark;
            break;
        case FavoriteIconEnum.Star:
            Icon = StarBorderIcon;
            className = classes.unfavoriteStar;
            break;
    }

    return <Icon {...props} className={className} />;
};
