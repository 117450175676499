import { v4 as uuidv4 } from "uuid";
import { sessionStorageKeys } from "./sessionStorageKeys";

/** Get a unique id for the current browser window. */
export function getBrowserContextId(): string {
    const cachedId = sessionStorage.getItem(sessionStorageKeys.browserContextId);
    if (cachedId) {
        return cachedId;
    }
    const generatedId = uuidv4();
    sessionStorage.setItem(sessionStorageKeys.browserContextId, generatedId);
    return generatedId;
}
