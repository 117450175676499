import * as React from "react";
import { createRoot } from "react-dom/client";
import { bootstrapTwinOakApp, TwinOakProviders } from "./Extensibility";
import { PamsApp as App } from "./PamsApp";

(async function bootstrapReact() {
    await bootstrapTwinOakApp();

    const container = document.getElementById("root");
    const root = createRoot(container!);

    root.render(
        <TwinOakProviders>
            <App />
        </TwinOakProviders>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA

    // Comment this out so we can use service workers in storybook.
    // serviceWorker.unregister();
})();
