import { HTTPError } from "ky";
import { useMemo } from "react";
import { MutateOptions, useMutation } from "react-query";
import { cleanseCommand, Command } from "../Command";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { featureFlags } from "../featureFlags";
import { getBrowserContextId } from "../getBrowserContextId";
import { bindConsoleLog, noopLogger } from "../log";
import { logEmojis } from "../logEmojis";
import { useSetSnack } from "../SnackContext";
import { useSpinner } from "../SpinnerContext";
import { useKy } from "./useKy";

const log = featureFlags.logProcedurePage ? bindConsoleLog("runProcedure()") : noopLogger;

export interface RunProcedureVariables {
    library: string;
    procedure: string;
    procedureParameters?: string | string[];
    commandKey?: string;
    screenBuffer?: string;
    stepPath?: string | number[];
    promptInput?: string;
}

export type RunProcedureOptions = MutateOptions<Command[], HTTPError, RunProcedureVariables, unknown> | undefined;

export function useRunProcedureMutation() {
    const ky = useKy();
    const { startSpinner } = useSpinner();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    return useMutation<Command[], HTTPError, RunProcedureVariables>(
        useMemo(
            () => ({
                mutationFn: async function runProcedure(variables: RunProcedureVariables) {
                    const {
                        library,
                        procedure,
                        procedureParameters: procedureParametersArg,
                        commandKey,
                        screenBuffer,
                        stepPath: stepPathArg,
                        promptInput,
                    } = variables;

                    if (!library || !procedure) {
                        const error = `Failed to run procedure, because the library and/or procedure prop is not set.`;
                        console.error(error);
                        throw new Error(error);
                    }

                    const procedureUrl = `procedures/${library}/${procedure}`;

                    const stepPath =
                        typeof stepPathArg === "string" ? stepPathArg.split(",").map(parseInt) : stepPathArg;

                    const procedureParameters =
                        typeof procedureParametersArg === "string"
                            ? procedureParametersArg.split(",")
                            : procedureParametersArg;

                    const browserContextId = getBrowserContextId();

                    setSnack(`Running procedure ${procedure.toUpperCase()}, ${library.toUpperCase()}...`);
                    const bodyObj = {
                        ...(commandKey && { commandKey }),
                        ...(screenBuffer && { screenBuffer }),
                        ...(stepPath && { stepPath }),
                        ...(promptInput && { promptInput }),
                        ...(procedureParameters && { procedureParameters }),
                        browserContextId,
                    };

                    log(`${logEmojis.invocation} starting procedure call`, {
                        procedureUrl,
                        body: bodyObj,
                        variables,
                    });

                    const stopSpinner = startSpinner(procedureUrl);
                    try {
                        const result = (await ky
                            .post(procedureUrl, {
                                json: bodyObj,
                            })
                            .json()) as Command | Command[];
                        const packaged = Array.isArray(result) ? result : [result];
                        return packaged.map((c) => cleanseCommand(c));
                    } finally {
                        stopSpinner();
                    }
                },
                onSuccess: function onSuccessRunProcedure(data, variables, _context) {
                    const { library, procedure } = variables;
                    setSnack(`Ran procedure ${procedure?.toUpperCase()}, ${library?.toUpperCase()}.`);
                    log(`finished procedure call => success ${logEmojis.outcomePositive}`, data);
                },
                onError: function onErrorRunProcedure(error, _variables, _context) {
                    log(`finished procedure call => error ${logEmojis.outcomeNegative}`, error);
                    showError(ErrorMessage.FailedToRunProcedure, error);
                },
            }),
            [ky, setSnack, showError, startSpinner]
        )
    );
}
