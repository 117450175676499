import { atom } from "jotai";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import queryString from "query-string";
import { ReactNode, useMemo } from "react";
import { isSnack, SnackState } from "./SnackState";

type SetSnackParamType = ReactNode | SnackState;

export interface SetSnack {
    (snack: SetSnackParamType): void;
}

const defaultSnack: SnackState = {
    severity: "info",
    children: null,
};

const snackAtomCore = atom<SnackState | undefined>(undefined);

function areSnacksDisabled() {
    const query = queryString.parse(window.location.search);
    return "no-snacks" in query;
}

function getActualSnack(snack: SetSnackParamType) {
    if (areSnacksDisabled()) {
        return undefined;
    } else if (isSnack(snack)) {
        return snack;
    } else {
        return {
            ...defaultSnack,
            children: snack,
        };
    }
}

const snackAtom = atom<SnackState | undefined, SetSnackParamType>(
    (get) => get(snackAtomCore),
    (_get, set, snack) => set(snackAtomCore, getActualSnack(snack))
);

export function useSnack() {
    return useAtomValue(snackAtom);
}

export const useSetSnack: () => { setSnack: (update?: ReactNode | SnackState | undefined) => void } = () => {
    const setSnack = useUpdateAtom(snackAtom);
    return useMemo(() => {
        return {
            setSnack,
        };
    }, [setSnack]);
};
