import * as React from "react";
import { QueryKey, QueryObserverResult } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { bindConsoleLog } from "../log";
import { cleanseWebApiChecklistTemplate } from "./cleanseWebApiChecklistTemplate";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";
import { WebApiChecklistTemplate, WebApiChecklistTemplateParameter } from "./WebApiTypes";

const urlToChecklistTemplatesApi = "checklist-templates";
const queryKeyToChecklistTemplates: QueryKey = ["checklist-templates"];

export function useChecklistTemplates(): QueryObserverResult<WebApiChecklistTemplate[]> {
    const ky = useKy();
    return useTwinOakQuery<WebApiChecklistTemplate[]>(
        React.useMemo(
            () => ({
                queryKey: queryKeyToChecklistTemplates,
                queryFn: async () =>
                    ((await ky(urlToChecklistTemplatesApi).json()) as WebApiChecklistTemplate[]).map(
                        cleanseWebApiChecklistTemplate
                    ),
                errorMessage: ErrorMessage.FailedToLoadChecklistSummaries,
                ...queryOptionsDataChangesInfrequently,
            }),
            [ky]
        )
    );
}

const logUseUniqueChecklistTemplateParameters = bindConsoleLog("useUniqueChecklistTemplateParameters()");

type FlattenedWebApiChecklistTemplateParameter = WebApiChecklistTemplateParameter & {
    templateId: string;
    templateName: string;
};

const emptyArrayOfTemplates: WebApiChecklistTemplate[] = [];
const emptyArrayOfParameters: WebApiChecklistTemplateParameter[] = [];

const ambiguousChecklistTemplateParametersError =
    "The checklist templates have metadata inconsistencies that could complicate how checklists are filtered.  Some " +
    "parameter objects with the same name, but are not identical objects.";

/**
 * Get unique checklist template parameters.  Report any type/label discrepancies among parameters with the same name.
 *
 * If the parameters have not loaded yet, it will still run, but it won't modify the config.
 */
export function useUniqueChecklistTemplateParameters() {
    const { data } = useChecklistTemplates();
    const isFirstError = React.useRef(true);

    return React.useMemo(() => {
        function showErrorOnce() {
            if (!isFirstError.current) {
                return;
            }
            isFirstError.current = false;
            console.error(ambiguousChecklistTemplateParametersError);
        }

        const checklistTemplates = data || emptyArrayOfTemplates;
        if (checklistTemplates.length < 1) {
            return emptyArrayOfParameters;
        }

        // Flatten out the nested objects so we just have a single dimension array.  Project to a new object that
        // includes references back to the parent template.  We'll need the template to report discrepancies in a
        // useful way.
        const parametersFlattened = checklistTemplates
            .flatMap((t) =>
                t.parameters.map<FlattenedWebApiChecklistTemplateParameter>((p) => ({
                    templateName: t.name,
                    templateId: t.id,
                    ...p,
                }))
            )
            .sort((a, b) => {
                return (a.label ?? "").localeCompare(b.label ?? "");
            });

        const parametersGroupedByName = parametersFlattened.reduce<
            Record<string, FlattenedWebApiChecklistTemplateParameter[]>
        >(
            (acc, next) => ({
                ...acc,
                [next.name]: [...(acc[next.name] ?? []), next],
            }),
            {}
        );

        Object.values(parametersGroupedByName).forEach((parametersWithSameName) => {
            if (parametersWithSameName.length <= 1) {
                return;
            }
            const [first, ...remaining] = parametersWithSameName;

            const parametersWithTypeConflicts = remaining.filter((p) => p.type !== first.type);
            if (parametersWithTypeConflicts.length) {
                showErrorOnce();
                logUseUniqueChecklistTemplateParameters(
                    `There are checklist template parameters with the same name (${
                        first.name
                    }), but different types.  We'll be using the first type found: ${JSON.stringify(first.type)}`,
                    [first, ...parametersWithTypeConflicts]
                );
            }

            const parametersWithLabelConflicts = remaining.filter((p) => p.label !== first.label);
            if (parametersWithLabelConflicts.length) {
                showErrorOnce();
                logUseUniqueChecklistTemplateParameters(
                    `There are checklist template parameters with the same name (${
                        first.name
                    }), but different labels.  We'll be using the first label found: ${JSON.stringify(first.label)}`,
                    [first, ...parametersWithLabelConflicts]
                );
            }
        });

        const result = Object.values(parametersGroupedByName).map<WebApiChecklistTemplateParameter>(
            (parametersWithSameName) => {
                const { templateId: _templateId, templateName: _templateName, ...template } = parametersWithSameName[0];
                return template;
            }
        );

        logUseUniqueChecklistTemplateParameters({ checklistTemplates, result });
        return result;
    }, [data]);
}
