import { Operation } from "fast-json-patch";
import { HTTPError } from "ky";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { PrinterSpecification } from "./types";
import { useShowErrorDetails } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useKy } from "./useKy";

type PrintJobId = string;

function urlToStartPrintJobApi(printJobId: PrintJobId) {
    return `print-jobs/${printJobId}/start`;
}

function urlToPatchPrintJobApi(printJobId: PrintJobId) {
    return `print-jobs/${printJobId}`;
}

function urlToUpdatePrintJobPrinterApi(printJobId: PrintJobId) {
    return `print-jobs/${printJobId}/printer-specification`;
}

function urlToDeletePrintJobApi(printJobId: PrintJobId) {
    return `print-jobs/${printJobId}`;
}

export function useStartPrintJobMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    const showErrorDetails = useShowErrorDetails();
    return useMutation<void, HTTPError, PrintJobId>(
        useMemo(
            () => ({
                mutationFn: function startPrintJob(printJobId: PrintJobId) {
                    return ky.post(urlToStartPrintJobApi(printJobId)).json();
                },
                onError: async function handleStartPrintJobError(error, variables, context) {
                    // var problemDetails = await tryGetProblemDetails(error);
                    console.log("[handleStartPrintJobError]", error, variables, context);
                    showErrorDetails(ErrorMessage.FailedToStartPrintJob, error);
                },
                onSuccess: function handleStartPrintJobSuccess(_data, _mutationVariables) {
                    queryCache.invalidateQueries(["print-jobs"]);
                    queryCache.invalidateQueries(["print-job-aggregates"]);
                },
            }),
            [ky, queryCache, showErrorDetails]
        )
    );
}

type PatchMutationOperationsVariables = { id: string; ops: Operation[] };

export function usePatchPrintJobMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    return useMutation<void, Response, PatchMutationOperationsVariables>(
        useMemo(
            () => ({
                mutationFn: function cancelPrintJob({ id, ops }: PatchMutationOperationsVariables) {
                    return ky.patch(urlToPatchPrintJobApi(id), { json: ops }).json();
                },
                onSuccess: (_data, _mutationVariables) => {
                    queryCache.invalidateQueries(["print-jobs"]);
                    queryCache.invalidateQueries(["print-job-aggregates"]);
                },
            }),
            [ky, queryCache]
        )
    );
}

type UpdatePrintJobPrinterMutationVariables = { id: string; printerSpecification: PrinterSpecification };

export function useUpdatePrintJobPrinterMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    return useMutation<void, Response, UpdatePrintJobPrinterMutationVariables>(
        useMemo(
            () => ({
                mutationFn: function updatePrintJobPrinter({
                    id,
                    printerSpecification,
                }: UpdatePrintJobPrinterMutationVariables) {
                    return ky.put(urlToUpdatePrintJobPrinterApi(id), { json: printerSpecification }).json();
                },
                onSuccess: (_data, _mutationVariables) => {
                    queryCache.invalidateQueries(["print-jobs"]);
                    queryCache.invalidateQueries(["print-job-aggregates"]);
                },
            }),
            [ky, queryCache]
        )
    );
}

export function useDeletePrintJobMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    return useMutation({
        mutationFn: useCallback(
            function deletePrintJob(printJobId: PrintJobId) {
                return ky.delete(urlToDeletePrintJobApi(printJobId));
            },
            [ky]
        ),
        onSuccess: (_data, _mutationVariables) => {
            queryCache.invalidateQueries(["print-jobs"]);
            queryCache.invalidateQueries(["print-job-aggregates"]);
        },
    });
}
