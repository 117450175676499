import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import constate from "constate";
import * as H from "history";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppConfig } from "./AppConfig";
import { areWeTesting } from "./areWeTesting";
import { featureFlags } from "./featureFlags";

const reactPlugin = new ReactPlugin();

const createAppInsights = (instrumentationKey: string, history: H.History<H.LocationState>): ApplicationInsights =>
    new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {
                    history,
                },
            },
        },
    });

const useAppInsightsState = (): ApplicationInsights | undefined => {
    const appConfig = useAppConfig();
    const instrumentationKey =
        areWeTesting || featureFlags.disableAppInsights ? "" : appConfig.applicationInsightsInstrumentationKey;
    const [appInsights, setAppInsights] = useState<ApplicationInsights>();
    const history = useHistory();
    useEffect(() => {
        const initializeAppInsights = async (): Promise<void> => {
            if (!instrumentationKey) {
                return;
            }
            const appInsights = createAppInsights(instrumentationKey, history);
            appInsights.loadAppInsights();
            appInsights.trackPageView();
            setAppInsights(appInsights);
        };

        initializeAppInsights();
    }, [instrumentationKey, history]);

    return appInsights;
};

const [AppInsightsProviderWithLegacyType, useAppInsights] = constate(useAppInsightsState);
const AppInsightsProvider = AppInsightsProviderWithLegacyType as React.FC<React.PropsWithChildren<{}>>;

export function withAppInsights<P>(
    Component: React.ComponentType<P>,
    componentName?: string,
    className?: string
): React.ComponentClass<P> {
    return withAITracking(reactPlugin, Component, componentName, className);
}

class Wrapper extends React.Component<React.PropsWithChildren<{}>> {
    render(): ReactNode {
        return <>{this.props.children}</>;
    }
}

const AppInsights = withAppInsights(Wrapper);

export { AppInsightsProvider, useAppInsights, AppInsights };
