import {
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    CardHeader,
    createStyles,
    IconButton,
    Link,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import AccountCircle from "@material-ui/icons/AccountCircle";
import * as React from "react";
import { MouseEventHandler, useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
    convertRefetchIntervalToQueryConfig,
    useMyActiveChecklistSummariesUnpaged,
    useUserSettings,
    useVerifiedGravatarUrlQuery,
} from "./Api";
import { AppHeader, AppHeaderButton } from "./AppLayout";
import { useAuth } from "./Auth";
import { ConnectivityContainer } from "./Connectivity";
import { useAppCustomizations } from "./Extensibility/state";
import { CloseIcon, FeedbackIcon, MenuIcon, NotificationsActiveIcon, NotificationsNoneIcon } from "./Icons";
import { usePageSubTitleContext } from "./PageSubTitleContext";
import { usePageTitleContext } from "./PageTitleContext";
import { urlToHomePage, urlToProfilePage } from "./Routing";
import { Search } from "./Search";
import { useMainMenuOpen } from "./useMainMenuOpen";
import { UserFeedback } from "./UserFeedback";

export const Notifications: React.FC = () => {
    const { hubPageRefetchIntervalInSeconds } = useUserSettings();
    const queryConfig = useMemo(
        () => convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds),
        [hubPageRefetchIntervalInSeconds]
    );
    const { data } = useMyActiveChecklistSummariesUnpaged(queryConfig);
    const count = useMemo(() => data?.length, [data]);
    const [notificationWindowAnchor, setNotificationWindowAnchor] = React.useState<HTMLButtonElement | null>(null);

    return useMemo(() => {
        const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setNotificationWindowAnchor(event.currentTarget);
        };

        const handleNotificationClose = () => setNotificationWindowAnchor(null);

        return !count ? (
            <IconButton color="inherit">
                <NotificationsNoneIcon />
            </IconButton>
        ) : (
            <>
                <IconButton onClick={handleNotificationClick} color="inherit">
                    <Badge badgeContent={count} color="error" overlap="rectangle" variant="dot">
                        <NotificationsActiveIcon />
                    </Badge>
                </IconButton>
                <Popover
                    open={Boolean(notificationWindowAnchor)}
                    anchorEl={notificationWindowAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleNotificationClose}
                >
                    <Card>
                        <CardHeader
                            title="Notifications"
                            action={
                                <IconButton aria-label="cancel" onClick={handleNotificationClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                There are checklist steps assigned to you. You can see them on the{" "}
                                <Link
                                    component={RouterLink}
                                    to={`/hubs/my-tasks`}
                                    onClick={() => handleNotificationClose()}
                                >
                                    &ldquo; My Tasks&rdquo;
                                </Link>{" "}
                                page.
                            </Typography>
                        </CardContent>
                    </Card>
                </Popover>
            </>
        );
    }, [count, notificationWindowAnchor]);
};

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        title: {
            display: "flex",
            flexGrow: 1,
        },
    })
);

function ensureReactNode(value: string | React.ReactNode): React.ReactNode {
    return typeof value === "string" ? <span>{value}</span> : value;
}

const HeaderPageTitle: React.FC = () => {
    const classes = useStyles();
    const { pageTitle } = usePageTitleContext();
    const { pageSubTitle } = usePageSubTitleContext();
    const { shortSiteName } = useAppCustomizations();
    return useMemo(
        () => (
            <div className={classes.title}>
                <Typography variant="h5">{ensureReactNode(shortSiteName)}</Typography>
                {pageTitle && <Typography variant="h5">&nbsp;- {ensureReactNode(pageTitle)}</Typography>}
                {pageTitle && pageSubTitle && (
                    <Typography variant="h5">&nbsp;- {ensureReactNode(pageSubTitle)}</Typography>
                )}
            </div>
        ),
        [classes.title, pageSubTitle, pageTitle, shortSiteName]
    );
};

export const Header: React.FC = () => {
    const { headerLogo } = useAppCustomizations();
    const [mainMenuOpen, setMainMenuOpen] = useMainMenuOpen();
    const { isUserLoggedIn, account, logout, login, isLoginInProgress } = useAuth();
    const { enableSearch, fallbackAvatar, showConnectivityInHeader } = useUserSettings();

    const history = useHistory();
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [feedbackWindowAnchor, setFeedbackWindowAnchor] = React.useState<HTMLButtonElement | null>(null);
    const isAccountMenuOpen = Boolean(accountMenuAnchor);
    const isFeedbackWindowOpen = Boolean(feedbackWindowAnchor);

    const email = account?.userName || "";
    const { data: gravatarUrl } = useVerifiedGravatarUrlQuery({ email, fallbackAvatar });
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

    const accountBox = useMemo(() => {
        const handleAvatarClick: MouseEventHandler<HTMLButtonElement> = (event) => {
            setAccountMenuAnchor(event.currentTarget);
        };

        const handleAccountMenuClose = (): void => {
            setAccountMenuAnchor(null);
        };

        const handleProfileClick = (): void => {
            history.push(urlToProfilePage);
            setAccountMenuAnchor(null);
        };

        const handleLogoutClick: MouseEventHandler = () => {
            setAccountMenuAnchor(null);
            logout();
            history.push(urlToHomePage);
        };

        const avatar = gravatarUrl ? <Avatar src={gravatarUrl} /> : <AccountCircle />;

        return isUserLoggedIn ? (
            <>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleAvatarClick}
                    color="inherit"
                >
                    {avatar}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    open={isAccountMenuOpen}
                    anchorEl={accountMenuAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleAccountMenuClose}
                >
                    <MenuItem onClick={handleProfileClick}>My profile</MenuItem>
                    <MenuItem onClick={handleLogoutClick}>Log out</MenuItem>
                </Menu>
            </>
        ) : (
            <>
                <Button
                    color="inherit"
                    onClick={() => {
                        setLoginButtonDisabled(true);
                        login();
                    }}
                    disabled={loginButtonDisabled || isLoginInProgress}
                >
                    Login
                </Button>
            </>
        );
    }, [
        gravatarUrl,
        isUserLoggedIn,
        isAccountMenuOpen,
        accountMenuAnchor,
        loginButtonDisabled,
        isLoginInProgress,
        history,
        logout,
        login,
    ]);

    const feedbackToggle = useMemo(() => {
        const handleFeedbackClick = (event: React.MouseEvent<HTMLButtonElement>) =>
            setFeedbackWindowAnchor(event.currentTarget);

        const handleFeedbackClose = () => setFeedbackWindowAnchor(null);

        return (
            <>
                <IconButton
                    aria-label="Send feedback to Twin Oak Solutions"
                    onClick={handleFeedbackClick}
                    color="inherit"
                >
                    <FeedbackIcon />
                </IconButton>

                <Popover
                    open={isFeedbackWindowOpen}
                    anchorEl={feedbackWindowAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={handleFeedbackClose}
                >
                    <UserFeedback onClose={handleFeedbackClose} />
                </Popover>
            </>
        );
    }, [setFeedbackWindowAnchor, isFeedbackWindowOpen, feedbackWindowAnchor]);

    return useMemo(() => {
        return (
            <AppHeader mainMenuOpen={mainMenuOpen}>
                <Toolbar>
                    <AppHeaderButton onClick={() => setMainMenuOpen(true)} mainMenuOpen={mainMenuOpen}>
                        <MenuIcon />
                    </AppHeaderButton>
                    <HeaderPageTitle />
                    {enableSearch && isUserLoggedIn && <Search />}
                    {showConnectivityInHeader && <ConnectivityContainer />}
                    {headerLogo}
                    {isUserLoggedIn && <Notifications />}
                    {isUserLoggedIn ? feedbackToggle : <></>}
                    {accountBox}
                </Toolbar>
            </AppHeader>
        );
    }, [
        accountBox,
        enableSearch,
        feedbackToggle,
        headerLogo,
        isUserLoggedIn,
        mainMenuOpen,
        setMainMenuOpen,
        showConnectivityInHeader,
    ]);
};
