import { Menu, MenuProps, withStyles } from "@material-ui/core";
import * as React from "react";

/** A styled version of the MUI <Menu> component that looks like a popup menu. */
export const StyledPopupMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));
