import merge from "lodash.merge";
import * as React from "react";
import { App } from "../App";
import { useSetAppCustomizations } from "./state";
import { defaultTwinOakAppProps } from "./defaultTwinOakAppProps";
import { TwinOakAppProps } from "./types";

/**
 * Install cleansed customizations into an atom.  Only return a TwinOakApp after the customizations are ready.
 */
export const TwinOakApp: React.FC<TwinOakAppProps> = (customizations) => {
    const setCustomizations = useSetAppCustomizations();
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        setCustomizations(merge({}, defaultTwinOakAppProps, customizations));
        setReady(true);
    }, [customizations, setCustomizations]);

    return React.useMemo(() => {
        return ready ? <App /> : null;
    }, [ready]);
};
