import BookmarkBorderFilledIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkBorderRoundedIcon from "@material-ui/icons/BookmarkBorderRounded";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import BookmarkBorderTwoToneIcon from "@material-ui/icons/BookmarkBorderTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const bookmarkBorderIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return BookmarkBorderFilledIcon;
        case IconStyle.Outlined:
            return BookmarkBorderOutlinedIcon;
        case IconStyle.Rounded:
            return BookmarkBorderRoundedIcon;
        case IconStyle.TwoTone:
            return BookmarkBorderTwoToneIcon;
        case IconStyle.Sharp:
            return BookmarkBorderSharpIcon;
    }
};

export const BookmarkBorderIcon = createIconComponent(bookmarkBorderIconSelector);
