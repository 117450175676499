import * as React from "react";
import { PropsWithChildren } from "react";
import { SecurityScheme, useAppConfig } from "../AppConfig";
import { AzureAdRequireAuth } from "./AzureAd/AzureAdRequireAuth";
import { OidcRequireAuth } from "./Oidc";
import { UnsecuredRequireAuth } from "./Unsecured";

// eslint-disable-next-line @typescript-eslint/ban-types
export const RequireAuth: React.FC<PropsWithChildren<{}>> = React.memo(({ children }) => {
    const { securityScheme } = useAppConfig();
    if (securityScheme === SecurityScheme.Unsecured) {
        return <UnsecuredRequireAuth>{children}</UnsecuredRequireAuth>;
    } else if (securityScheme === SecurityScheme.AzureAD) {
        return <AzureAdRequireAuth>{children}</AzureAdRequireAuth>;
    } else {
        return <OidcRequireAuth>{children}</OidcRequireAuth>;
    }
});
