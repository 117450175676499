import { useAtom } from "jotai";
import * as React from "react";
import { useEffect } from "react";
import { authAtom } from "../authAtom";
import { unsecuredAuth } from "./unsecuredAuth";

export const UnsecuredAuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [auth, setAuth] = useAtom(authAtom);
    useEffect(() => {
        setAuth(unsecuredAuth);
    }, [setAuth]);
    return auth ? <>{children}</> : null;
};
