import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import { useMemo } from "react";

export function useConfirmBool() {
    const confirm_ = useConfirm();
    return useMemo(() => {
        async function confirm(options?: ConfirmOptions) {
            try {
                await confirm_(options);
                return true;
            } catch {
                return false;
            }
        }
        return { confirm };
    }, [confirm_]);
}
