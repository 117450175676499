/** Extend a record type so that all properties are required. */
type NoUndefinedField<T> = {
    [P in keyof T]-?: Exclude<T[P], null | undefined>;
};

/**
 * Returns a copy of an object without any root level props set to undefined.  This approach is pure without
 * side-effects.
 */
export function omitUndefinedProps<T>(obj: T): T {
    if (obj === undefined || obj === null || typeof obj !== "object") {
        return obj;
    }
    return Object.entries(obj).reduce(
        (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
        {} as NoUndefinedField<T>
    );
}
