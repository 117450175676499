import { featureFlags } from "../featureFlags";
import { createLogger, noopLogger } from "../log";

const logMarkerTwinOak = "Auth";

/** Conditionally log an auth related debug message to the console. */
export const logAuth = featureFlags.logAuth ? createLogger(logMarkerTwinOak) : noopLogger;

export const logMessagePrefixMicrosoftAuth = "[Auth.MSAL]";
export const logMessagePrefixTwinOakAuth = `[${logMarkerTwinOak}]`;
