import assert from "assert";
import { atom } from "jotai";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { areWeTesting } from "../areWeTesting";
import { Auth } from "./Auth";
import { logAuth, logMessagePrefixTwinOakAuth } from "./logAuth";
import { unsecuredAuth } from "./Unsecured/unsecuredAuth";

const authPrimitiveAtom = atom<Auth | undefined>(undefined);

const authSetCountAtom = atom(0);

function logAuthChange(auth: Auth, changeCount: number) {
    const logArgs = [
        `Created Twin Oak auth object #${changeCount}.`,
        "👈 The object # resets on page load.",
        "This should happen infrequently, typically three times per page load when logged in on a secured environment.",
        "There may be more auth objects created during auth token renewal.",
        auth,
    ];
    if (changeCount < 4) {
        logAuth(...logArgs);
    } else if (changeCount < 5) {
        console.warn(...[logMessagePrefixTwinOakAuth, ...logArgs]);
    } else {
        console.error(...[logMessagePrefixTwinOakAuth, ...logArgs]);
    }
}

export const authAtom = atom<Auth | undefined, Auth>(
    (get) => get(authPrimitiveAtom),
    (_get, set, auth) => {
        set(authPrimitiveAtom, auth);
        set(authSetCountAtom, (prevCount) => {
            const count = prevCount + 1;
            logAuthChange(auth, count);
            return count;
        });
    }
);

export const useAuth = areWeTesting
    ? () => unsecuredAuth
    : () => {
          const auth = useAtomValue(authAtom);
          assert(!!auth, "useAuth() may be used only in the context of a <AuthProvider> component.");
          return auth;
      };

export function useSetAuth() {
    return useUpdateAtom(authAtom);
}
