import { useEffect, useState } from "react";
import { featureFlags } from "../featureFlags";
import { createLogger, noopLogger } from "../log";
import { ConnectivityStatus } from "./ConnectivityStatus";

const logUseConnectivityStatus = featureFlags.logConnectivity ? createLogger("useConnectivityStatus") : noopLogger;
const logConvertToConnectivityStatus = featureFlags.logConnectivity
    ? createLogger("convertToConnectivityStatus")
    : noopLogger;

const defaultConnectivityStatus: ConnectivityStatus = {
    effectiveType: "4g",
    downlink: 0,
};

function getConnectivityStatus() {
    return convertToConnectivityStatus(window.navigator.connection);
}

function convertToConnectivityStatus(connection: NetworkInformation | undefined) {
    logConvertToConnectivityStatus("NetworkInformation:", connection);
    if (connection === undefined) {
        return defaultConnectivityStatus;
    }
    const { effectiveType, downlink } = connection;
    return { effectiveType, downlink };
}

export function useConnectivityStatus(): ConnectivityStatus {
    const [connectivityStatus, setConnectivityStatus] = useState(getConnectivityStatus());

    useEffect(() => {
        function updateConnectivityStatus() {
            const connectivityStatus = getConnectivityStatus();
            logUseConnectivityStatus("[updateConnectivityStatus]", connectivityStatus);
            setConnectivityStatus(connectivityStatus);
        }

        window.navigator.connection?.addEventListener("change", updateConnectivityStatus);
        return () => {
            window.navigator.connection?.removeEventListener("change", updateConnectivityStatus);
        };
    }, []);

    logUseConnectivityStatus(connectivityStatus);
    return connectivityStatus;
}
