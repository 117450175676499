import { SnackbarProps } from "@material-ui/core";
import { Color } from "@material-ui/lab/Alert";
import { ReactNode } from "react";

export interface SnackState {
    severity: Color;
    children: ReactNode;
    snackbarProps?: SnackbarProps;
}

export function isSnack(x: SnackState | ReactNode): x is SnackState {
    return x !== undefined && x !== null && typeof x === "object" && "children" in x && "severity" in x;
}
