import { Typography } from "@material-ui/core";
import * as React from "react";
import { useAppCustomizations } from "../Extensibility/state";

export const AnonymousHome: React.FC = () => <AnonymousWelcome />;

const AnonymousWelcome: React.FC = () => {
    const { shortSiteName } = useAppCustomizations();
    return (
        <Typography variant="body1" gutterBottom>
            Welcome to {shortSiteName}.Click on the <Typography variant="button">Login</Typography> link above in the
            header to use the system.
        </Typography>
    );
};
