import { satisfies } from "compare-versions";
import ky from "ky";
import { useCallback, useMemo } from "react";
import { useAppConfig } from "../AppConfig";
import { areWeTesting } from "../areWeTesting";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { useTwinOakQuery, UseTwinOakQueryOptions } from "./useTwinOakQuery";

/**
 * Identifies the assemblies that consititute the Twin Oak Api.
 */
export enum TwinOakAssemblies {
    /** The Twin Oak web api assembly. */
    WebApi = "TwinOak.WebApi",
    /** The Twin Oak RPG runtime assembly */
    Runtime = "TwinOak.RPG.Runtime",
    /** The Twin Oak data access layer (DAL) assembly. */
    Dal = "TwinOak.DAL",
}

/** Type returned by the actual api. This will be converted into the simpler type TwinOakAssemblyVersions. */
export type WebApiAssemblyVersions = { productName: string; productVersion: string }[];

/**
 * Stores the server's actual assembly versions.  The keys correspond to assembly names like "TwinOak.WebApi" and the v
 * values are the semantic version of the named assembly.  For example: { "TwinOak.WebApi": "0.12.318",  ... }
 */
export type TwinOakAssemblyVersions = Record<TwinOakAssemblies, string>;

/** Simplify the api result down to just the data we want to keep. */
function convertApiResult(apiResult: WebApiAssemblyVersions) {
    return apiResult.reduce(
        (acc, next) => ({ ...acc, [next.productName]: next.productVersion }),
        {} as TwinOakAssemblyVersions
    );
}

const apiRelativeUrl = "assembly-versions";

/**
 * React hook that calls the api to retrieve assembly versions of the api server.  For example, we might learn the api
 * we are running against is using v0.12.921 of the TwinOak.WebApi assembly.
 */
export function useAssemblyVersions(queryOptions?: UseTwinOakQueryOptions<TwinOakAssemblyVersions>) {
    const { apiBaseUrl } = useAppConfig();
    return useTwinOakQuery(
        useMemo(() => {
            const apiFullUrl = areWeTesting ? apiRelativeUrl : new URL(apiRelativeUrl, apiBaseUrl).toString();
            return {
                queryKey: [apiRelativeUrl],
                queryFn: async () => convertApiResult(await ky(apiFullUrl).json()),
                ...queryOptionsDataChangesInfrequently,
                ...queryOptions,
            };
        }, [apiBaseUrl, queryOptions])
    );
}

export function useDoesApiVersionSatisfyImperative() {
    const { data: actualVersions } = useAssemblyVersions();
    return useCallback(
        function doesApiVersionSatisfy(assembly: TwinOakAssemblies, desiredVersionRange: string) {
            if (actualVersions === undefined) {
                // We don't actually know, but this will be temporary.
                return true;
            }
            const actualVersion = actualVersions[assembly as keyof TwinOakAssemblyVersions];
            return satisfies(actualVersion, desiredVersionRange);
        },
        [actualVersions]
    );
}

export function useDoesApiVersionSatisfy(assembly: TwinOakAssemblies, desiredVersionRange: string) {
    const doesApiVersionSatisfyImperative = useDoesApiVersionSatisfyImperative();
    return useMemo(() => {
        return doesApiVersionSatisfyImperative(assembly, desiredVersionRange);
    }, [assembly, desiredVersionRange, doesApiVersionSatisfyImperative]);
}

/**
 * Check if the server is running the right version of the software by comparing the actual twin oak assembly versions
 * with the given range.
 *
 * For details on how versions are compared, see https://github.com/omichelsen/compare-versions#version-ranges
 *
 * @param assembly which twin oak assembly to check the version of
 * @param desiredVersionRange the version range we want
 * @param reason optional message included with error messages
 * @example
 * // when actual version is not >= 0.12, emit error
 * useAssemblyVersionCheck(
 *     TwinOakAssemblies.WebApi,
 *     ">=0.12",
 *     "Version 0.12 introduced the whiz-bang endpoint."
 * );
 */
export function useAssemblyVersionCheck(assembly: TwinOakAssemblies, desiredVersionRange: string, reason?: string) {
    const { data: actualVersions } = useAssemblyVersions();
    return useMemo(
        function checkAssemblyVersionEffect() {
            if (actualVersions === undefined) {
                // We don't actually know, but this will be temporary.
                return true;
            }
            const actualVersion = actualVersions[assembly as keyof TwinOakAssemblyVersions];
            const result = satisfies(actualVersion, desiredVersionRange);
            if (!result) {
                console.error(
                    `One of the server components is not the version we need.  The spa depends on ${JSON.stringify(
                        assembly
                    )} assembly version ${JSON.stringify(
                        desiredVersionRange
                    )}, but the actual version is ${JSON.stringify(actualVersion)}.${
                        reason === undefined ? "" : `  ${reason}`
                    }`
                );
            }
            return result;
        },
        [actualVersions, assembly, desiredVersionRange, reason]
    );
}
