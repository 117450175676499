import FavoriteFilledIcon from "@material-ui/icons/Favorite";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import FavoriteSharpIcon from "@material-ui/icons/FavoriteSharp";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const favoriteIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return FavoriteFilledIcon;
        case IconStyle.Outlined:
            return FavoriteOutlinedIcon;
        case IconStyle.Rounded:
            return FavoriteRoundedIcon;
        case IconStyle.TwoTone:
            return FavoriteTwoToneIcon;
        case IconStyle.Sharp:
            return FavoriteSharpIcon;
    }
};

export const FavoriteIcon = createIconComponent(favoriteIconSelector);
