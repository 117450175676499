import * as React from "react";
import { useEffect, useMemo } from "react";
import { useDownloadPrintDocumentByJobIdMutation, usePrintDocument } from "../Api";
import { useStartPrintJobMutation } from "../Api";
import { Error } from "../Error";
import { usePageTitleContext } from "../PageTitleContext";
import { Report } from "../Report";

interface PrintJobContainerProps {
    printJobId: string;
    firstHighlightedLine: number | undefined;
    lastHighlightedLine: number | undefined;
}

export const PrintJobContainer: React.FC<PrintJobContainerProps> = ({
    printJobId,
    firstHighlightedLine,
    lastHighlightedLine,
}) => {
    const { setPageTitle } = usePageTitleContext();
    useEffect(() => {
        return setPageTitle(printJobId ? `Print Job - ${printJobId}` : "Reports");
    }, [setPageTitle, printJobId]);

    const { mutate: downloadPrintDocumentByJobId } = useDownloadPrintDocumentByJobIdMutation();
    const { mutate: startPrintJobMutation } = useStartPrintJobMutation();
    const { status, data, error } = usePrintDocument(printJobId);

    const language = useMemo(
        () =>
            printJobId?.endsWith(".json")
                ? "json"
                : printJobId?.endsWith(".js")
                ? "javascript"
                : printJobId?.endsWith(".css")
                ? "css"
                : printJobId?.endsWith(".html")
                ? "html"
                : "plaintext",
        [printJobId]
    );

    return useMemo(
        () =>
            status === "success" && data !== undefined ? (
                <Report
                    content={data}
                    language={language}
                    firstHighlightedLine={firstHighlightedLine}
                    lastHighlightedLine={lastHighlightedLine}
                    onDownload={() => {
                        return downloadPrintDocumentByJobId(printJobId);
                    }}
                    onPrint={() => {
                        return startPrintJobMutation(printJobId);
                    }}
                />
            ) : status === "error" && error ? (
                <>
                    <Error errorMessage={error.message} />
                </>
            ) : (
                <></>
            ),
        [
            status,
            data,
            language,
            firstHighlightedLine,
            lastHighlightedLine,
            error,
            downloadPrintDocumentByJobId,
            printJobId,
            startPrintJobMutation,
        ]
    );
};
