import { Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AppConfig } from "../../AppConfig";
import { logAuth, logMessagePrefixMicrosoftAuth } from "../logAuth";
import { featureFlags } from "../../featureFlags";

/**
 * Build a pca and cache it outside of react.  We need to guarantee only one is instantiated.
 */
export const getPublicClientApplication = (() => {
    let publicClientApplication: PublicClientApplication | undefined = undefined;
    return (appConfig: AppConfig) => {
        if (publicClientApplication === undefined) {
            const msalConfig = convertTwinOakAppConfigToMsalConfig(appConfig);
            logAuth("[getPublicClientApplication]", "appConfig", appConfig, "msalConfig", msalConfig);
            publicClientApplication = new PublicClientApplication(msalConfig);
        }
        return publicClientApplication;
    };
})();

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/authConfig.js
function convertTwinOakAppConfigToMsalConfig(appConfig: AppConfig) {
    const { authority, clientId } = appConfig;
    return {
        auth: {
            clientId,
            authority,
            redirectUri: "/authcode-redirect",
            postLogoutRedirectUri: "/",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: isIE || isEdge || isFirefox,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(logMessagePrefixMicrosoftAuth, message);
                            return;
                        case LogLevel.Info:
                            if (featureFlags.logAuth) {
                                console.info(logMessagePrefixMicrosoftAuth, message);
                            }
                            return;
                        case LogLevel.Verbose:
                            if (featureFlags.logAuth) {
                                console.debug(logMessagePrefixMicrosoftAuth, message);
                            }
                            return;
                        case LogLevel.Warning:
                            console.warn(logMessagePrefixMicrosoftAuth, message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    } as Configuration;
}
