import { useMemo } from "react";
import { QueryObserverResult, useMutation } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { downloadReponseToDisk } from "./downloadReponseToDisk";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";

function urlToPrintDocumentApi(printJobId: string) {
    return `print-jobs/${printJobId}/print-document`;
}

export function usePrintDocument(printJobId: string): QueryObserverResult<string, Error> {
    const ky = useKy();
    return useTwinOakQuery<string, Error>(
        useMemo(
            () => ({
                queryKey: ["print-document-by-job-id", printJobId],
                queryFn: () => ky(urlToPrintDocumentApi(printJobId)).text(),
                errorMessage: ErrorMessage.FailedToLoadPrintDocument,
                staleTime: 1 * 60 * 1000,
            }),
            [ky, printJobId]
        )
    );
}

export function useDownloadPrintDocumentByJobIdMutation() {
    const ky = useKy();
    const showError = useShowError();
    return useMutation<void, Error, string>(
        useMemo(
            () => ({
                mutationFn: async (printJobId: string) => {
                    const downloadUrl = urlToPrintDocumentApi(printJobId);
                    const response = await ky(downloadUrl, { searchParams: { asAttachment: true } });
                    await downloadReponseToDisk(response);
                },
                onError: (error) => {
                    showError(ErrorMessage.FailedToLoadPrintDocument, error);
                },
            }),
            [ky, showError]
        )
    );
}
