import { Button, ButtonProps, createStyles, Theme, withStyles } from "@material-ui/core";

export const TableToolbarButton = withStyles((_theme: Theme) =>
    createStyles({
        root: {
            whiteSpace: "nowrap",
            minWidth: "max-content",
        },
    })
)((props: ButtonProps) => (
    <div>
        <Button variant="contained" color="primary" {...props} />
    </div>
));
