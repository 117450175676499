import { useMemo } from "react";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { checklistQueryKey } from "../types";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";
import { useKy } from "./useKy";
import { WebApiChecklist } from "./WebApiTypes";

type SetChecklistCurrentStepMutationVariables = { checklistId: string; checklistStepId: string };

const urlToSetChecklistCurrentStepApi: (options: SetChecklistCurrentStepMutationVariables) => string = ({
    checklistId,
    checklistStepId,
}) => {
    return `checklists/${checklistId}/steps/${checklistStepId}/setcurrent`;
};

export function useSetChecklistCurrentStepMutation() {
    const ky = useKy();
    const showError = useShowError();
    const { setSnack } = useSetSnack();
    const queryCache = useQueryClient();

    return useMutation<WebApiChecklist, Response, SetChecklistCurrentStepMutationVariables>(
        useMemo(
            () => ({
                mutationFn: async function setChecklistCurrentStep(variables) {
                    const json = await ky.post(urlToSetChecklistCurrentStepApi(variables)).text();
                    return parseJsonWebApiChecklist(json);
                },
                onSuccess: function onSuccessSetChecklistCurrentStep(data, variables, _context) {
                    const queryKey: QueryKey = checklistQueryKey(variables.checklistId);
                    if (data) {
                        queryCache.setQueryData(queryKey, data);
                    } else {
                        queryCache.invalidateQueries(queryKey);
                    }
                    setSnack("Updated next step.");
                },
                onError: function onErrorUpdatePrinter(error, _variables, _context) {
                    showError(ErrorMessage.FailedToSetChecklistCurrentStep, error);
                },
            }),
            [ky, queryCache, setSnack, showError]
        )
    );
}
