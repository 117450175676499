import {
    Checkbox,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import * as React from "react";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { Link as RouterLink } from "react-router-dom";
import {
    CellProps,
    Column as RTColumn,
    Filters,
    HeaderProps,
    SortingRule,
    TableOptions,
    useFilters,
    UseFiltersInstanceProps,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import { useQueryString, useQueryStringKey } from "use-route-as-state";
import {
    OrderByDirection,
    PrinterSpecification,
    PrintJobAggregate,
    PrintJobListOrderBy,
    RpgProcedure,
    RpgProgram,
    useDeletePrintJobMutation,
    useDownloadPrintDocumentByJobIdMutation,
    usePrinters,
    usePrintJobAggregates,
    useStartPrintJobMutation,
    useUpdatePrintJobPrinterMutation,
    useUsers,
    WebApiUser,
} from "../Api";
import { PagedResult } from "../Api/PagedResult";
import { UserDisplayName } from "../Checklist/UserDisplayName";
import { featureFlags } from "../featureFlags";
import { formatDateTime } from "../formatDate";
import {
    CancelIcon,
    DeleteForeverIcon,
    EditIcon,
    MyFavoriteIconContainer,
    MyUnfavoriteIconContainer,
    PrintIcon,
    SaveAltIcon,
} from "../Icons";
import { omitUndefinedProps } from "../omitUndefinedProps";
import { usePageTitleContext } from "../PageTitleContext";
import { urlToPrintJobPage } from "../Routing";
import { useSetSnack } from "../SnackContext";
import { StyledPopupMenu } from "../StyledPopupMenu";
import {
    convertEntityIdsToRowIds,
    convertRowIdsToEntityIds,
    deselectAllRowsKey,
    tableStateReducer,
    TableToolbarButton,
    useTableToolbarStyles,
} from "../Table";
import { TablePaginationActions } from "../TablePaginationActions";
import { useConfirmBool } from "../useConfirmBool";
import { useQueryStringKeyArray } from "../useQueryStringKeyArray";
import { useQueryStringKeyInt } from "../useQueryStringKeyInt";
import { UserPicker } from "../UserPicker";
import { useChoosePrinter } from "./ChoosePrinter";
import { PrinterSpecificationLabel } from "./PrinterSpecificationLabel";

export const PrintJobListPage: React.FC = () => {
    const { setPageTitle } = usePageTitleContext();
    useEffect(() => setPageTitle("Print Jobs"), [setPageTitle]);
    return <PrintJobListContainer />;
};

type PrintJobListContainerProps = {};

function usePrintJobFilters(): [Filters<PrintJobAggregate>, (value: Filters<PrintJobAggregate>) => void] {
    const [creatorId, _setCreatorId] = useQueryStringKey("creator-id");
    const [_queryString, setQueryString] = useQueryString();
    const filters: Filters<PrintJobAggregate> = useMemo(() => {
        return !creatorId
            ? []
            : [
                  {
                      id: "printJobCreatorId",
                      value: creatorId,
                  },
              ];
    }, [creatorId]);
    const setFilters = useCallback(
        (value: Filters<PrintJobAggregate>) => {
            const creatorId = (value || [])?.find((filter) => filter.id === "printJobCreatorId")?.value;
            setQueryString((prevValue) =>
                omitUndefinedProps({
                    ...prevValue,
                    "creator-id": creatorId,
                    "page-index": "" + defaultQueryParams["page-index"],
                } as Paramified<PrintJobListPageQueryParams>)
            );
        },
        [setQueryString]
    );
    return [filters, setFilters];
}

function usePrintJobSortBy(): [SortingRule<PrintJobAggregate>[], (value: SortingRule<PrintJobAggregate>[]) => void] {
    const [sortBy, _setSortBy] = useQueryStringKey("sort-by", defaultQueryParams["sort-by"]);
    const [sortDir, _setSortDir] = useQueryStringKey("sort-dir", defaultQueryParams["sort-dir"]);
    const [_queryString, setQueryString] = useQueryString();
    const sortingRules: SortingRule<PrintJobAggregate>[] = useMemo(() => {
        return sortBy && typeof sortBy === "string"
            ? [
                  {
                      id: sortBy,
                      desc: sortDir === "desc",
                  },
              ]
            : [];
    }, [sortBy, sortDir]);
    const setSortingRules = useCallback(
        (value: SortingRule<PrintJobAggregate>[]) => {
            if (value && value.length === 1) {
                const sort = value[0];
                const sortBy = sort.id;
                const sortDir = sort.desc ? "desc" : undefined;
                setQueryString((prevValue) =>
                    omitUndefinedProps({
                        ...prevValue,
                        "sort-by": sortBy,
                        "sort-dir": sortDir,
                        "page-index": "" + defaultQueryParams["page-index"],
                    } as Paramified<PrintJobListPageQueryParams>)
                );
                return;
            }
            setQueryString((prevValue) =>
                omitUndefinedProps({
                    ...prevValue,
                    "sort-by": defaultQueryParams["sort-by"],
                    "sort-desc": defaultQueryParams["sort-dir"],
                    "page-index": "" + defaultQueryParams["page-index"],
                } as Paramified<PrintJobListPageQueryParams>)
            );
        },
        [setQueryString]
    );
    return [sortingRules, setSortingRules];
}

/** Extend a record type so that all properties are optional. */
type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

/**
 * Extend a type to be (mostly) compatible with `useQueryString`. Typical usage involves a subsequent call to
 * `removeUndefined`.
 */
type Paramified<T extends Object> = PartialRecord<keyof T, string | string[]>;

interface PrintJobListPageQueryParams {
    "sort-by"?: Extract<keyof PrintJobAggregate, string>;
    "sort-dir"?: "asc" | "desc";
    "page-index"?: number;
    "page-size"?: number;
    "selected-job-id"?: string[];
    "creator-id"?: string;
}

const defaultQueryParams: PrintJobListPageQueryParams = {
    "sort-by": "printJobName",
    "sort-dir": "asc",
    "page-index": 0,
    "page-size": 10,
    "selected-job-id": [],
    "creator-id": undefined,
};

// copied from use-route-as-state
type RouteObject = Record<string, string | string[]>;

function toCanonicalQueryString(queryString: RouteObject): RouteObject {
    return {
        ...(defaultQueryParams as RouteObject),
        ...queryString,
    };
}

// Selection state is stored in a strange way.  After a lot of experimentation, I could only get react-table to work
// the way I want is if the selection is maintained by react-table.  So the selection state is not a "controlled state".
// But we do still want to persist the selection in the url, so we bubble it back up to the container.  The
// selectedPrintJobIds prop passed into PrintJobList only takes affect in the initial page load.
const PrintJobListContainer: React.FC<PrintJobListContainerProps> = () => {
    const [queryString, setQueryString] = useQueryString();
    useEffect(
        function canonicalizeQueryString() {
            const newQueryString = toCanonicalQueryString(queryString);
            console.log("[canonicalizeQueryString]", { queryString, newQueryString });
            if (!isEqual(queryString, newQueryString)) {
                setQueryString(newQueryString);
            }
        },
        [queryString, setQueryString]
    );
    const [filters, setFilters] = usePrintJobFilters();
    const [sortBy, setSortBy] = usePrintJobSortBy();
    useEffect(() => {
        console.log("[PrintJobListContainer]", "sortBy", sortBy);
    }, [sortBy]);
    const [pageIndex, setPageIndex] = useQueryStringKeyInt("page-index");
    const [pageSize, setPageSize] = useQueryStringKeyInt("page-size", 10);

    const [selectedPrintJobIds, setSelectedPrintJobIds] = useQueryStringKeyArray("selected-job-id");

    const [favoritedPrintJobIds, setFavoritedPrintJobIds] = useQueryStringKeyArray("favorited-job-id", []);

    // Prime the local cache of printers.
    usePrinters();

    const { data: printJobAggregatesPage, isLoading } = usePrintJobAggregates(
        useMemo(() => {
            const firstSortBy = sortBy?.[0];
            const sortByColumnId = firstSortBy?.id;
            const orderBy = sortByColumnId
                ? tableColumns.find((c) => c.id === sortByColumnId)?.twinOakOrderBy
                : undefined;
            const orderDirection = firstSortBy?.desc ? OrderByDirection.Descending : OrderByDirection.Ascending;

            const creatorId = filters?.find((filter) => filter.id === "printJobCreatorId")?.value;

            return {
                pageNumber: pageIndex + 1,
                pageSize,
                orderBy,
                orderDirection,
                creatorId,
            };
        }, [filters, pageIndex, pageSize, sortBy])
    );

    // This exists in order to prevent a flash of empty table during sort/filter/page changes.
    const [lastFetchResult, setLastFetchResult] = useState<PagedResult<PrintJobAggregate>>();

    useEffect(() => {
        if (printJobAggregatesPage) {
            setLastFetchResult(printJobAggregatesPage);
        }
    }, [printJobAggregatesPage]);

    const { data: selfUsers } = useUsers("self");
    const currentUserId = selfUsers?.[0].id;

    const { mutate: downloadPrintDocumentByJobId } = useDownloadPrintDocumentByJobIdMutation();
    const { mutate: startPrintJob } = useStartPrintJobMutation();
    const { mutate: deletePrintJob } = useDeletePrintJobMutation();
    const { mutate: updatePrintJobPrinter } = useUpdatePrintJobPrinterMutation();

    const { confirm } = useConfirmBool();

    const { setSnack } = useSetSnack();

    return useMemo(() => {
        function handleDownloadPrintDocumentByJobId(printJobId: string) {
            downloadPrintDocumentByJobId(printJobId);
        }
        function handleStartPrintJob(printJobId: string) {
            startPrintJob(printJobId, {
                onSuccess: (_data) => {
                    setSnack(`Started print job ${printJobId}.`);
                },
            });
        }
        async function handleDeletePrintJob(printJobId: string) {
            const confirmed = await confirm({ description: "This action is permanent." });
            if (!confirmed) {
                return;
            }

            deletePrintJob(printJobId);
        }

        function handleChangePrinter(printJobId: string, printer: PrinterSpecification) {
            updatePrintJobPrinter({ id: printJobId, printerSpecification: printer });
        }

        if (!currentUserId) {
            return null;
        }

        const page = isLoading ? lastFetchResult : printJobAggregatesPage;
        return (
            <PrintJobList
                currentUserId={currentUserId}
                data={page?.data || []}
                onDownloadPrintDocumentByJobId={handleDownloadPrintDocumentByJobId}
                onStartPrintJob={handleStartPrintJob}
                onDeletePrintJob={handleDeletePrintJob}
                onChangePrinter={handleChangePrinter}
                onSortChanged={setSortBy}
                initialSortBy={sortBy}
                initialFilters={filters}
                onFiltersChanged={setFilters}
                initialSelectedPrintJobIds={selectedPrintJobIds}
                totalCount={page?.totalCount || 0}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                onChangeSelectedPrintJobIds={setSelectedPrintJobIds}
                favoritedPrintJobIds={favoritedPrintJobIds}
                onChangeFavoritedPrintJobIds={setFavoritedPrintJobIds}
            />
        );
    }, [
        currentUserId,
        isLoading,
        lastFetchResult,
        printJobAggregatesPage,
        setSortBy,
        sortBy,
        filters,
        setFilters,
        selectedPrintJobIds,
        pageIndex,
        pageSize,
        setPageIndex,
        setPageSize,
        setSelectedPrintJobIds,
        favoritedPrintJobIds,
        setFavoritedPrintJobIds,
        downloadPrintDocumentByJobId,
        startPrintJob,
        setSnack,
        confirm,
        deletePrintJob,
        updatePrintJobPrinter,
    ]);
};

interface PrintJobListActionProps {
    onDownloadPrintDocumentByJobId(printJobId: string): void;
    onStartPrintJob(printJobId: string): void;
    onChangePrinter(printJobId: string, printerSpecification: PrinterSpecification): void;
    onDeletePrintJob(printJobId: string): Promise<void>;
}

/**
 * Extra props that we pass into useTable via the options parameter.
 */
interface FavoriteProps {
    favoritedPrintJobIds: string[];
    onChangeFavoritedPrintJobIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface PrintJobListProps extends PrintJobListActionProps {
    currentUserId: string;
    data: PrintJobAggregate[];
    initialSelectedPrintJobIds: string[];
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    initialSortBy: SortingRule<PrintJobAggregate>[];
    initialFilters: Filters<PrintJobAggregate>;
    onChangePageIndex(pageIndex: number): void;
    onChangePageSize(pageSize: number): void;
    onChangeSelectedPrintJobIds: React.Dispatch<React.SetStateAction<string[]>>;
    onSortChanged(value: SortingRule<PrintJobAggregate>[] | undefined): void;
    onFiltersChanged(value: Filters<PrintJobAggregate> | undefined): void;
}

/** A table cell that renders buttons that operate on the current row. */
const ActionCell: React.FC<CellProps<PrintJobAggregate> & FavoriteProps> = ({
    row: {
        original: { printJobId },
    },
    onChangeFavoritedPrintJobIds,
    favoritedPrintJobIds,
    onDownloadPrintDocumentByJobId,
    onStartPrintJob,
    onDeletePrintJob,
    onChangePrinter,
}) => {
    const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
    const { choosePrinter } = useChoosePrinter();

    const isFavorite = favoritedPrintJobIds.includes(printJobId);

    return useMemo(() => {
        async function handleClickChoosePrinter() {
            const printer = await choosePrinter();
            onChangePrinter(printJobId, printer);
        }

        function handleToggleFavorite() {
            onChangeFavoritedPrintJobIds((prev) =>
                prev.includes(printJobId) ? prev.filter((id) => id !== printJobId) : [...prev, printJobId]
            );
        }

        return (
            <>
                <IconButton {...bindTrigger(popupState)}>
                    <MoreVertIcon />
                </IconButton>
                <StyledPopupMenu {...bindMenu(popupState)}>
                    <MenuItem
                        key="onDownloadPrintDocumentByJobId"
                        onClick={() => {
                            popupState.close();
                            onDownloadPrintDocumentByJobId(printJobId);
                        }}
                    >
                        <ListItemIcon>
                            <SaveAltIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Download" />
                    </MenuItem>
                    <MenuItem
                        key="onStartPrintJob"
                        onClick={() => {
                            popupState.close();
                            onStartPrintJob(printJobId);
                        }}
                    >
                        <ListItemIcon>
                            <PrintIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Print" />
                    </MenuItem>
                    <MenuItem
                        key="onChangePrinter"
                        onClick={() => {
                            popupState.close();
                            handleClickChoosePrinter();
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Change printer" />
                    </MenuItem>
                    {featureFlags.enableFavorites && !isFavorite && (
                        <MenuItem
                            onClick={() => {
                                popupState.close();
                                handleToggleFavorite();
                            }}
                        >
                            <ListItemIcon>
                                <MyUnfavoriteIconContainer fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Toggle favorite" />
                        </MenuItem>
                    )}
                    {featureFlags.enableFavorites && isFavorite && (
                        <MenuItem
                            onClick={() => {
                                popupState.close();
                                handleToggleFavorite();
                            }}
                        >
                            <ListItemIcon>
                                <MyFavoriteIconContainer fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Toggle favorite" />
                        </MenuItem>
                    )}
                    <MenuItem
                        key="onDeletePrintJob"
                        onClick={() => {
                            popupState.close();
                            onDeletePrintJob(printJobId);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteForeverIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </MenuItem>
                </StyledPopupMenu>
            </>
        );
    }, [
        choosePrinter,
        isFavorite,
        onChangeFavoritedPrintJobIds,
        onChangePrinter,
        onDeletePrintJob,
        onDownloadPrintDocumentByJobId,
        onStartPrintJob,
        popupState,
        printJobId,
    ]);
};

const PrintJobList: React.FC<PrintJobListProps & FavoriteProps> = ({
    data,
    onDownloadPrintDocumentByJobId,
    onStartPrintJob,
    onChangePrinter,
    onDeletePrintJob,
    favoritedPrintJobIds,
    onChangeFavoritedPrintJobIds,
    onFiltersChanged,
    onSortChanged,
    initialSortBy,
    initialFilters,
    totalCount,
    pageIndex,
    pageSize,
    initialSelectedPrintJobIds,
    onChangePageIndex,
    onChangePageSize,
    onChangeSelectedPrintJobIds,
}) => {
    const classes = useStyles();
    const toolbarClasses = useTableToolbarStyles();

    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
        toggleSortBy,
        state: { sortBy, filters, selectedRowIds },
        dispatch: dispatchTable,
    } = useTable<PrintJobAggregate>(
        useMemo(
            function buildReactTableOptions() {
                const selectedRowIds = convertEntityIdsToRowIds(initialSelectedPrintJobIds || []);

                const options: TableOptions<PrintJobAggregate> = {
                    columns: tableColumns,
                    data,
                    manualSortBy: true,
                    manualFilters: true,
                    manualPagination: true,
                    autoResetSelectedRows: false,

                    // This is necessary if you want a coherent selection that spans pages.  The default implementation
                    // just uses the ordinal position.
                    getRowId: (row) => row.printJobId,

                    stateReducer: tableStateReducer,
                    initialState: {
                        sortBy: initialSortBy,
                        filters: initialFilters,
                        selectedRowIds,
                    },
                    favoritedPrintJobIds,
                    onChangeFavoritedPrintJobIds,
                };
                return options;
            },
            [
                data,
                favoritedPrintJobIds,
                initialFilters,
                initialSelectedPrintJobIds,
                initialSortBy,
                onChangeFavoritedPrintJobIds,
            ]
        ),
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        useCallback(
            (hooks) => {
                hooks.visibleColumns.push(
                    (columns) =>
                        [
                            {
                                id: "selection",
                                Header: ({ getToggleAllPageRowsSelectedProps }) => {
                                    return <Checkbox {...getToggleAllPageRowsSelectedProps()} />;
                                },
                                Cell: ({ row }: CellProps<PrintJobAggregate>) => (
                                    <Checkbox {...row.getToggleRowSelectedProps()} className={classes.checkbox} />
                                ),
                                width: 40,
                                totalWidth: 39,
                                maxWidth: 41,
                            },
                            {
                                id: "actions",
                                Header: () => <></>,
                                Cell: (props: CellProps<PrintJobAggregate> & FavoriteProps) => (
                                    <ActionCell
                                        {...props}
                                        onDownloadPrintDocumentByJobId={onDownloadPrintDocumentByJobId}
                                        onStartPrintJob={onStartPrintJob}
                                        onChangePrinter={onChangePrinter}
                                        onDeletePrintJob={onDeletePrintJob}
                                    />
                                ),
                                width: 40,
                                totalWidth: 39,
                                maxWidth: 41,
                                className: classes.narrowColumn,
                            },
                            ...columns,
                        ] as PrintJobAggregateColumn[]
                );
            },
            [
                classes.narrowColumn,
                classes.checkbox,
                onDownloadPrintDocumentByJobId,
                onStartPrintJob,
                onChangePrinter,
                onDeletePrintJob,
            ]
        )
    );

    const deselectAllRows = useCallback(() => {
        dispatchTable({ type: deselectAllRowsKey });
    }, [dispatchTable]);

    const selectedPrintJobIds = useMemo(() => convertRowIdsToEntityIds(selectedRowIds), [selectedRowIds]);

    useEffect(
        function observeSortBy() {
            onSortChanged(sortBy);
        },
        [onSortChanged, sortBy]
    );

    useEffect(
        function observeFilters() {
            onFiltersChanged(filters);
        },
        [onFiltersChanged, filters]
    );

    useEffect(
        function observeSelection() {
            onChangeSelectedPrintJobIds((prevIds) =>
                isEqual(prevIds, selectedPrintJobIds) ? prevIds : selectedPrintJobIds
            );
        },
        [onChangeSelectedPrintJobIds, selectedPrintJobIds]
    );

    const { confirm } = useConfirmBool();

    const { mutate: downloadPrintDocument } = useDownloadPrintDocumentByJobIdMutation();
    const { mutate: startPrintJob } = useStartPrintJobMutation();
    const { mutate: deletePrintJob } = useDeletePrintJobMutation();

    return useMemo(() => {
        function handleChangePage(e: unknown, pageIndex: number) {
            onChangePageIndex(pageIndex);
        }
        function handleChangeRowsPerPage(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
            onChangePageSize(Number(e.target.value));
        }
        function handleClickClearSelection() {
            deselectAllRows();
        }
        function handleClickDownloadSelected() {
            if (selectedPrintJobIds.length === 0) {
                return;
            }
            selectedPrintJobIds.forEach((printJobId) => {
                downloadPrintDocument(printJobId);
            });
        }
        function handleClickPrintSelected() {
            if (selectedPrintJobIds.length === 0) {
                return;
            }
            selectedPrintJobIds.forEach((printJobId) => {
                startPrintJob(printJobId);
            });
        }
        async function handleClickDeleteSelected() {
            if (selectedPrintJobIds.length === 0) {
                return;
            }

            const confirmed = await confirm({ description: "This action is permanent." });
            if (!confirmed) {
                return;
            }

            selectedPrintJobIds.forEach((printJobId) => {
                deletePrintJob(printJobId);
            });
        }

        const numSelected = selectedPrintJobIds.length;
        const numSelectedSuffix = numSelected === 1 ? " (1 job)" : ` (${numSelected} jobs)`;

        // If the user puts in a non-standard page size, just go with it.
        const defaultRowsPerPageOptions = [5, 10, 20];
        const rowsPerPageOptions = defaultRowsPerPageOptions.includes(pageSize)
            ? defaultRowsPerPageOptions
            : [...defaultRowsPerPageOptions, pageSize];

        return (
            <div className={classes.root}>
                <TableContainer>
                    <Toolbar
                        className={clsx(toolbarClasses.root, {
                            [toolbarClasses.highlight]: numSelected > 0,
                        })}
                    >
                        {numSelected > 0 ? (
                            <>
                                <Typography className={toolbarClasses.title} color="inherit" variant="subtitle1">
                                    {numSelected} selected
                                </Typography>
                                <TableToolbarButton startIcon={<CancelIcon />} onClick={handleClickClearSelection}>
                                    Clear selection
                                </TableToolbarButton>
                                <TableToolbarButton startIcon={<SaveAltIcon />} onClick={handleClickDownloadSelected}>
                                    Download{numSelectedSuffix}
                                </TableToolbarButton>
                                <TableToolbarButton startIcon={<PrintIcon />} onClick={handleClickPrintSelected}>
                                    Print{numSelectedSuffix}
                                </TableToolbarButton>
                                <TableToolbarButton
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={handleClickDeleteSelected}
                                    className={toolbarClasses.delete}
                                >
                                    Delete{numSelectedSuffix}
                                </TableToolbarButton>
                            </>
                        ) : (
                            <Typography className={toolbarClasses.title} variant="h6" id="tableTitle">
                                Print Jobs
                            </Typography>
                        )}
                    </Toolbar>
                    <Table {...getTableProps()} className={classes.table}>
                        {page.length === 0 && (
                            <caption>
                                <Typography variant="body1" gutterBottom component="span">
                                    No print jobs found.
                                </Typography>
                            </caption>
                        )}
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <Fragment key={`HeaderRowsParent-${headerGroup.id}`}>
                                    <TableRow {...headerGroup.getHeaderGroupProps()} key="HeaderRow">
                                        {headerGroup.headers.map((column) => (
                                            <TableCell
                                                {...column.getHeaderProps()}
                                                className={(column as PrintJobAggregateColumn).className}
                                                key={`HeaderCell-${column.id}`}
                                            >
                                                {column.canSort ? (
                                                    <TableSortLabel
                                                        active={column.isSorted}
                                                        direction={column.isSortedDesc ? "desc" : "asc"}
                                                        onClick={() => {
                                                            const descending = column.isSorted
                                                                ? !column.isSortedDesc
                                                                : false;
                                                            toggleSortBy(column.id, descending);
                                                        }}
                                                        key={`HeaderCellSort-${column.id}`}
                                                    >
                                                        {column.render("Header")}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.render("Header")
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow {...headerGroup.getHeaderGroupProps()} key="FilterRow">
                                        {headerGroup.headers.map((column) => {
                                            return (
                                                <TableCell
                                                    {...column.getHeaderProps()}
                                                    className={(column as PrintJobAggregateColumn).className}
                                                    key={`FilterCell-${column.id}`}
                                                >
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </Fragment>
                            ))}
                        </TableHead>
                        <TableBody>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <TableRow {...row.getRowProps()} key={`DataRow-${row.original.printJobId}`}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    className={(cell.column as PrintJobAggregateColumn).className}
                                                    key={`DataCell-${row.original.printJobId}-${cell.column.id}`}
                                                >
                                                    {cell.render("Cell")}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalCount}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </div>
        );
    }, [
        selectedPrintJobIds,
        classes.root,
        classes.table,
        toolbarClasses.root,
        toolbarClasses.highlight,
        toolbarClasses.title,
        toolbarClasses.delete,
        getTableProps,
        page,
        headerGroups,
        totalCount,
        pageSize,
        pageIndex,
        onChangePageIndex,
        onChangePageSize,
        deselectAllRows,
        downloadPrintDocument,
        startPrintJob,
        confirm,
        deletePrintJob,
        toggleSortBy,
        prepareRow,
    ]);
};

const convertRpgThingToString = (rpgThing: undefined | RpgProcedure | RpgProgram): string =>
    rpgThing ? `${rpgThing.name}, ${rpgThing.library}` : "";

const OwnerFilter: React.FC<{ onOwnerChanged: (user: WebApiUser | null) => void; value: string }> = ({
    onOwnerChanged,
    value,
}) => {
    return useMemo(() => {
        function handleClick(user: WebApiUser | null) {
            onOwnerChanged(user);
        }
        return <UserPicker isFilter noUserDisplayName="Everyone" value={value} onClick={handleClick} />;
    }, [onOwnerChanged, value]);
};

// https://react-table.tanstack.com/docs/api/useFilters#column-options
// "Receives the table instance and column model as props"
type PrintJobAggregateColumn = RTColumn<PrintJobAggregate> & {
    twinOakOrderBy?: PrintJobListOrderBy;
    className?: string;
};

const PrintJobCreatorFilter: React.FC<HeaderProps<PrintJobAggregate> & UseFiltersInstanceProps<PrintJobAggregate>> = ({
    setFilter,
    column,
}) => {
    const { data: allUsers, isSuccess: areUsersLoaded } = useUsers();
    const { data: selfUsers } = useUsers("self");
    const selfUser = selfUsers?.[0];
    const selfUserId = selfUser?.id;

    console.log("[PrintJobCreatorFilter]", { allUsers, areUsersLoaded });

    // Example filters value:
    // [
    //     {
    //         "id": "printJobCreatorId",
    //         "value": "7c96bf13-db5a-4aa7-8c18-73ce6680b499"
    //     }
    // ]
    const [filters, _setFilters] = usePrintJobFilters();

    return useMemo(
        function renderPrintJobCreatorFilter() {
            if (!areUsersLoaded) {
                return null;
            }

            const creatorId = filters.filter((f) => f.id === "printJobCreatorId").map((f) => f.value)?.[0];
            const creatorName = !creatorId
                ? "Everyone"
                : creatorId === selfUserId
                ? "Me"
                : (allUsers || []).filter((user) => user.id === creatorId).map((user) => user.name)?.[0];

            const handleOwnerChanged = (owner: WebApiUser | null): void => {
                setFilter(column.id, owner?.id);
            };

            console.log("[PrintJobCreatorFilter]", {
                creatorId,
                creatorName,
                filters,
                selfUser,
                allUsers,
            });

            return <OwnerFilter onOwnerChanged={handleOwnerChanged} value={creatorName} key="PrintJobCreatorFilter" />;
        },
        [areUsersLoaded, filters, selfUserId, allUsers, selfUser, setFilter, column.id]
    );
};

export const FavoriteCell: React.FC<CellProps<PrintJobAggregate> & FavoriteProps> = (props) => {
    const {
        row: {
            original: { printJobId },
        },
        favoritedPrintJobIds,
        onChangeFavoritedPrintJobIds,
    } = props;

    const isFavorite = favoritedPrintJobIds.includes(printJobId);

    return useMemo(() => {
        const icon = isFavorite ? (
            <MyFavoriteIconContainer fontSize="small" />
        ) : (
            <MyUnfavoriteIconContainer fontSize="small" />
        );

        function handleClick() {
            onChangeFavoritedPrintJobIds((prev) =>
                prev.includes(printJobId) ? prev.filter((id) => id !== printJobId) : [...prev, printJobId]
            );
        }

        return <IconButton onClick={handleClick}>{icon}</IconButton>;
    }, [isFavorite, onChangeFavoritedPrintJobIds, printJobId]);
};

const tableColumns: PrintJobAggregateColumn[] = (
    [
        ...(featureFlags.enableFavorites
            ? [
                  {
                      id: "favorite",
                      Header: "Favorite",
                      Cell: FavoriteCell,
                  },
              ]
            : []),
        {
            id: "printJobName",
            Header: "Job Name",
            twinOakOrderBy: PrintJobListOrderBy.Name,
            accessor: (row) => (
                <Link component={RouterLink} to={urlToPrintJobPage(row.printJobId)}>
                    {row.printJobName}
                </Link>
            ),
        },
        {
            id: "printJobCreatedDate",
            Header: "Created",
            twinOakOrderBy: PrintJobListOrderBy.CreatedDate,
            accessor: (row) => formatDateTime(row.printJobCreatedDate),
        },
        {
            id: "printJobCreatorId",
            Header: "Owner",
            twinOakOrderBy: PrintJobListOrderBy.CreatorName,
            accessor: (row) => <UserDisplayName userId={row.printJobCreatorId} />,
            Filter: PrintJobCreatorFilter,
        },
        {
            id: "printerSpecification",
            Header: "Printer",
            accessor: (row) => <PrinterSpecificationLabel printerSpecification={row.printerSpecification} />,
        },
        { id: "disposition", Header: "Disposition", accessor: "disposition" },
        { id: "status", Header: "Status", twinOakOrderBy: PrintJobListOrderBy.Status, accessor: "status" },
        { id: "statusDetails", Header: "Status details", accessor: "statusDetails" },
        { id: "numberOfCopies", Header: "Number of copies", accessor: "numberOfCopies" },
        {
            id: "printJobCreatedByProcedure",
            Header: "Procedure",
            twinOakOrderBy: PrintJobListOrderBy.CreatedByProcedureLibrary,
            accessor: (row) => convertRpgThingToString(row.printJobCreatedByProcedure),
        },
        {
            id: "printJobCreatedByProgram",
            Header: "Program",
            twinOakOrderBy: PrintJobListOrderBy.CreatedByProgramLibrary,
            accessor: (row) => convertRpgThingToString(row.printJobCreatedByProgram),
        },
        { id: "printDocumentDescriptorName", Header: "Document Name", accessor: "printDocumentDescriptorName" },
        { id: "format", Header: "Format", accessor: (row) => row.format.toUpperCase() },
    ] as PrintJobAggregateColumn[]
).map((c) => ({ ...c, disableSortBy: c.twinOakOrderBy === undefined, disableFilters: c.Filter === undefined }));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "fit-content",
    },
    table: {
        marginBottom: theme.spacing(2),
        width: "fit-content",
    },
    checkbox: {
        padding: 12,
    },
    narrowColumn: {
        padding: 0,
    },
}));
