/**
 * Convert a simple list of string ids into the `rowId` format used internally by react-table.  In the `rowId` format,
 * an object is used instead of an array.  Selected ids are indicated root object properties whose name is the id and
 * whose value is `true`.  For example ids `["a", "b", "c"]` would transform into `{"a": true, "b": true, "c": true}`.
 * @param entityIds list of ids to be converted to `rowId` format
 * @returns ids in `rowId` format
 */
export function convertEntityIdsToRowIds(ids: string[]) {
    return ids.reduce(
        (acc, id) => ({
            ...acc,
            [id]: true,
        }),
        {} as Record<string, boolean>
    );
}
