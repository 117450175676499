import { createStyles, Grid, makeStyles, Paper, Theme, ThemeOptions, Typography } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Variant } from "@material-ui/core/styles/createTypography";
import * as React from "react";
import { useAuth } from "./Auth";
import { TwinOakApp, TwinOakAppProps } from "./Extensibility";
import { useAppCustomizations } from "./Extensibility/state";
import { AnonymousHome } from "./Home/AnonymousHome";
import { Panel } from "./Home/Panel";
import { usePageTitleContext } from "./PageTitleContext";

/**
 * Customize the spa using props of <TwinOakApp>.
 */
export const PamsApp: React.FC = () => {
    return React.useMemo(() => {
        // Spa customizations are introduced here.  You can use hooks to dynamically load data in this component.
        const props: TwinOakAppProps = {
            shortSiteName: "PAMS",
            buildPalette,
            components: {
                Home: Home,
            },
        };

        return <TwinOakApp {...props} />;
    }, []);
};

const customPalette: PaletteOptions = {};

export const buildPalette = (themeOptions: ThemeOptions): ThemeOptions => {
    return {
        ...themeOptions,
        palette: {
            ...themeOptions.palette,
            ...customPalette,
        },
    };
};

const Home: React.FC = () => {
    const { setPageTitle } = usePageTitleContext();
    React.useEffect(() => setPageTitle(""), [setPageTitle]);

    const { isUserLoggedIn } = useAuth();
    return isUserLoggedIn ? <AuthenticatedHome /> : <AnonymousHome />;
};

const useAuthenticatedHomeStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    })
);

const AuthenticatedHome: React.FC = () => {
    const classes = useAuthenticatedHomeStyles();
    return (
        <>
            <AuthenticatedWelcome />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ReleaseNotes />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const AuthenticatedWelcome: React.FC = () => {
    const { account } = useAuth();
    const name = account?.name;
    const { shortSiteName } = useAppCustomizations();
    return (
        <>
            <Typography variant="body1" gutterBottom>
                Welcome to {shortSiteName}
                {name && `, ${name}`}.
            </Typography>
        </>
    );
};

const useNoteStyles = makeStyles((theme: Theme) =>
    createStyles({
        note: {
            marginBottom: theme.spacing(1),
        },
    })
);

const Note: React.FC<React.PropsWithChildren<{ title: string; titleVariant?: Variant }>> = ({
    title,
    titleVariant,
    children,
}) => {
    const classes = useNoteStyles();
    return (
        <div className={classes.note}>
            <Typography variant={titleVariant || "h6"} gutterBottom>
                {title}
            </Typography>
            {children}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    })
);

export const ReleaseNotes: React.FC = () => {
    const classes = useStyles();
    return (
        <Panel title="Release Notes" helpText="What are the most recent changes made to the PAMS software?">
            <Paper className={classes.paper}>
                <Typography variant="h5" gutterBottom>
                    February 16, 2021
                </Typography>
                <Note title="Bug Fixes">
                    <Typography variant="body2" gutterBottom>
                        DFU Bug Fixes
                    </Typography>
                    <ul>
                        <li>Alpha fields are saved as upper case</li>
                        <li>
                            Fix made for searching records in a file that has a composite key (for example, UT.CUST)
                        </li>
                    </ul>
                    <Typography variant="body2" gutterBottom>
                        Bug fix for loading Oakmont water consumptions.
                    </Typography>
                </Note>
                <Note title="Real Estate Library">
                    <Typography variant="body2" gutterBottom>
                        The Real Estate library has been translated and is ready to use! Here's what has been done:
                    </Typography>
                    <ul>
                        <li>299 programs have been translated (100%)</li>
                        <li>213 of 228 Procedures have been translated (93%)</li>
                        <li>97 data files have been converted (100%)</li>
                    </ul>
                    <Typography variant="body2" gutterBottom>
                        Remaining work for Real Estate includes DFU translation and user acceptance testing.
                    </Typography>
                </Note>
            </Paper>
        </Panel>
    );
};
