import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { featureFlags } from "../featureFlags";
import { createLogger, noopLogger } from "../log";
import { ConnectivityDetails } from "./ConnectivityDetails";
import { ConnectivityIndicator } from "./ConnectivityIndicator";
import { CustomConnectivityIndicator } from "./CustomConnectivityIndicator";
import { MaterialConnectivityIndicator } from "./MaterialConnectivityIndicator";
import { useConnectivityStatus } from "./useConnectivityStatus";

const log = featureFlags.logConnectivity ? createLogger("ConnectivityContainer") : noopLogger;

type ConnectivityIndicatorType = "material" | "custom-css" | "third-party";

type ConnectivityContainerProps = {
    showDetails?: boolean;
    indicatorType?: ConnectivityIndicatorType;
};

const defaultIndicatorType: ConnectivityIndicatorType = "material";

export const ConnectivityContainer: React.FC<ConnectivityContainerProps> = ({
    showDetails,
    indicatorType: originalIndicatorType,
}) => {
    const indicatorType = originalIndicatorType || defaultIndicatorType;
    const { effectiveType, downlink } = useConnectivityStatus();

    log("render", { effectiveType, downlink });

    const indicator =
        indicatorType === "material" ? (
            <MaterialConnectivityIndicator effectiveType={effectiveType} downlink={downlink} />
        ) : indicatorType === "custom-css" ? (
            <CustomConnectivityIndicator effectiveType={effectiveType} downlink={downlink} />
        ) : (
            <ConnectivityIndicator effectiveType={effectiveType} downlink={downlink} />
        );
    return (
        <>
            {indicator}
            {showDetails && (
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Network connection details
                    </Typography>
                    <ConnectivityDetails />
                </Box>
            )}
        </>
    );
};
