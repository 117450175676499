import * as React from "react";
import { QueryClient, QueryClientProvider as RealQueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { simpleVersion } from "../AppVersion";
import { featureFlags } from "../featureFlags";
import { sessionStorageKeys } from "../sessionStorageKeys";

const eightHoursInMilliseconds = 1000 * 60 * 60 * 24;
const maxAge = eightHoursInMilliseconds;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: !featureFlags.reactQueryDisableRefetchOnWindowFocus,
            cacheTime: maxAge,
        },
    },
});

export const QueryClientProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return <RealQueryClientProvider client={queryClient}>{children}</RealQueryClientProvider>;
};

// Experimental: Persist react query cache to session storage.
const sessionStoragePersistor = !featureFlags.enableReactQueryOfflineCache
    ? undefined
    : createWebStoragePersistor({
          storage: window.sessionStorage,
          key: sessionStorageKeys.reactQueryOfflineCache,
      });

if (featureFlags.enableReactQueryOfflineCache && sessionStoragePersistor) {
    persistQueryClient({
        queryClient,
        persistor: sessionStoragePersistor,
        maxAge,
        buster: simpleVersion,
    });
}

export function clearReactQueryCache() {
    sessionStoragePersistor?.removeClient();
}
