import TableChartFilledIcon from "@material-ui/icons/TableChart";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import TableChartRoundedIcon from "@material-ui/icons/TableChartRounded";
import TableChartSharpIcon from "@material-ui/icons/TableChartSharp";
import TableChartTwoToneIcon from "@material-ui/icons/TableChartTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const tableChartIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return TableChartFilledIcon;
        case IconStyle.Outlined:
            return TableChartOutlinedIcon;
        case IconStyle.Rounded:
            return TableChartRoundedIcon;
        case IconStyle.TwoTone:
            return TableChartTwoToneIcon;
        case IconStyle.Sharp:
            return TableChartSharpIcon;
    }
};

export const TableChartIcon = createIconComponent(tableChartIconSelector);
