import { makeStyles } from "@material-ui/core";
import SignalWifi1BarIcon from "@material-ui/icons/SignalWifi1Bar";
import SignalWifi2BarIcon from "@material-ui/icons/SignalWifi2Bar";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import * as React from "react";
import { ConnectivityStatus } from "./ConnectivityStatus";

function convertToInteger({ effectiveType, downlink }: ConnectivityStatus) {
    return typeof downlink === "number" && downlink <= 0
        ? 0
        : effectiveType === "slow-2g"
        ? 1
        : effectiveType === "2g"
        ? 2
        : effectiveType === "3g"
        ? 3
        : 4;
}

export const useStyles = makeStyles((theme) => ({
    warning: {
        color: theme.palette.warning.dark,
    },
    error: {
        color: theme.palette.error.dark,
    },
}));

/**
 * Indicate connectivity using MaterialUI icons.
 * @param props
 */
export const MaterialConnectivityIndicator: React.FC<ConnectivityStatus> = (props) => {
    const strength = convertToInteger(props);
    const classes = useStyles();

    return strength === 0 ? (
        <WifiOffIcon color="error" />
    ) : strength === 1 ? (
        <SignalWifi1BarIcon className={classes.warning} />
    ) : strength === 2 ? (
        <SignalWifi2BarIcon />
    ) : strength === 3 ? (
        <SignalWifi3BarIcon />
    ) : (
        <SignalWifi4BarIcon />
    );
};
