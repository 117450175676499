import queryString from "query-string";
import { useMemo } from "react";
import isEqual from "react-fast-compare";
import { QueryObserverResult, useQueryClient } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { omitUndefinedProps } from "../omitUndefinedProps";
import {
    ChecklistSummariesLogicalQuery,
    ChecklistSummariesQuery,
    checklistSummariesQueryKey,
    ChecklistSummariesQueryPage,
    ChecklistSummariesQuerySort,
    ChecklistSummariesSimpleQuery,
    ChecklistSummaryOrderBy,
    isChecklistSummariesLogicalQuery,
    QueryStringValues,
} from "../types";
import { csharpIntMaxValue } from "./csharpIntMaxValue";
import { PagedResult } from "./PagedResult";
import { parseJsonWebApiChecklistSummaries } from "./parseJsonWebApiChecklistSummaries";
import { parsePagedResultChecklistSummaries } from "./parsePagedResultChecklistSummaries";
import { TwinOakAssemblies, useAssemblyVersionCheck } from "./useAssemblyVersionCheck";
import { useKy } from "./useKy";
import { useTwinOakQuery, UseTwinOakQueryOptions } from "./useTwinOakQuery";
import { WebApiChecklistSummary } from "./WebApiTypes";

function parseOptionalBool(value: string | string[]) {
    if (typeof value === "boolean") {
        return value;
    }
    if (value === true.toString()) {
        return true;
    }
    if (value === false.toString()) {
        return false;
    }
    return undefined;
}

function parseOptionalInt(value: string | string[]) {
    if (typeof value === "string") {
        try {
            return parseInt(value);
        } catch (error) {
            console.error("Failed to parse int.  Value:", value);
        }
    }
    return undefined;
}

function parseOptionalDate(value: string | string[]) {
    if (typeof value === "string") {
        try {
            return new Date(value);
        } catch (error) {
            console.error("Failed to parse date.  Value:", value);
        }
    }
    return undefined;
}

function singleStringOrUndefined(value: string | string[]) {
    return typeof value === "string" ? value : undefined;
}

/**
 * Convert a generic "query string" object into one of our query objects.
 */
export function convertQueryStringValuesToChecklistSummariesQuery(input: QueryStringValues) {
    if (input?.filterType === undefined) {
        console.warn(
            "convertQueryStringValuesToChecklistSummariesQuery() recieved a query string with no type specified. Defaulting to simple query."
        );
    }
    switch (input?.filterType) {
        default:
        case "simple": {
            const orderBy: ChecklistSummaryOrderBy =
                (singleStringOrUndefined(input?.orderBy) as unknown as ChecklistSummaryOrderBy) ??
                ChecklistSummaryOrderBy.CreatedDateDesc;
            const output: ChecklistSummariesSimpleQuery = {
                filterType: "simple",
                isScheduled: parseOptionalBool(input?.isScheduled),
                isStarted: parseOptionalBool(input?.isStarted),
                isAssigned: parseOptionalBool(input?.isAssigned),
                isArchived: parseOptionalBool(input?.isArchived),
                isCompleted: parseOptionalBool(input?.isCompleted),
                isLate: parseOptionalBool(input?.isLate),
                minStartDate: parseOptionalDate(input?.minStartDate),
                maxStartDate: parseOptionalDate(input?.maxStartDate),
                minCompletedDate: parseOptionalDate(input?.minCompletedDate),
                maxCompletedDate: parseOptionalDate(input?.maxCompletedDate),
                assignedToUserId: singleStringOrUndefined(input?.assignedToUserId),
                orderBy,
                pageSize: parseOptionalInt(input?.pageSize) ?? 10,
                pageNumber: parseOptionalInt(input?.pageNumber) ?? 1,
            };
            console.log("convertQueryStringValuesToChecklistSummariesQuery()", { input, output });
            return omitUndefinedProps(output);
        }
        case "logical": {
            const orderBy: ChecklistSummaryOrderBy =
                (singleStringOrUndefined(input?.orderBy) as unknown as ChecklistSummaryOrderBy) ??
                ChecklistSummaryOrderBy.CreatedDateDesc;
            const expression = singleStringOrUndefined(input?.expression);
            const output: ChecklistSummariesLogicalQuery = {
                filterType: "logical",
                expression: expression === undefined ? undefined : JSON.parse(expression),
                orderBy,
                pageSize: parseOptionalInt(input?.pageSize) ?? 10,
                pageNumber: parseOptionalInt(input?.pageNumber) ?? 1,
            };
            console.log("convertQueryStringValuesToChecklistSummariesQuery()", { input, output });
            return output;
        }
    }
}

export function convertToQueryStringValues(input: Record<string, unknown>) {
    const result: QueryStringValues = Object.entries(input).reduce((acc, [key, originalValue]) => {
        let value: string | undefined;
        switch (typeof originalValue) {
            case "string":
                value = originalValue;
                break;
            case "boolean":
                value = originalValue.toString();
                break;
            case "number":
                value = originalValue.toString();
                break;
            case "object":
                value = originalValue?.toString();
                break;
        }
        return {
            ...acc,
            [key]: value,
        };
    }, {});
    return result;
}

export function convertChecklistSummariesQueryToQueryStringValues(input: ChecklistSummariesQuery) {
    return convertToQueryStringValues(
        (isChecklistSummariesLogicalQuery(input)
            ? { ...input, expression: JSON.stringify(input.expression) }
            : input) as unknown as Record<string, unknown>
    );
}

export const defaultChecklistSummariesQuery: ChecklistSummariesQuerySort & ChecklistSummariesQueryPage = {
    orderBy: ChecklistSummaryOrderBy.CreatedDateDesc,
    pageSize: 10,
    pageNumber: 1,
};

/** Ensure object has all required props set. */
export function cleanseChecklistSummariesQuery<T extends ChecklistSummariesQuerySort & ChecklistSummariesQueryPage>(
    value: Partial<T>
): T {
    const cleansed = { ...defaultChecklistSummariesQuery, ...value } as T;
    return isEqual(cleansed, value) ? (value as T) : cleansed;
}

function repackageParameters(x: ChecklistSummariesSimpleQuery): Record<string, any> {
    const { parameters, ...rest } = x;
    if (parameters === undefined) {
        return rest;
    }
    // Convert objects of this shape
    // { "parameters": { "firstParamName": "firstParamValue", "two": 2 } }
    // Into objects of this shape
    // { "parameters.firstParamName": "firstParamValue", "parameters.two": 2 } }
    return Object.entries(parameters).reduce((acc, [key, value]) => ({ ...rest, [`parameters.${key}`]: value }), rest);
}

function urlToSimpleChecklistSummariesApi(query: Partial<ChecklistSummariesSimpleQuery>) {
    const cleansedQuery = cleanseChecklistSummariesQuery(query);
    const actualQuery = repackageParameters(cleansedQuery);
    const qs = query ? queryString.stringify(actualQuery) : "";
    return `checklist-summaries/simple-query${qs ? `?${qs}` : ""}`;
}

type ChecklistSummariesUnpagedQueryOptions = UseTwinOakQueryOptions<WebApiChecklistSummary[]>;
type ChecklistSummariesQueryOptions = UseTwinOakQueryOptions<PagedResult<WebApiChecklistSummary>>;

/**
 * React hook that loads checklist summaries from the server.  Queries can be defined using a simple query object,
 * logical query object, or just a url.
 *
 * TODO: Migrate to query object only.  We shouldn't be passing urls to these hooks.  The actual api url is an
 * implementation detail that shouldn't be exposed to callers.
 *
 * @param query
 * @param queryOptions
 * @returns
 */
export function useChecklistSummaries(
    query: ChecklistSummariesQuery | string,
    queryOptions?: ChecklistSummariesQueryOptions
): QueryObserverResult<PagedResult<WebApiChecklistSummary>> {
    const ky = useKy();
    useAssemblyVersionCheck(
        TwinOakAssemblies.WebApi,
        ">=0.13",
        "Version 0.13 introduced checklist summary logical queries."
    );
    return useTwinOakQuery(
        useMemo(
            () => ({
                queryKey: checklistSummariesQueryKey(query),
                queryFn: async () => {
                    return parsePagedResultChecklistSummaries(
                        await (isChecklistSummariesLogicalQuery(query)
                            ? ky
                                  .post("checklist-summaries/logical-query", {
                                      json: {
                                          expression: query.expression ?? null,
                                          pageSize: query.pageSize,
                                          pageNumber: query.pageNumber,
                                          orderBy: query.orderBy,
                                          ...(query.includeChecklistParameters
                                              ? { includeChecklistParameters: true }
                                              : undefined),
                                      },
                                  })
                                  .text()
                            : ky(typeof query === "string" ? query : urlToSimpleChecklistSummariesApi(query)).text())
                    );
                },
                errorMessage: ErrorMessage.FailedToLoadChecklistSummaries,
                ...queryOptions,
            }),
            [query, ky, queryOptions]
        )
    );
}

export function useChecklistSummariesLegacy(
    apiUrl: string,
    queryOptions?: ChecklistSummariesUnpagedQueryOptions
): QueryObserverResult<WebApiChecklistSummary[]> {
    const ky = useKy();
    return useTwinOakQuery(
        useMemo(
            () => ({
                queryKey: checklistSummariesQueryKey(apiUrl),
                queryFn: async () => {
                    return parseJsonWebApiChecklistSummaries(await ky(apiUrl).text());
                },
                errorMessage: ErrorMessage.FailedToLoadChecklistSummaries,
                ...queryOptions,
            }),
            [apiUrl, ky, queryOptions]
        )
    );
}

function ensureApiUrlHasUnpagedOptions(apiUrl: string) {
    const parsed = queryString.parseUrl(apiUrl);
    return queryString.stringifyUrl({
        ...parsed,
        query: { ...parsed.query, pageNumber: 1, pageSize: csharpIntMaxValue },
    });
}

function ensureQueryHasUnpagedOptions(query: ChecklistSummariesQuery): ChecklistSummariesQuery {
    return { ...query, pageNumber: 1, pageSize: csharpIntMaxValue };
}

export function useChecklistSummariesUnpaged(
    query: ChecklistSummariesQuery | string,
    queryOptions?: ChecklistSummariesQueryOptions
) {
    const { data, ...rest } = useChecklistSummaries(
        typeof query === "string" ? ensureApiUrlHasUnpagedOptions(query) : ensureQueryHasUnpagedOptions(query),
        queryOptions
    );
    return {
        data: data?.data || [],
        ...rest,
    };
}

/** @deprecated Use useMyActiveChecklistSummaries instead. */
export function useMyActiveChecklistSummariesUnpaged(queryOptions?: ChecklistSummariesQueryOptions) {
    return useChecklistSummariesUnpaged("checklist-summaries/mylist?filter=notCompleted", queryOptions);
}

/** @deprecated Use useChecklistSummariesImperative instead. */
export function useChecklistSummariesUnpagedImperative() {
    const ky = useKy();
    const queryCache = useQueryClient();
    return useMemo(() => {
        function queryChecklistSummaries(query: Partial<ChecklistSummariesSimpleQuery> | string) {
            const queryActual = typeof query === "object" ? cleanseChecklistSummariesQuery(query) : query;
            return queryCache.fetchQuery(checklistSummariesQueryKey(queryActual), async () => {
                return parsePagedResultChecklistSummaries(
                    await ky(typeof query === "string" ? query : urlToSimpleChecklistSummariesApi(query)).text()
                );
            });
        }
        return { queryChecklistSummaries };
    }, [ky, queryCache]);
}
