import {
    Avatar,
    Dialog,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import * as React from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { ByIdPrinterSpecification, PrinterSpecification, usePrinters } from "../Api";
import { CancelIcon, PrintIcon } from "../Icons";

const context = createContext<{
    choosePrinter(): Promise<PrinterSpecification>;
}>({ choosePrinter: async () => ({ _type: "id", printerId: "" }) });

export function useChoosePrinter() {
    return useContext(context);
}

const ChoosePrinterDialog: React.FC<{
    onClose(printerId?: string): void;
    open: boolean;
}> = ({ onClose, open }) => {
    const { data: printers } = usePrinters(undefined, { enabled: open });
    return useMemo(
        () => (
            <Dialog onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Set printer</DialogTitle>
                <List>
                    {(printers || []).map((printer) => (
                        <ListItem button onClick={() => onClose(printer.id)} key={printer.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <PrintIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={printer.name} />
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem button onClick={() => onClose()} key="">
                        <ListItemIcon>
                            <CancelIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cancel" />
                    </ListItem>
                </List>
            </Dialog>
        ),
        [onClose, open, printers]
    );
};

function noop() {}

export const ChoosePrinterProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [resolveReject, setResolveReject] = useState<
        [(value: PrinterSpecification | PromiseLike<PrinterSpecification>) => void, (reason?: any) => void]
    >([noop, noop]);
    const [resolve, reject] = resolveReject;
    return useMemo(() => {
        function handleClose(printerId?: string) {
            setOpen(false);
            if (!printerId) {
                reject();
            } else {
                resolve({ printerId, _type: "id" } as ByIdPrinterSpecification);
            }
        }
        function choosePrinter() {
            return new Promise<PrinterSpecification>((resolve, reject) => {
                setOpen(true);
                setResolveReject([resolve, reject]);
            });
        }
        return (
            <context.Provider value={{ choosePrinter }}>
                {children}
                <ChoosePrinterDialog open={open} onClose={handleClose} />
            </context.Provider>
        );
    }, [children, open, reject, resolve]);
};
