import {
    Box,
    BoxProps,
    Button,
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    makeStyles,
    TextField,
    TextFieldProps,
    Tooltip,
    TooltipProps,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Autocomplete } from "@material-ui/lab";
import { Field, FieldProps, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as React from "react";
import { ReactNode, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
    Printer,
    PrintFormat,
    useAddPrinterMutation,
    useDeletePrinterMutation,
    useFontFamilyNames,
    usePaperSizeNamesByPrinterPhysicalName,
    usePaperSourceNamesByPrinterPhysicalName,
    usePrinter,
    usePrinterPhysicalNames,
    useUpdatePrinterMutation,
} from "../Api";
import { hasValue } from "../hasValue";
import { DeleteForeverIcon, HelpIcon, SaveIcon } from "../Icons";
import { usePageTitleContext } from "../PageTitleContext";
import { isAddPrinterSpecialId, PrinterPageParams, urlToPrinterListPage } from "../Routing";
import { useConfirmBool } from "../useConfirmBool";
import { getPrinterDisplayName } from "./getPrinterDisplayName";

export const PrinterPage: React.FC = () => {
    const { printerId } = useParams<PrinterPageParams>();
    return isAddPrinterSpecialId(printerId) ? (
        <NewPrinterContainer />
    ) : (
        <ExistingPrinterContainer printerId={printerId} />
    );
};

const PrinterNotFound: React.FC = () => <>Printer not found.</>;

const ExistingPrinterContainer: React.FC<{ printerId: string }> = ({ printerId }) => {
    const { confirm } = useConfirmBool();
    const { mutate: deletePrinter, isIdle: isIdleDeletePrinter } = useDeletePrinterMutation();
    const { data: printer, isLoading } = usePrinter(printerId, { enabled: isIdleDeletePrinter });
    const { mutate: updatePrinter } = useUpdatePrinterMutation();

    const history = useHistory();
    const handleSave = (updatedPrinter: Printer) => {
        updatePrinter(updatedPrinter, {
            onSuccess: () => {
                history.replace(urlToPrinterListPage);
            },
        });
    };

    const handleDelete = async (printer: Printer) => {
        if (!(await confirm({ description: "This action is permanent." }))) {
            return;
        }
        deletePrinter(printer, {
            onSuccess: () => {
                history.replace(urlToPrinterListPage);
            },
        });
    };

    return isLoading ? null : printer ? (
        <PrinterComponent defaultValue={printer} onSave={handleSave} onDelete={handleDelete} />
    ) : (
        <PrinterNotFound />
    );
};

const defaultNewPrinter: Printer = {
    id: "",
    name: "",
    physicalName: "",
    supportedFormats: [],
    printerSettings: {
        printerName: "",
    },
    font: {
        familyName: "",
        size: 14,
    },
    overwriteFile: false,
};

const usePrinterBoxStyles = makeStyles((_theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minWidth: 600,
    },
}));

/**
 * A pleasant looking box used to group content on the printer page.
 */
const PrinterBox: React.FC<{ title?: ReactNode; introductoryParagraphs?: ReactNode | ReactNode[] } & BoxProps> = ({
    title,
    introductoryParagraphs,
    children,
    ...boxProps
}) => {
    const classes = usePrinterBoxStyles();
    return useMemo(
        () => (
            <Box border={1} borderRadius={4} padding={3} mt={2} mb={2} className={classes.root} {...boxProps}>
                {title && (
                    <Typography variant="subtitle1" gutterBottom>
                        {title}
                    </Typography>
                )}
                {introductoryParagraphs &&
                    (Array.isArray(introductoryParagraphs) ? introductoryParagraphs : [introductoryParagraphs]).map(
                        (p, index) => (
                            <Typography variant="body1" gutterBottom key={index}>
                                {p}
                            </Typography>
                        )
                    )}
                {children}
            </Box>
        ),
        [boxProps, children, classes.root, introductoryParagraphs, title]
    );
};

const NewPrinterContainer: React.FC = () => {
    const { mutate: createPrinter } = useAddPrinterMutation();
    const history = useHistory();
    return useMemo(() => {
        const handleSave = (printer: Printer) => {
            createPrinter(printer, { onSuccess: () => history.replace(urlToPrinterListPage) });
        };
        return <PrinterComponent defaultValue={defaultNewPrinter} onSave={handleSave} />;
    }, [createPrinter, history]);
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minWidth: "min-content",
        maxWidth: 1000,
        gap: theme.spacing(2),
    },
    paperSizeRootContainer: {
        display: "flex",
        gap: theme.spacing(2),
    },
    paperSizeLeftColumnContainer: {
        display: "flex",
        flexDirection: "column",
        width: "20em",
        justifyContent: "center",
    },
    paperSizeStandardOrContainer: {
        display: "flex",
        alignItems: "flex-end",
        gap: 16,
    },
    paperSizeGrowContainer: {
        flexGrow: 1,
        alignItems: "flex-end",
    },
    paperSizeRightColumnContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        minWidth: "20em",
        width: 200,
    },
    printFormatCheckbox: {
        display: "block",
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        "&:hover": { backgroundColor: theme.palette.error.dark },
    },
    buttonBar: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-start",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    paperSizeContainer: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "fit-content",
        gap: theme.spacing(1),
    },
    marginContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "fit-content",
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    marginTopAndBottomRows: {
        display: "flex",
        gap: theme.spacing(3),
        justifyContent: "center",
    },
    marginMiddleRow: {
        display: "flex",
        gap: theme.spacing(3),
        justifyContent: "center",
    },
    marginField: {
        width: "8ch",
    },
}));

type SetValueProps = {
    setValue(value: string): void;
};

const AvailablePrintersTextField: React.FC<TextFieldProps & SetValueProps> = ({ setValue, ...textFieldProps }) => {
    return textFieldProps.disabled || textFieldProps.InputProps?.readOnly ? (
        <TextField {...textFieldProps} />
    ) : (
        <AvailablePrintersTextFieldWithAutoComplete {...textFieldProps} setValue={setValue} />
    );
};

const AvailablePrintersTextFieldWithAutoComplete: React.FC<TextFieldProps & SetValueProps> = ({
    setValue,
    ...textFieldProps
}) => {
    const { data } = usePrinterPhysicalNames();
    const value = typeof textFieldProps.value === "string" ? textFieldProps.value : "";
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            options={data || []}
            onChange={(_event, value) => setValue(value || "")}
            value={value}
            renderInput={(autocompleteProps) => <TextField {...textFieldProps} {...autocompleteProps} />}
        />
    );
};

const PaperSourcesTextField: React.VFC<TextFieldProps & SetValueProps & { printerPhysicalName: string }> = ({
    setValue,
    printerPhysicalName,
    ...textFieldProps
}) => {
    return textFieldProps.disabled || textFieldProps.InputProps?.readOnly ? (
        <TextField {...textFieldProps} />
    ) : (
        <PaperSourcesTextFieldWithAutoComplete
            {...textFieldProps}
            setValue={setValue}
            printerPhysicalName={printerPhysicalName}
        />
    );
};

const PaperSourcesTextFieldWithAutoComplete: React.FC<
    TextFieldProps & SetValueProps & { printerPhysicalName: string }
> = ({ setValue, printerPhysicalName, ...textFieldProps }) => {
    const { data } = usePaperSourceNamesByPrinterPhysicalName(printerPhysicalName, { enabled: !!printerPhysicalName });
    const value = typeof textFieldProps.value === "string" ? textFieldProps.value : "";
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            options={data || []}
            onChange={(_event, value) => setValue(value || "")}
            value={value}
            renderInput={(autocompleteProps) => <TextField {...textFieldProps} {...autocompleteProps} />}
        />
    );
};

const PaperSizesTextField: React.FC<TextFieldProps & SetValueProps & { printerPhysicalName: string }> = ({
    setValue,
    printerPhysicalName,
    ...textFieldProps
}) => {
    return textFieldProps.disabled || textFieldProps.InputProps?.readOnly ? (
        <TextField {...textFieldProps} />
    ) : (
        <PaperSizesTextFieldWithAutoComplete
            {...textFieldProps}
            setValue={setValue}
            printerPhysicalName={printerPhysicalName}
        />
    );
};

const PaperSizesTextFieldWithAutoComplete: React.FC<
    TextFieldProps & SetValueProps & { printerPhysicalName: string }
> = ({ setValue, printerPhysicalName, ...textFieldProps }) => {
    const { data } = usePaperSizeNamesByPrinterPhysicalName(printerPhysicalName, {
        enabled: !!printerPhysicalName,
    });
    const value = typeof textFieldProps.value === "string" ? textFieldProps.value : "";
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            options={data || []}
            onChange={(_event, value) => setValue(value || "")}
            value={value}
            renderInput={(autocompleteProps) => <TextField {...textFieldProps} {...autocompleteProps} />}
        />
    );
};

const FontFamilyNameTextField: React.FC<TextFieldProps & SetValueProps> = ({ setValue, ...textFieldProps }) => {
    return textFieldProps.disabled || textFieldProps.InputProps?.readOnly ? (
        <TextField {...textFieldProps} />
    ) : (
        <FontFamilyNameTextFieldWithAutoComplete {...textFieldProps} setValue={setValue} />
    );
};

const FontFamilyNameTextFieldWithAutoComplete: React.FC<TextFieldProps & SetValueProps> = ({
    setValue,
    ...textFieldProps
}) => {
    const { data } = useFontFamilyNames();
    const value = typeof textFieldProps.value === "string" ? textFieldProps.value : "";
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            options={data || []}
            onChange={(_event, value) => setValue(value || "")}
            value={value}
            renderInput={(autocompleteProps) => <TextField {...textFieldProps} {...autocompleteProps} />}
        />
    );
};

function toggleArrayMembership<T>(arr: T[], value: T): T[] {
    return arr.includes(value) ? arr.filter((e) => e !== value) : [...arr, value];
}

type PrinterComponentProps = {
    defaultValue: Printer;
    readOnly?: boolean;
    onSave(printer: Printer): void;
    onDelete?(printer: Printer): Promise<void>;
};

function marginFieldValidation() {
    return Yup.number().min(0).typeError(`Margin width must be a number.`).nullable();
}

const printerValidationSchema = Yup.object().shape({
    name: Yup.string().required("Twin Oak logical name is required."),
    font: Yup.object().shape({
        familyName: Yup.string().required("Font family name is required."),
        size: Yup.number().integer().min(0).required("Font size is required."),
    }),
    printerSettings: Yup.object().shape({
        marginTop: marginFieldValidation(),
        marginBottom: marginFieldValidation(),
        marginLeft: marginFieldValidation(),
        marginRight: marginFieldValidation(),
    }),
});

/**
 * Log formik errors to the console.  Why?  When formik validation errors are not all displayed, the form will not
 * submit and the user won't know why.  By placing this component inside a <Formik/> control, errors are recorded to
 * the console for a dev to find.
 */
const LogFormikErrors: React.VFC = () => {
    const { errors } = useFormikContext();
    useEffect(
        function logFormikErrors() {
            if (Object.keys(errors).length > 0) {
                console.warn("Formik validation errors: ", errors);
            }
        },
        [errors]
    );
    return null;
};

function tryParseFloat<T extends any>(value?: T): number | undefined {
    const parsed = typeof value === "number" ? value : typeof value === "string" ? parseFloat(value) : undefined;
    if (parsed === undefined || isNaN(parsed)) {
        return undefined;
    }
    return parsed;
}

function convertFormikValuesToPrinter({ printerSettings, ...printer }: Printer): Printer {
    return {
        ...printer,
        printerSettings: {
            ...printerSettings,
            marginTop: tryParseFloat(printerSettings?.marginTop),
            marginBottom: tryParseFloat(printerSettings?.marginBottom),
            marginLeft: tryParseFloat(printerSettings?.marginLeft),
            marginRight: tryParseFloat(printerSettings?.marginRight),
            paperSizeCustomWidth: tryParseFloat(printerSettings?.paperSizeCustomWidth),
            paperSizeCustomHeight: tryParseFloat(printerSettings?.paperSizeCustomHeight),
        },
    } as Printer;
}

const FormikTextField: React.FC<{ fieldName: string; textFieldClassName?: string } & TextFieldProps> = ({
    fieldName,
    textFieldClassName,
    ...textFieldProps
}) => {
    return (
        <div>
            <Field name={fieldName}>
                {({ field: { value, ...field }, meta: { error, touched } }: FieldProps) => {
                    // It's important that the value property never be undefined, because that puts the component into
                    // uncontrolled mode.
                    const actualValue = value === undefined || value === null ? "" : value.toString();
                    return (
                        <TextField
                            {...field}
                            value={actualValue}
                            error={Boolean(touched && error)}
                            helperText={touched && error}
                            className={textFieldClassName}
                            {...textFieldProps}
                        />
                    );
                }}
            </Field>
        </div>
    );
};

const useHelpTooltipStyles = makeStyles(() => ({
    tooltip: {
        maxWidth: "140ch",
    },
}));

const HelpTooltip: React.FC<Omit<TooltipProps, "children">> = ({ classes: classesProp, ...tooltipProps }) => {
    const helpTooltipClasses = useHelpTooltipStyles();
    return useMemo(() => {
        const tooltip = [helpTooltipClasses.tooltip, classesProp?.tooltip].filter(hasValue).join(" ") || undefined;
        const classes = {
            ...classesProp,
            tooltip,
        };
        return (
            <Tooltip interactive {...tooltipProps} classes={classes}>
                <span>
                    <HelpIcon />
                </span>
            </Tooltip>
        );
    }, [classesProp, helpTooltipClasses.tooltip, tooltipProps]);
};

const PrintToFileFilenameHelpTooltip: React.FC = () => (
    <HelpTooltip
        title={
            <>
                <Typography variant="body1" gutterBottom>
                    The &ldquo;filename&rdquo; option sets the path of the output file. There are several macro strings
                    that can be used to setup a dynamic path. For example, you could setup a printer that write files to
                    a directory named with the current user's username and also put the name of the program that created
                    it in the filename.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Filename examples:
                </Typography>
                <Typography variant="body1">
                    <ul>
                        <li>
                            <code>X:\%CreatedByUserName%\%CreatedByProcedureName%_%PrintJobName%.pdf</code>
                        </li>
                        <li>
                            <code>\\SomeFileServer\SomeShareName\%PrintJobName%.pdf</code>
                        </li>
                    </ul>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Variables
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <dl>
                        <dt>%PrintJobName%</dt>
                        <dd>Name of the print job.</dd>
                        <dt>%CreatedByProcedureLibrary%</dt>
                        <dd>Library of the procedure that created this.</dd>
                        <dt>%CreatedByProcedureName%</dt>
                        <dd>Name of the procedure that created this.</dd>
                        <dt>%CreatedByProgramLibrary%</dt>
                        <dd>Library of the program that created this.</dd>
                        <dt>%CreatedByProgramName%</dt>
                        <dd>Name of the program that created this.</dd>
                        <dt>%CreatedByUserId%</dt>
                        <dd>Userid of the user who created this.</dd>
                        <dt>%CreatedByUserName%</dt>
                        <dd>Username of the user who created this.</dd>
                        <dt>%CreatedByUserAzureADIdentifier%</dt>
                        <dd>Azure id of the user who created this.</dd>
                        <dt>%CreatedByUserEmailAddress%</dt>
                        <dd>Email address of the user who created this.</dd>
                        <dt>%CreatedByUserMyFilesDirectory%</dt>
                        <dd>Path to MyFiles directory of the user who created this.</dd>
                        <dt>%CreatedByUserLegacyUserName%</dt>
                        <dd>Legacy username of the user who created this.</dd>
                        <dt>%CreatedByUserWorkstationId%</dt>
                        <dd>Workstation id of the user who created this.</dd>
                    </dl>
                </Typography>
            </>
        }
    />
);

const PrintToFileOverwriteHelpTooltip: React.FC = () => (
    <HelpTooltip
        title={
            <Typography variant="body1" gutterBottom>
                The &ldquo;overwrite file&rdquo; option controls the system's behavior when printing to a file and the
                file already exists. If &ldquo;overwrite file&rdquo; is checked, the existing file will be overwritten.
                If unchecked, the new file will be renamed with a numeric suffix like{" "}
                <code>&ldquo;Report (2).pdf&rdquo;</code>.
            </Typography>
        }
    />
);

const PrinterComponent: React.FC<PrinterComponentProps> = (props) => {
    const { defaultValue, onSave, onDelete, readOnly } = props;
    const classes = useStyles();
    const { setPageTitle } = usePageTitleContext();

    useEffect(() => {
        setPageTitle(
            defaultValue.id ? `Printers - ${getPrinterDisplayName(defaultValue)}` : "Printers - Add new printer"
        );
    }, [defaultValue, setPageTitle]);

    return useMemo(() => {
        function handleDelete() {
            return defaultValue.id && onDelete?.(defaultValue);
        }

        const deleteButton = !!defaultValue.id && onDelete && (
            <Button
                startIcon={<DeleteForeverIcon />}
                className={classes.deleteButton}
                variant="contained"
                onClick={handleDelete}
            >
                Delete
            </Button>
        );

        const textFieldReadOnlyProps: TextFieldProps | undefined = readOnly
            ? { InputProps: { readOnly: true }, disabled: true }
            : undefined;

        const checkboxReadOnlyProps: CheckboxProps | undefined = readOnly ? { disabled: true } : undefined;

        console.log("<PrinterComponent>", { defaultValue });

        function handleSubmit(values: Printer, _formikHelpers: FormikHelpers<Printer>) {
            const printer = convertFormikValuesToPrinter(values);
            console.log("<PrinterComponent>", "handleSubmit()", { defaultValue, values, printer });
            return onSave(printer);
        }

        return (
            <Formik
                initialValues={defaultValue}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={printerValidationSchema}
            >
                <Form className={classes.root}>
                    <LogFormikErrors />
                    <FormikTextField
                        {...textFieldReadOnlyProps}
                        fieldName="name"
                        label="Twin Oak logical name"
                        fullWidth
                    />
                    <Field name="printerSettings.printerName">
                        {({ field, form: { setFieldValue }, meta: { error, touched } }: FieldProps) => (
                            <AvailablePrintersTextField
                                {...textFieldReadOnlyProps}
                                {...field}
                                label="Windows device or print queue"
                                setValue={(value) => setFieldValue("printerSettings.printerName", value)}
                                fullWidth
                                error={Boolean(touched && error)}
                                helperText={touched && error}
                            />
                        )}
                    </Field>
                    <Field name="supportedFormats">
                        {({ field: { value }, form: { setFieldValue } }: FieldProps<string[]>) => {
                            const supportsAscii = value.includes(PrintFormat.Ascii);
                            const supportsAsa = value.includes(PrintFormat.Asa);
                            const handleSupportsAsciiClick = () =>
                                setFieldValue("supportedFormats", toggleArrayMembership(value, PrintFormat.Ascii));
                            const handleSupportsAsaClick = () =>
                                setFieldValue("supportedFormats", toggleArrayMembership(value, PrintFormat.Asa));
                            return (
                                <PrinterBox title="Supported file formats">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...checkboxReadOnlyProps}
                                                checked={supportsAscii}
                                                onChange={handleSupportsAsciiClick}
                                                color="primary"
                                            />
                                        }
                                        label="ASCII"
                                        className={classes.printFormatCheckbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...checkboxReadOnlyProps}
                                                checked={supportsAsa}
                                                onChange={handleSupportsAsaClick}
                                                color="primary"
                                            />
                                        }
                                        label="ASA"
                                        className={classes.printFormatCheckbox}
                                    />
                                </PrinterBox>
                            );
                        }}
                    </Field>
                    <Field name="font.familyName">
                        {({ field, form: { setFieldValue }, meta: { error, touched } }: FieldProps) => (
                            <FontFamilyNameTextField
                                {...textFieldReadOnlyProps}
                                {...field}
                                label="Font family name"
                                setValue={(value) => setFieldValue("font.familyName", value)}
                                fullWidth
                                error={Boolean(touched && error)}
                                helperText={touched && error}
                            />
                        )}
                    </Field>
                    <FormikTextField {...textFieldReadOnlyProps} fieldName="font.size" label="Font size" fullWidth />
                    <Field name="printerSettings.landscape" type="checkbox">
                        {({ field }: FieldProps<boolean>) => (
                            <FormControlLabel
                                control={<Checkbox {...checkboxReadOnlyProps} {...field} />}
                                label={
                                    <Box display="flex" alignItems="center" gridGap={8}>
                                        Landscape
                                    </Box>
                                }
                            />
                        )}
                    </Field>
                    <Field name="printerSettings.paperSourceName">
                        {({ field, form: { setFieldValue, values }, meta: { error, touched } }: FieldProps) => (
                            <PaperSourcesTextField
                                {...textFieldReadOnlyProps}
                                {...field}
                                printerPhysicalName={values.printerSettings.printerName}
                                label="Paper source"
                                setValue={(value) => setFieldValue("printerSettings.paperSourceName", value)}
                                fullWidth
                                error={Boolean(touched && error)}
                                helperText={touched && error}
                            />
                        )}
                    </Field>
                    <PrinterBox
                        title="Paper size"
                        introductoryParagraphs={
                            <>
                                Paper size can be set two different ways. If you want use one of the printer's built-in
                                sizes, fill in the &ldquo;Standard paper size&rdquo; field below. If you want to use a
                                custom size, fill in the custom width and height fields below. If you set both the
                                standard paper size and the custom size, the system will use the standard paper size. If
                                you do not enter any paper size, we will use the printer's default.
                            </>
                        }
                    >
                        <div className={classes.paperSizeRootContainer}>
                            <div className={classes.paperSizeLeftColumnContainer}>
                                <div className={classes.paperSizeStandardOrContainer}>
                                    <div className={classes.paperSizeGrowContainer}>
                                        <Field name="printerSettings.paperSizeName">
                                            {({
                                                field,
                                                form: { setFieldValue, values },
                                                meta: { error, touched },
                                            }: FieldProps) => (
                                                <PaperSizesTextField
                                                    {...textFieldReadOnlyProps}
                                                    {...field}
                                                    printerPhysicalName={values.printerSettings.printerName}
                                                    label="Standard paper size"
                                                    setValue={(value) =>
                                                        setFieldValue("printerSettings.paperSizeName", value)
                                                    }
                                                    fullWidth
                                                    error={Boolean(touched && error)}
                                                    helperText={touched && error}
                                                    classes={{ root: classes.paperSizeGrowContainer }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div>
                                        <Typography variant="body1">&mdash; OR &mdash;</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.paperSizeRightColumnContainer}>
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.paperSizeCustomWidth"
                                    label="Custom paper width (inches)"
                                    fullWidth
                                />
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.paperSizeCustomHeight"
                                    label="Custom paper height (inches)"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </PrinterBox>
                    <PrinterBox
                        title="Margin widths"
                        introductoryParagraphs={
                            <>
                                Use the fields below to set margin widths. All entries are measured in inches. For
                                example, in order to print with a 3/4&rdquo; margin, enter <code>.75</code>. Margins
                                that are blank will use the default, which is typically 1&rdquo;.
                            </>
                        }
                    >
                        <div className={classes.marginContainer}>
                            <div className={classes.marginTopAndBottomRows}>
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.marginTop"
                                    label="Top"
                                    textFieldClassName={classes.marginField}
                                />
                            </div>
                            <div className={classes.marginMiddleRow}>
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.marginLeft"
                                    label="Left"
                                    textFieldClassName={classes.marginField}
                                />
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.marginRight"
                                    label="Right"
                                    textFieldClassName={classes.marginField}
                                />
                            </div>
                            <div className={classes.marginTopAndBottomRows}>
                                <FormikTextField
                                    {...textFieldReadOnlyProps}
                                    fieldName="printerSettings.marginBottom"
                                    label="Bottom"
                                    textFieldClassName={classes.marginField}
                                />
                            </div>
                        </div>
                    </PrinterBox>
                    <PrinterBox
                        title="Print-to-file settings"
                        introductoryParagraphs={
                            <>These settings are only used by &ldquo;printers&rdquo; that print to a file.</>
                        }
                    >
                        <Field name="printerSettings.printToFile" type="checkbox">
                            {({ field }: FieldProps<boolean>) => (
                                <FormControlLabel
                                    control={<Checkbox {...checkboxReadOnlyProps} {...field} />}
                                    label={
                                        <Box display="flex" alignItems="center" gridGap={8}>
                                            Print to file
                                        </Box>
                                    }
                                />
                            )}
                        </Field>

                        <Field name="printerSettings.overwriteFile">
                            {({ field }: FieldProps<boolean>) => (
                                <FormControlLabel
                                    control={<Checkbox {...checkboxReadOnlyProps} {...field} />}
                                    label={
                                        <Box display="flex" alignItems="center" gridGap={8}>
                                            Overwrite file <PrintToFileOverwriteHelpTooltip />
                                        </Box>
                                    }
                                />
                            )}
                        </Field>

                        <Field name="printerSettings.printFileName">
                            {({ field }: FieldProps) => (
                                <div style={{ display: "flex", gap: 8 }}>
                                    <TextField label="Filename" fullWidth {...textFieldReadOnlyProps} {...field} />
                                    <PrintToFileFilenameHelpTooltip />
                                </div>
                            )}
                        </Field>
                    </PrinterBox>
                    <div className={classes.buttonBar}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            component={RouterLink}
                            to={urlToPrinterListPage}
                        >
                            Back to printer list
                        </Button>
                        {!readOnly && (
                            <Button startIcon={<SaveIcon />} color="primary" variant="contained" type="submit">
                                Save
                            </Button>
                        )}
                        {!readOnly && deleteButton}
                    </div>
                </Form>
            </Formik>
        );
    }, [
        classes.buttonBar,
        classes.deleteButton,
        classes.marginContainer,
        classes.marginField,
        classes.marginMiddleRow,
        classes.marginTopAndBottomRows,
        classes.paperSizeGrowContainer,
        classes.paperSizeLeftColumnContainer,
        classes.paperSizeRightColumnContainer,
        classes.paperSizeRootContainer,
        classes.paperSizeStandardOrContainer,
        classes.printFormatCheckbox,
        classes.root,
        defaultValue,
        onDelete,
        onSave,
        readOnly,
    ]);
};
