import { useCallback } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { useSpinnerEffect } from "../SpinnerContext";
import { useAuthorizedFetch } from "./AuthorizedFetch";

export function useBlobFileList(): QueryObserverResult<string[]> {
    const { authorizedFetch } = useAuthorizedFetch();

    const getBlobList = useCallback(async () => {
        const response = await authorizedFetch("blobs");
        const containers = (await response.json()) as string[];
        return (
            await Promise.all(
                containers.map(async (c) => {
                    const response = await authorizedFetch(`blobs/${c}`);
                    const blobs = (await response.json()) as string[];
                    return blobs.map((b) => `${c}/${b}`);
                })
            )
        ).flat();
    }, [authorizedFetch]);

    const query = useQuery(["blob-list"], getBlobList, {
        staleTime: 1 * 60 * 1000,
    });

    useSpinnerEffect(query.isLoading, "blob-list");

    return query;
}
