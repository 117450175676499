import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { checklistQueryKey, checklistSummariesQueryKey } from "../types";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";
import { WebApiChecklist } from "./WebApiTypes";

function urlToChecklistsApi(checklistId: string) {
    return `checklists/${checklistId}`;
}

export function useChecklist(checklistId: string) {
    const ky = useKy();
    return useTwinOakQuery<WebApiChecklist, Error>(
        useMemo(
            () => ({
                queryKey: checklistQueryKey(checklistId),
                queryFn: async () => parseJsonWebApiChecklist(await ky(urlToChecklistsApi(checklistId)).text()),
                staleTime: 60 * 1000,
                errorMessage: ErrorMessage.FailedToLoadChecklist,
            }),
            [checklistId, ky]
        )
    );
}

function urlToArchiveChecklistApi(checklistId: string) {
    return `checklists/${checklistId}/archive`;
}

export function useArchiveChecklistMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    return useMutation({
        mutationFn: useCallback(
            function archiveChecklist(checklistId: string) {
                return ky.post(urlToArchiveChecklistApi(checklistId));
            },
            [ky]
        ),
        onSuccess: (_data, _mutationVariables) => {
            queryCache.invalidateQueries(checklistQueryKey());
            queryCache.invalidateQueries(checklistSummariesQueryKey());
        },
    });
}

function urlToUnarchiveChecklistApi(checklistId: string) {
    return `checklists/${checklistId}/reactivate`;
}

export function useUnarchiveChecklistMutation() {
    const queryCache = useQueryClient();
    const ky = useKy();
    return useMutation({
        mutationFn: useCallback(
            function unarchiveChecklist(checklistId: string) {
                return ky.post(urlToUnarchiveChecklistApi(checklistId));
            },
            [ky]
        ),
        onSuccess: (_data, _mutationVariables) => {
            queryCache.invalidateQueries(checklistQueryKey());
            queryCache.invalidateQueries(checklistSummariesQueryKey());
        },
    });
}
