import queryString from "query-string";
import * as React from "react";
import { QueryKey, useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useShowError } from "../ErrorContext";
import { ErrorMessage } from "../ErrorMessage";
import { useSetSnack } from "../SnackContext";
import { checklistQueryKey } from "../types";
import { useAuthorizedFetch } from "./AuthorizedFetch";
import { parseJsonWebApiChecklist } from "./parseJsonWebApiChecklist";
import { WebApiChecklist } from "./WebApiTypes";

interface Parameters {
    checklistId: string;
    checklistStepId: string;
    checklistStepName?: string;
    note: string;
}

export const useAddNoteToChecklistStepMutation = (): UseMutationResult<
    WebApiChecklist | undefined,
    unknown,
    Parameters,
    unknown
> => {
    const queryClient = useQueryClient();

    const mutation = useMutation(addNoteToChecklistStep, {
        onSuccess: (data) => {
            if (!data) {
                return;
            }
            const queryKey: QueryKey = checklistQueryKey(data.id);
            queryClient.setQueryData(queryKey, data);
        },
    });

    const { authorizedFetch } = useAuthorizedFetch();
    const showError = useShowError();
    const { setSnack } = useSetSnack();

    async function addNoteToChecklistStep({ checklistId, checklistStepId, checklistStepName, note }: Parameters) {
        const qs = queryString.stringify({ checklistId, stepId: checklistStepId, annotation: note });
        const apiUrl = `checklists/${checklistId}/steps/${checklistStepId}/annotations?${qs}`;
        const response = await authorizedFetch(apiUrl, {
            method: "POST",
        });
        if (response.status >= 200 && response.status < 300) {
            setSnack(
                checklistStepName ? <>Added note to step: &ldquo;{checklistStepName}&rdquo;.</> : "Added note to step."
            );
            const json = await response.text();
            try {
                return parseJsonWebApiChecklist(json);
            } catch (ex) {
                showError(ErrorMessage.FailedToParseChecklist, response);
            }
        } else {
            showError(ErrorMessage.FailedToStartChecklist, response);
        }
    }

    return mutation;
};
