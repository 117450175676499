export class TwinOakHttpError extends Error {
    public response?: Response;
    public input: RequestInfo;
    public init?: RequestInit;

    constructor(responseOrError: Response | Error, input: RequestInfo, init?: RequestInit) {
        let message = "";
        if (responseOrError instanceof Error) {
            message = responseOrError.toString();
        } else {
            const code = responseOrError?.status || responseOrError?.status === 0 ? responseOrError?.status : "";
            const title = responseOrError?.statusText || "";
            const status = `${code} ${title}`.trim();
            const reason = status ? `status code ${status}` : "an unknown error";
            message = `Request failed with ${reason}`;
        }

        super(message);

        this.name = "TwinOakHttpError";
        this.input = input;
        this.init = init;
        if (responseOrError instanceof Response) {
            this.response = responseOrError;
        }
    }

    // es2022 version:

    // constructor(responseOrError: Response | Error, input: RequestInfo, init?: RequestInit) {
    //     let message = "";
    //     let options: ErrorOptions | undefined;

    //     if (responseOrError instanceof Error) {
    //         message = responseOrError.message;
    //         options = { cause: responseOrError };
    //     } else {
    //         const code = responseOrError?.status || responseOrError?.status === 0 ? responseOrError?.status : "";
    //         const title = responseOrError?.statusText || "";
    //         const status = `${code} ${title}`.trim();
    //         const reason = status ? `status code ${status}` : "an unknown error";
    //         message = `Request failed with ${reason}`;
    //     }

    //     super(message, options);

    //     this.name = "TwinOakHttpError";
    //     this.input = input;
    //     this.init = init;
    //     this.response = responseOrError instanceof Response ? responseOrError : undefined;
    // }
}
