import { useCallback, useMemo } from "react";
import { QueryKey, QueryObserverResult, useQueries, useQueryClient, UseQueryOptions } from "react-query";
import { ErrorMessage } from "../ErrorMessage";
import { ScreenIdentifier } from "../Screen/ScreenDefinition";
import { queryOptionsDataChangesInfrequently } from "./queryOptionsDataChangesInfrequently";
import { useKy } from "./useKy";
import { useTwinOakQuery } from "./useTwinOakQuery";
import { cleanseWebApiScreen, WebApiScreen } from "./WebApiTypes";

/** Get api url for a screen. */
const apiUrl = ({ library, sourceMember, name }: ScreenIdentifier) => `screens/${library}/${sourceMember}/${name}`;

export const newScreensQueryKey = "screens";

/** Get query key for a screen. */
const queryKey = ({ library, sourceMember, name }: ScreenIdentifier) =>
    [newScreensQueryKey, library, sourceMember, name] as QueryKey;

function useFetchScreenDefinitionUncached() {
    const ky = useKy();
    return useCallback(
        async function (screenIdentifier: ScreenIdentifier, options?: RequestInit) {
            const screen = (await ky(apiUrl(screenIdentifier), options).json()) as WebApiScreen;
            return cleanseWebApiScreen(screen);
        },
        [ky]
    );
}

export function useScreenDefinition(screenIdentifier: ScreenIdentifier): QueryObserverResult<WebApiScreen> {
    const fetchScreenDefinitionUncached = useFetchScreenDefinitionUncached();
    return useTwinOakQuery(
        useMemo(
            () => ({
                queryKey: queryKey(screenIdentifier),
                queryFn: () => fetchScreenDefinitionUncached(screenIdentifier),
                errorMessage: ErrorMessage.FailedToLoadScreenDefinition,
                ...queryOptionsDataChangesInfrequently,
            }),
            [fetchScreenDefinitionUncached, screenIdentifier]
        )
    );
}

export function useScreenDefinitions(screenIdentifiers: ScreenIdentifier[]) {
    const fetchScreenDefinitionUncached = useFetchScreenDefinitionUncached();
    return useQueries(
        screenIdentifiers.map((screenIdentifier) => ({
            queryKey: queryKey(screenIdentifier),
            queryFn: () => fetchScreenDefinitionUncached(screenIdentifier),
            errorMessage: ErrorMessage.FailedToLoadScreenDefinition,
            ...queryOptionsDataChangesInfrequently,
        }))
    );
}

export function useFetchScreenDefinition(queryOptions?: UseQueryOptions<WebApiScreen>): {
    fetchNewWebApiScreen(screenIdentifier: ScreenIdentifier, options?: RequestInit): Promise<WebApiScreen>;
} {
    const fetchScreenDefinitionUncached = useFetchScreenDefinitionUncached();
    const queryCache = useQueryClient();
    return useMemo(() => {
        function fetchNewWebApiScreen(screenIdentifier: ScreenIdentifier, options?: RequestInit) {
            return queryCache.fetchQuery(
                queryKey(screenIdentifier),
                () => fetchScreenDefinitionUncached(screenIdentifier, options),
                {
                    ...queryOptionsDataChangesInfrequently,
                    ...queryOptions,
                }
            );
        }
        return { fetchNewWebApiScreen };
    }, [fetchScreenDefinitionUncached, queryCache, queryOptions]);
}
