import assert from "assert";
import { atom, useAtom } from "jotai";
import { atomWithStorage, createJSONStorage, useAtomValue } from "jotai/utils";
import ky from "ky";
import * as React from "react";
import { useMemo } from "react";
import isEqual from "react-fast-compare";
import { useQuery, UseQueryOptions } from "react-query";
import { queryOptionsDataChangesInfrequently } from "../Api/queryOptionsDataChangesInfrequently";
import { createLogger } from "../log";
import { sessionStorageKeys } from "../sessionStorageKeys";
import { useSpinnerEffect } from "../SpinnerContext";
import { AppConfig } from "./AppConfig";
import { AppConfigLoadError } from "./AppConfigLoadError";
import { areWeTesting } from "../areWeTesting";
import { SecurityScheme } from "./SecurityScheme";

export const log = createLogger("AppConfigProvider");

/**
 * Get the spa config url from <div id=root data-config-url="http://...">.  This the expected structure in an Azure
 * hosted site.
 */
function getUrlToConfigSpaApiFromHtml() {
    const root = document.getElementById("root");
    if (!root) {
        throw new Error("Could not find *[id='root']");
    }
    const { configUrl } = root.dataset;
    if (!configUrl) {
        throw new Error("Could not load configUrl from *[id='root']");
    }
    return configUrl;
}

/**
 * This value is embedded in the static spa JavaScript so that at deployment time, the CI/CD pipeline can replace it
 * with the actual fully qualified config/spa url for that specific environment.  In production environments, it is
 * replaced with the correct url.  In development environments, it is replaced with the url to the local api which is
 * always the same.
 *
 * We could have done this more simply by having a convention where the api url can be derived using a pure function
 * applied to some part of the spa's url.  That approach would probably prevent us from deploying the spa and api to
 * different domains.  That alternative approach would (probably) also let us avoid setting up cors.  We may end up
 * still needing CORS if we can't find a way to run the dev spa and dev api from the same origin (host + port).
 */
const urlPlaceholder = "a30a7270-a1f4-41a5-9eef-dc6d5325ad09";

/** Url to the config/spa endpoint development environments. */
const urlToConfigSpaApiForLocalDevelopment = "https://localhost:5001/config/spa";

/**
 * Get the url used to load app config.  On development environmnets, the url is hard coded.  In Azure hosted
 * environments, it is embedded in index.html.
 */
function getUrlToConfigSpaApi() {
    if (areWeTesting) {
        return urlToConfigSpaApiForLocalDevelopment;
    }
    const urlToConfigSpaApiFromHtml = getUrlToConfigSpaApiFromHtml();
    const isDeployedEnvironment = urlToConfigSpaApiFromHtml !== urlPlaceholder;
    if (isDeployedEnvironment) {
        return urlToConfigSpaApiFromHtml;
    }
    return urlToConfigSpaApiForLocalDevelopment;
}

const urlToConfigSpaApiDefault = getUrlToConfigSpaApi();
console.log("Twin Oak config endpoint:", urlToConfigSpaApiDefault);

const urlToConfigSpaApiAtom = atom(urlToConfigSpaApiDefault);

function useUrlToConfigSpaApi() {
    return useAtomValue(urlToConfigSpaApiAtom);
}

export const appConfigQueryKey = "config/spa";

/** App config used when we are running a unit test or storybook. */
const testingAppConfig: AppConfig = {
    securityScheme: SecurityScheme.Unsecured,
    apiBaseUrl: "",
    authority: "",
    clientId: "",
    scopes: [],
    applicationInsightsInstrumentationKey: "",
};

const appConfigAtom = atomWithStorage<AppConfig | undefined>(
    sessionStorageKeys.appConfig,
    undefined,
    createJSONStorage(() => sessionStorage)
);

export const useAppConfig = areWeTesting
    ? () => testingAppConfig
    : () => {
          const appConfig = useAtomValue(appConfigAtom);
          assert(!!appConfig, "useAppConfig() may be used only in the context of a <AppConfigProvider> component.`");
          return appConfig;
      };

/**
 * !!! This component faces some unique challenges. !!!
 *
 * This provider needs to be placed higher in the tree than anything that uses the app config.  This includes any of
 * the auth and api infrastructure that makes calling the apis relatively easy, including `useKy` and
 * `useTwinOakQuery`.  If the app config cannot be loaded, we can't do much of anything, so we just show the user the
 * "you're hosed" component.
 */
export const AppConfigProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    if (areWeTesting) {
        throw new Error("Don't use <AppConfigProvider> in unit tests or storybook stories.");
    }

    const [appConfig, setAppConfig] = useAtom(appConfigAtom);
    const urlToConfigSpaApi = useUrlToConfigSpaApi();

    const { failureCount } = useQuery<AppConfig>(
        useMemo(() => {
            // There is retry logic in both react-query and ky.  Let's use the one in react-query, because that will
            // surface errors to the user quicker.  If we have a cached appConfig, don't retry.  If we do *not* have a
            // cached config, let's retry a small number of times.
            // https://react-query.tanstack.com/guides/query-retries
            // https://github.com/sindresorhus/ky#retry
            // https://github.com/sindresorhus/ky/issues/225#issuecomment-606641384
            const reactQueryRetry = appConfig ? false : true;
            const kyRetry = 0;

            return {
                ...queryOptionsDataChangesInfrequently,
                retry: reactQueryRetry,
                queryKey: [appConfigQueryKey],
                queryFn: () => ky(urlToConfigSpaApi, { retry: kyRetry }).json(),
                onSuccess: (data: AppConfig) => {
                    if (!isEqual(data, appConfig)) {
                        setAppConfig(data);
                    }
                },
                onError: () => {
                    console.error(
                        `Failed to load app config from server.${
                            appConfig ? " Using cached config." : " No cached config available."
                        }`
                    );
                },
            } as UseQueryOptions<AppConfig>;
        }, [appConfig, setAppConfig, urlToConfigSpaApi])
    );

    useSpinnerEffect(failureCount === 0 && !appConfig, urlToConfigSpaApi);
    if (appConfig) {
        return <>{children}</>;
    } else if (failureCount > 0) {
        return <AppConfigLoadError urlToConfigSpaApi={urlToConfigSpaApi} configLoadFailureCount={failureCount} />;
    } else {
        return null;
    }
};
