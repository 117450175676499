/**
 * Convert a `rowId` object into a simple list of string ids.  For example `{"a": true, "b": false, "c": true}` would
 * transform into `["a", "c"]`.
 *
 * I've only ever seen true used as the value in selectedRowIds (eg: { id1: true, id2: true }), but in theory it could
 * return some set to false.
 *
 * https://react-table.tanstack.com/docs/api/useRowSelect#table-options
 *
 * @param rowIds row ids in the react-table `rowId` format.
 * @returns list of string ids
 */
export function convertRowIdsToEntityIds(rowIds: Record<string, unknown>) {
    return Object.entries(rowIds)
        .filter(([_key, value]) => typeof value === "boolean" && value)
        .map(([key, _value]) => key);
}
