import { parse as parseContentDisposition } from "content-disposition-header";

export async function downloadReponseToDisk(response: Response, filename?: string): Promise<void> {
    // https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename || getFilenameFromResponse());
    document.body.appendChild(link);
    link.click();

    function getFilenameFromResponse() {
        const contentDispositionHeader = response.headers.get("content-disposition") || "";
        const contentDisposition = parseContentDisposition(contentDispositionHeader);
        return contentDisposition.parameters.filename;
    }
}
