import { IconButton, makeStyles, useTheme } from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import * as React from "react";
import { useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon, FirstPageIcon, LastPageIcon } from "./Icons";

const useTablePaginationActionsStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

export const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
    count,
    onChangePage,
    page,
    rowsPerPage,
}) => {
    const classes = useTablePaginationActionsStyles();
    const theme = useTheme();

    return useMemo(() => {
        function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
            onChangePage(event, 0);
        }

        function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
            onChangePage(event, page - 1);
        }

        function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
            onChangePage(event, page + 1);
        }

        function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }

        return (
            <div className={classes.root}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }, [classes.root, count, onChangePage, page, rowsPerPage, theme.direction]);
};
