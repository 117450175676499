import { Badge, Collapse, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import HiddenWithLegacyType, { HiddenProps } from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText";
import { NetworkCheck } from "@material-ui/icons";
import clsx from "clsx";
import * as React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
    convertRefetchIntervalToQueryConfig,
    useChecklistSummariesLegacy,
    useChecklistSummariesUnpaged,
    useHubPages,
    useUserSettings,
} from "./Api";
import { AppDrawer, AppNav } from "./AppLayout";
import { useAuth } from "./Auth";
import { ThematicColor } from "./Checklist/ThematicColor";
import { useAppCustomizations } from "./Extensibility/state";
import { featureFlags } from "./featureFlags";
import {
    AppsIcon,
    DashboardIcon,
    DescriptionIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    FolderIcon,
    HelpIcon,
    HomeIcon,
    MenuBookIcon,
    MenuIcon,
    PrintIcon,
    TableChartIcon,
} from "./Icons";
import {
    urlToApiDashboardPage,
    urlToChecklistListPage,
    urlToConnectivityPage,
    urlToExplorerMainPage,
    urlToHelpPage,
    urlToHomePage,
    urlToHubPage,
    urlToMenuListPage,
    urlToMenuPage,
    urlToPrinterListPage,
    urlToPrintJobListPage,
    urlToScreenListPage,
} from "./Routing";
import { ChecklistSummariesQuery, MainMenuContainerProps, MainMenuElement, MainMenuItemProps, MenuTree } from "./types";
import { useMainMenuOpen } from "./useMainMenuOpen";
import { useMenuItemStyles } from "./useMenuItemStyles";
import { MainMenuLayout, WorkflowMainMenuLayout } from "./UserSettings";

const Hidden = HiddenWithLegacyType as React.FC<React.PropsWithChildren<HiddenProps>>;

export interface NavProps {
    menuTree?: MenuTree;
}

export const useStyles = makeStyles((theme) => ({
    [ThematicColor.Success]: {
        backgroundColor: theme.palette.success.dark,
    },
    [ThematicColor.Grey]: {
        backgroundColor: theme.palette.grey.A200,
    },
    [ThematicColor.Info]: {
        backgroundColor: theme.palette.info.dark,
    },
    [ThematicColor.Warning]: {
        backgroundColor: theme.palette.warning.dark,
    },
    [ThematicColor.Error]: {
        backgroundColor: theme.palette.error.dark,
    },
}));

const ChecklistSummaryCountBadge: React.FC<
    React.PropsWithChildren<{
        apiUrlOrQuery: string | ChecklistSummariesQuery;
        isLegacyApi?: boolean;
    }>
> = ({ apiUrlOrQuery, isLegacyApi, children }) => {
    const { hubPageRefetchIntervalInSeconds } = useUserSettings();

    const { data: dataNew } = useChecklistSummariesUnpaged(
        apiUrlOrQuery,
        useMemo(
            () => ({ ...convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds), enabled: !isLegacyApi }),
            [hubPageRefetchIntervalInSeconds, isLegacyApi]
        )
    );
    const { data: dataLegacy } = useChecklistSummariesLegacy(
        apiUrlOrQuery as string,
        useMemo(
            () => ({ ...convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds), enabled: isLegacyApi }),
            [hubPageRefetchIntervalInSeconds, isLegacyApi]
        )
    );
    var data = isLegacyApi ? dataLegacy : dataNew;

    // const queryConfig = useMemo(
    //     () => convertRefetchIntervalToQueryConfig(hubPageRefetchIntervalInSeconds),
    //     [hubPageRefetchIntervalInSeconds]
    // );
    // const { data } = useChecklistSummariesUnpaged(apiUrlOrQuery, queryConfig);
    // return useMemo(() => {}, []);
    const lateCount = data?.filter((summary) => summary.isLate).length;
    const allCount = data?.length;

    const color = lateCount ? ThematicColor.Error : ThematicColor.Info;
    const count = lateCount || allCount;
    const classes = useStyles();
    const className = classes[color];

    return count ? (
        <Badge badgeContent={count} color="error" classes={{ badge: className }}>
            {children}
        </Badge>
    ) : (
        <>{children}</>
    );
};

function isMainMenuContainer(x: MainMenuElement): x is MainMenuContainerProps {
    return x !== undefined && x !== null && typeof x === "object" && "menuItems" in x;
}

const MainMenuContainer: React.FC<MainMenuContainerProps> = ({
    onClick,
    primary,
    expanded,
    menuItems,
    icon,
    listItemTextProps,
    dividerBefore,
    dividerAfter,
}) => (
    <>
        {dividerBefore && <Divider />}
        <List>
            <ListItem button onClick={onClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} {...listItemTextProps} />
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={expanded} timeout="auto" unmountOnExit key={`hub`}>
                {menuItems.map((menuItem, i) => (
                    <MainMenuItem {...menuItem} key={i} />
                ))}
            </Collapse>
        </List>
        {dividerAfter && <Divider />}
    </>
);

const MainMenuItem: React.FC<MainMenuItemProps> = ({ icon, primary, to, dividerBefore, dividerAfter }) => (
    <>
        {dividerBefore && <Divider />}
        <ListItem button component={Link} to={to}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={primary} />
        </ListItem>
        {dividerAfter && <Divider />}
    </>
);

export type MainMenuProps = {
    menuElements: MainMenuElement[];
};

const MainMenu: React.FC<MainMenuProps> = ({ menuElements }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    return React.useMemo(() => {
        const handleClick = (panel: string) => (): void => {
            setExpanded(expanded === panel ? false : panel);
        };
        return (
            <>
                {menuElements.map((menuElement, i) => {
                    const key = `MainMenuElement-${i.toString()}`;
                    const isExpanded = expanded === key;
                    return isMainMenuContainer(menuElement) ? (
                        <MainMenuContainer
                            {...menuElement}
                            expanded={isExpanded}
                            onClick={handleClick(key)}
                            key={key}
                            dividerAfter
                        />
                    ) : (
                        <MainMenuItem {...menuElement} key={key} dividerAfter />
                    );
                })}
            </>
        );
    }, [expanded, menuElements]);
};

const libraryMenuContainerListItemTextProps: ListItemTextProps = {
    style: { textTransform: "uppercase" },
};

export const Nav: React.FC<NavProps> = ({ menuTree }) => {
    const {
        mainMenuLayout,
        workflowMainMenuLayout: hubMenuLayout,
        showApiDashboardInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
        showConnectivityInMenu,
    } = useUserSettings();
    const classes = useMenuItemStyles();

    const [mainMenuOpen, setMainMenuOpen] = useMainMenuOpen();
    const hubPages = useHubPages();

    const { buildMainMenuElements } = useAppCustomizations();
    const { isUserLoggedIn } = useAuth();

    const drawer = useMemo(() => {
        let menuElements: MainMenuElement[] = [];

        // Add menu elements that are visible to all users, even if they are not logged in.
        menuElements = [
            ...menuElements,
            {
                primary: "Home",
                icon: <HomeIcon />,
                to: urlToHomePage,
            },
        ];

        if (isUserLoggedIn && hubMenuLayout !== WorkflowMainMenuLayout.Hidden) {
            const checklistMenuItems = [
                {
                    primary: "All workflows",
                    icon: <TableChartIcon />,
                    to: urlToChecklistListPage,
                    dividerAfter: true,
                },
                ...hubPages.map(({ menuItemText, showBadgeWithCountFromUrlOrQuery, isLegacyApi, spaUrlParam }) => {
                    const icon = showBadgeWithCountFromUrlOrQuery ? (
                        <ChecklistSummaryCountBadge
                            apiUrlOrQuery={showBadgeWithCountFromUrlOrQuery}
                            isLegacyApi={isLegacyApi}
                        >
                            <AppsIcon />
                        </ChecklistSummaryCountBadge>
                    ) : (
                        <AppsIcon />
                    );
                    return {
                        primary: menuItemText,
                        icon,
                        to: urlToHubPage(spaUrlParam),
                    };
                }),
            ];

            switch (hubMenuLayout) {
                case WorkflowMainMenuLayout.Flat:
                    menuElements = [...menuElements, ...checklistMenuItems];
                    break;
                case WorkflowMainMenuLayout.Nested:
                    menuElements = [
                        ...menuElements,
                        {
                            primary: "Workflows",
                            icon: <MenuBookIcon />,
                            menuItems: checklistMenuItems,
                        },
                    ];
                    break;
            }
        }

        if (isUserLoggedIn && menuTree) {
            menuElements = [
                ...menuElements,
                ...Object.keys(menuTree)
                    .sort()
                    .map((library) => ({
                        primary: library,
                        icon: <MenuBookIcon />,
                        listItemTextProps: libraryMenuContainerListItemTextProps,
                        menuItems: Object.keys(menuTree[library]).map((menu) => {
                            const menuItem = menuTree[library][menu];
                            const className = clsx({
                                [classes.mainMenuItem]: true,
                                [classes.disabled]: !menuItem.isEnabled,
                                [classes.emphasized]: menuItem.isEmphasized,
                            });

                            return {
                                primary: <span className={className}>{menu}</span>,
                                icon: <MenuIcon />,
                                to: urlToMenuPage({ library, menu }),
                            };
                        }),
                    })),
            ];
        }

        if (isUserLoggedIn) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Print Jobs",
                    icon: <DescriptionIcon />,
                    to: urlToPrintJobListPage,
                },
            ];
        }

        if (isUserLoggedIn && featureFlags.showPrinterListInMainMenu) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Printers",
                    icon: <PrintIcon />,
                    to: urlToPrinterListPage,
                },
            ];
        }

        if (isUserLoggedIn) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Explorer",
                    icon: <FolderIcon />,
                    to: urlToExplorerMainPage,
                },
            ];
        }

        if (isUserLoggedIn && showMenuListInMenu) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Menu list",
                    icon: <AppsIcon />,
                    to: urlToMenuListPage,
                },
            ];
        }

        if (isUserLoggedIn && showScreenListInMenu) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Screen list",
                    icon: <AppsIcon />,
                    to: urlToScreenListPage,
                },
            ];
        }

        if (isUserLoggedIn && showApiDashboardInMenu) {
            menuElements = [
                ...menuElements,
                {
                    primary: "API Dashboard",
                    icon: <DashboardIcon />,
                    to: urlToApiDashboardPage,
                },
            ];
        }

        if (isUserLoggedIn && showConnectivityInMenu) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Test connectivity",
                    icon: <NetworkCheck />,
                    to: urlToConnectivityPage,
                },
            ];
        }

        if (isUserLoggedIn) {
            menuElements = [
                ...menuElements,
                {
                    primary: "Help",
                    icon: <HelpIcon />,
                    to: urlToHelpPage,
                },
            ];
        }

        menuElements = buildMainMenuElements(menuElements);

        const drawerContents = <MainMenu menuElements={menuElements} />;

        const responsiveDrawer = (
            <>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <AppDrawer
                        variant="temporary"
                        anchor="left"
                        open={mainMenuOpen}
                        onClose={() => setMainMenuOpen(false)}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawerContents}
                    </AppDrawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <AppDrawer variant="permanent" open>
                        {drawerContents}
                    </AppDrawer>
                </Hidden>
            </>
        );

        const persistentDrawer = (
            <AppDrawer open={mainMenuOpen} variant="persistent" anchor="left">
                {drawerContents}
            </AppDrawer>
        );
        return mainMenuLayout === MainMenuLayout.Responsive ? responsiveDrawer : persistentDrawer;
    }, [
        buildMainMenuElements,
        classes.disabled,
        classes.emphasized,
        classes.mainMenuItem,
        hubMenuLayout,
        hubPages,
        isUserLoggedIn,
        mainMenuLayout,
        mainMenuOpen,
        menuTree,
        setMainMenuOpen,
        showApiDashboardInMenu,
        showConnectivityInMenu,
        showMenuListInMenu,
        showScreenListInMenu,
    ]);

    return <AppNav>{drawer}</AppNav>;
};
