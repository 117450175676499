import { CSSProperties } from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { ConnectivityStatus } from "./ConnectivityStatus";

const wifiSignalWaveEnabledStyle: CSSProperties = {
    display: "inline-block",
    border: "10px solid transparent",
    borderTopColor: "currentColor",
    borderRadius: "50%",
    borderStyle: "solid",
    margin: "5px",
};
const wifiSignalWaveDisabledStyle: CSSProperties = { ...wifiSignalWaveEnabledStyle, borderTopColor: "#eee" };
const wifiSignalWaveErrorStyle: CSSProperties = {
    ...wifiSignalWaveEnabledStyle,
    borderTopColor: "#e00",
    animationName: "$fade",
};

export const WifiSignalWave: React.FC<React.PropsWithChildren<{ disabled?: boolean }>> = ({ children, disabled }) => (
    <div style={disabled ? wifiSignalWaveDisabledStyle : wifiSignalWaveEnabledStyle}>{children}</div>
);

export const WifiErrorSignalWave: React.FC<React.PropsWithChildren<{ disabled?: boolean }>> = ({ children }) => (
    <div style={wifiSignalWaveErrorStyle}>{children}</div>
);

function convertToNumberOfEnabledWaves({ effectiveType, downlink }: ConnectivityStatus) {
    return typeof downlink === "number" && downlink <= 0
        ? 0
        : effectiveType === "slow-2g" || effectiveType === "2g"
        ? 1
        : effectiveType === "3g"
        ? 2
        : 3;
}

/**
 * Indicate connectivity using custom css.
 * @param props
 */
export const CustomConnectivityIndicator: React.FC<ConnectivityStatus> = (props) => {
    const numberOfEnabledWaves = convertToNumberOfEnabledWaves(props);
    return numberOfEnabledWaves === 0 ? (
        <WifiErrorSignalWave>
            <WifiErrorSignalWave>
                <WifiErrorSignalWave></WifiErrorSignalWave>
            </WifiErrorSignalWave>
        </WifiErrorSignalWave>
    ) : numberOfEnabledWaves === 1 ? (
        <WifiSignalWave disabled>
            <WifiSignalWave disabled>
                <WifiSignalWave></WifiSignalWave>
            </WifiSignalWave>
        </WifiSignalWave>
    ) : numberOfEnabledWaves === 2 ? (
        <WifiSignalWave disabled>
            <WifiSignalWave>
                <WifiSignalWave></WifiSignalWave>
            </WifiSignalWave>
        </WifiSignalWave>
    ) : (
        <WifiSignalWave>
            <WifiSignalWave>
                <WifiSignalWave></WifiSignalWave>
            </WifiSignalWave>
        </WifiSignalWave>
    );
};
