import constate from "constate";
import { useState } from "react";

const usePageSubTitle = (): {
    pageSubTitle: string;
    setPageSubTitle(pageSubTitle: string): void;
    clearPageSubTitle: () => void;
} => {
    const [pageSubTitle, setPageSubTitle] = useState("");
    const clearPageSubTitle = (): void => {
        if (pageSubTitle) {
            setPageSubTitle("");
        }
    };
    return { pageSubTitle, setPageSubTitle, clearPageSubTitle };
};

const [PageSubTitleProviderWithLegacyType, usePageSubTitleContext] = constate(usePageSubTitle);
const PageSubTitleProvider = PageSubTitleProviderWithLegacyType as React.FC<React.PropsWithChildren<{}>>;

export { PageSubTitleProvider, usePageSubTitleContext };
