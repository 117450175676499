import {
    Button,
    IconButton,
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CellProps, Column as RTColumn, useTable } from "react-table";
import { Printer, PrintFormat, usePrinters } from "../Api";
import { AddIcon, EditIcon } from "../Icons";
import { usePageTitleContext } from "../PageTitleContext";
import { urlToAddPrinterPage, urlToPrinterPage } from "../Routing";

/** A page level component that deals with parsing the url and setting the page title. */
export const PrinterListPage: React.FC = () => {
    const { setPageTitle } = usePageTitleContext();
    useEffect(() => {
        setPageTitle("Printers");
    }, [setPageTitle]);
    return <PrinterListContainer />;
};

/** A behavioral component that loads the initial data. */
const PrinterListContainer: React.FC = () => {
    const { data, isLoading } = usePrinters();
    return isLoading ? null : <PrinterList data={data || []} />;
};

const usePrintFormatStyles = makeStyles((theme) => ({
    nonEmptyContainer: {
        display: "flex",
        gap: theme.spacing(2),
    },
}));

/** A table cell that renders an array of print formats. */
const PrintFormatsTableCell: React.FC<CellProps<Printer, PrintFormat[]>> = ({ value }) => {
    const classes = usePrintFormatStyles();
    return value.length ? (
        <div className={classes.nonEmptyContainer}>
            {value.map((v, i) => (
                <code key={i}>{v.toUpperCase()}</code>
            ))}
        </div>
    ) : (
        <code>No formats</code>
    );
};

/** A table cell that renders the cell value with a link to the edit printer page. */
const LinkToEditPrinterPageTableCell: React.FC<CellProps<Printer>> = ({
    value,
    row: {
        original: { id },
    },
}) => (
    <Link component={RouterLink} to={urlToPrinterPage(id)}>
        {value}
    </Link>
);

/** A table cell that renders buttons that operate on the current row. */
const ActionCell: React.FC<CellProps<Printer>> = ({
    row: {
        original: { id },
    },
}) => {
    return (
        <IconButton component={RouterLink} to={urlToPrinterPage(id)}>
            <EditIcon />
        </IconButton>
    );
};

const printerListColumns: RTColumn<Printer>[] = [
    {
        Header: "Name",
        accessor: "name",
        Cell: LinkToEditPrinterPageTableCell,
    },
    {
        Header: "Physical name",
        accessor: (row) => row.printerSettings?.printerName,
    },
    {
        Header: "Print filename",
        accessor: (row) => row.printerSettings?.printFileName,
    },
    {
        Header: "Print to file",
        accessor: (row) => row.printerSettings?.printToFile,
    },
    {
        Header: "Font family",
        accessor: (row) => row.font?.familyName,
    },
    {
        Header: "Font size",
        accessor: (row) => row.font?.size,
    },
    {
        Header: "Formats",
        accessor: "supportedFormats",
        Cell: PrintFormatsTableCell,
    },
    {
        Header: "Overwrite file",
        accessor: "overwriteFile",
    },
];

const useStyles = makeStyles((theme) => ({
    root: {},
    table: {
        marginBottom: theme.spacing(2),
        width: "fit-content",
    },
}));

/** A component that renders a list of printers in a table. */
export const PrinterList: React.FC<{ data: Printer[] }> = ({ data }) => {
    const classes = useStyles();
    const { getTableProps, headerGroups, rows, prepareRow } = useTable<Printer>(
        {
            columns: printerListColumns,
            data,
        },
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "actions",
                    Header: () => <></>,
                    Cell: ActionCell,
                },
                ...columns,
            ]);
        }
    );

    return (
        <div className={classes.root}>
            {rows.length === 0 ? (
                <Typography variant="body1" gutterBottom>
                    There are no printers setup. Click the <code>Add Printer</code> button below to set one up.
                </Typography>
            ) : (
                <Table {...getTableProps()} className={classes.table}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell {...column.getHeaderProps()}>{column.render("Header")}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()} key={row.original.id}>
                                    {row.cells.map((cell) => {
                                        return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={RouterLink}
                to={urlToAddPrinterPage}
            >
                Add printer
            </Button>
        </div>
    );
};
