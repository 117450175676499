import { Provider as JotaiProvider } from "jotai";
import { ConfirmProvider as ConfirmProviderWithLegacyType, ConfirmProviderProps } from "material-ui-confirm";
import * as React from "react";
import { Suspense, useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ClearQueryCacheOnAuthChange, QueryClientProvider } from "./Api";
import { AppConfigProvider } from "./AppConfig";
import { AppInsights, AppInsightsProvider } from "./AppInsights";
import { AuthProvider } from "./Auth";
import { featureFlags } from "./featureFlags";
import { PageSubTitleProvider } from "./PageSubTitleContext";
import { PageTitleProvider } from "./PageTitleContext";
import { ChoosePrinterProvider } from "./Printing";
import { SnackContainer } from "./SnackContainer";
import { SpinnerContainer } from "./SpinnerContainer";
import { useSpinner } from "./SpinnerContext";
import { ThemeProvider } from "./ThemeContext";
import { UserFeedbackProvider } from "./UserFeedbackContext";

const ConfirmProvider = ConfirmProviderWithLegacyType as React.FC<React.PropsWithChildren<ConfirmProviderProps>>;

/** Setup all the providers used across the entire app. */
export const AppContextProviders: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <JotaiProvider>
            <QueryClientProvider>
                <Suspense fallback={<SuspendFallback />}>
                    <AppConfigProvider>
                        <AppInsightsProvider>
                            <AppInsights>
                                <AuthProvider>
                                    <BrowserRouter>
                                        <ThemeProvider>
                                            <PageSubTitleProvider>
                                                <PageTitleProvider>
                                                    <UserFeedbackProvider>
                                                        <ChoosePrinterProvider>
                                                            <ConfirmProvider>{children}</ConfirmProvider>
                                                        </ChoosePrinterProvider>
                                                    </UserFeedbackProvider>
                                                </PageTitleProvider>
                                            </PageSubTitleProvider>
                                        </ThemeProvider>
                                    </BrowserRouter>
                                    <ClearQueryCacheOnAuthChange />
                                </AuthProvider>
                            </AppInsights>
                        </AppInsightsProvider>
                    </AppConfigProvider>
                    <SnackContainer />
                    <SpinnerContainer />
                </Suspense>
                {featureFlags.reactQueryDevtools && <ReactQueryDevtools />}
            </QueryClientProvider>
        </JotaiProvider>
    );
};

const SuspendFallback: React.FC = () => {
    const { startSpinner } = useSpinner();
    useEffect(() => {
        const stopSpinner = startSpinner("SuspendFallback");
        return (): void => stopSpinner();
    }, [startSpinner]);
    return <></>;
};
