import StarFilledIcon from "@material-ui/icons/Star";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import StarSharpIcon from "@material-ui/icons/StarSharp";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const starIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return StarFilledIcon;
        case IconStyle.Outlined:
            return StarOutlinedIcon;
        case IconStyle.Rounded:
            return StarRoundedIcon;
        case IconStyle.TwoTone:
            return StarTwoToneIcon;
        case IconStyle.Sharp:
            return StarSharpIcon;
    }
};

export const StarIcon = createIconComponent(starIconSelector);
