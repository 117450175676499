import BookmarkFilledIcon from "@material-ui/icons/Bookmark";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import BookmarkTwoToneIcon from "@material-ui/icons/BookmarkTwoTone";
import { createIconComponent } from "./createIconComponent";
import { IconStyle } from "./IconStyle";
import { IconSelector } from "./types";

export const bookmarkIconSelector: IconSelector = (iconStyle) => {
    switch (iconStyle) {
        default:
        case IconStyle.Filled:
            return BookmarkFilledIcon;
        case IconStyle.Outlined:
            return BookmarkOutlinedIcon;
        case IconStyle.Rounded:
            return BookmarkRoundedIcon;
        case IconStyle.TwoTone:
            return BookmarkTwoToneIcon;
        case IconStyle.Sharp:
            return BookmarkSharpIcon;
    }
};

export const BookmarkIcon = createIconComponent(bookmarkIconSelector);
