import { AppBar, Box, Divider, Drawer, DrawerProps, IconButton, makeStyles } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import * as React from "react";
import { PropsWithChildren, useMemo } from "react";
import { useUserSettings } from "./Api";
import { ChevronLeftIcon } from "./Icons";
import { useMainMenuOpen } from "./useMainMenuOpen";
import { MainMenuLayout } from "./UserSettings";

const drawerWidth = 240;

const useAppFrameStyles = makeStyles(() =>
    createStyles({
        responsiveAppFrame: {
            display: "flex",
            flexGrow: 1,
            minHeight: "100vh",
        },
        persistentAppFrame: {
            display: "flex",
            minHeight: "100vh",
        },
    })
);

export const AppFrame: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { mainMenuLayout } = useUserSettings();
    const classes = useAppFrameStyles();
    return useMemo(() => {
        const className =
            mainMenuLayout === MainMenuLayout.Responsive ? classes.responsiveAppFrame : classes.persistentAppFrame;
        return <Box className={className}>{children}</Box>;
    }, [children, classes.persistentAppFrame, classes.responsiveAppFrame, mainMenuLayout]);
};

interface AppHeaderProps {
    mainMenuOpen: boolean;
}

const useAppHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        responsiveAppHeader: {
            [theme.breakpoints.up("sm")]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        persistentAppHeader: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        persistentShiftAppHeader: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    })
);

export const AppHeader: React.FC<PropsWithChildren<AppHeaderProps>> = ({ children, mainMenuOpen }) => {
    const { mainMenuLayout } = useUserSettings();
    const classes = useAppHeaderStyles();
    return useMemo(() => {
        const className =
            mainMenuLayout === MainMenuLayout.Responsive
                ? classes.responsiveAppHeader
                : mainMenuOpen
                ? classes.persistentShiftAppHeader
                : classes.persistentAppHeader;
        return (
            <AppBar position="fixed" className={className}>
                {children}
            </AppBar>
        );
    }, [mainMenuLayout, classes, children, mainMenuOpen]);
};

interface AppHeaderButtonProps {
    mainMenuOpen: boolean;
    onClick(): void;
}

const useAppHeaderButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        responsiveAppHeaderButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        persistentAppHeaderButton: {
            marginRight: theme.spacing(2),
        },
        hideAppHeaderButton: {
            display: "none",
        },
    })
);

export const AppHeaderButtonFC: React.FC<PropsWithChildren<AppHeaderButtonProps>> = ({
    children,
    mainMenuOpen,
    onClick,
}) => {
    const { mainMenuLayout } = useUserSettings();
    const classes = useAppHeaderButtonStyles();
    const className =
        mainMenuLayout === MainMenuLayout.Responsive
            ? classes.responsiveAppHeaderButton
            : clsx(classes.persistentAppHeaderButton, mainMenuOpen && classes.hideAppHeaderButton);
    return useMemo(
        () => (
            <IconButton className={className} color="inherit" aria-label="open drawer" edge="start" onClick={onClick}>
                {children}
            </IconButton>
        ),
        [children, className, onClick]
    );
};

export const AppHeaderButton = React.memo(AppHeaderButtonFC);

const useAppHeaderSpacerStyles = makeStyles((theme: Theme) =>
    createStyles({
        appHeaderSpacer: {
            ...theme.mixins.toolbar,
        },
    })
);

export const AppHeaderSpacer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const classes = useAppHeaderSpacerStyles();
    const className = classes.appHeaderSpacer;
    return <Box className={className}>{children}</Box>;
};

/**
 * The toolbar mixin provided by MaterialUI is a set of css styles that adjusts the layout to place content below a
 * toolbar.  Let's convert that css style object into a style that can be used to position a "fixed" element that goes
 * below a toolbar.
 * @param toolbarMixin toolbar mixin provided by MaterialUI
 */
const convertToolbarMixinToFixedLayoutMixin: (toolbarMixin: CSSProperties) => CSSProperties = (toolbarMixin) => {
    return Object.keys(toolbarMixin).reduce(
        (acc, next) => ({
            ...acc,
            [next]: { top: (toolbarMixin[next] as CSSProperties)?.minHeight },
        }),
        { top: toolbarMixin.minHeight }
    );
};

const useAppTerminalWorkspaceStyles = makeStyles((theme: Theme) => {
    const fixedLayoutMixin = convertToolbarMixinToFixedLayoutMixin(theme.mixins.toolbar);
    return createStyles({
        responsiveTerminalWorkspace: {
            ...fixedLayoutMixin,
            position: "fixed",
            left: 0,
            [theme.breakpoints.up("sm")]: {
                left: drawerWidth,
            },
            bottom: 0,
            right: 0,
            transition: theme.transitions.create("left", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        persistentTerminalWorkspace: {
            ...fixedLayoutMixin,
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            transition: theme.transitions.create("left", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        persistentTerminalWorkspaceShift: {
            ...fixedLayoutMixin,
            position: "fixed",
            transition: theme.transitions.create("left", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            left: drawerWidth,
            top: theme.mixins.toolbar.minHeight,
            bottom: 0,
            right: 0,
        },
    });
});

export const AppTerminalWorkspace: React.FC<React.PropsWithChildren<{ style?: React.CSSProperties }>> = ({
    style,
    children,
}) => {
    const { mainMenuLayout } = useUserSettings();
    const [mainMenuOpen] = useMainMenuOpen();
    const classes = useAppTerminalWorkspaceStyles();
    const className = useMemo(
        () =>
            clsx({
                [classes.responsiveTerminalWorkspace]: mainMenuLayout === MainMenuLayout.Responsive,
                [classes.persistentTerminalWorkspace]: mainMenuLayout === MainMenuLayout.Manual && !mainMenuOpen,
                [classes.persistentTerminalWorkspaceShift]: mainMenuLayout === MainMenuLayout.Manual && mainMenuOpen,
            }),
        [classes, mainMenuLayout, mainMenuOpen]
    );

    return useMemo(() => {
        return (
            <div className={className} style={style}>
                {children}
            </div>
        );
    }, [className, style, children]);
};

interface AppMainProps extends React.PropsWithChildren<{}> {
    unpadded?: boolean;
}

const useAppMainStyles = makeStyles((theme: Theme) =>
    createStyles({
        responsiveAppMain: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        responsiveUnpaddedAppMain: {
            flexGrow: 1,
            padding: theme.spacing(0),
        },
        persistentAppMain: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        persistentUnpaddedAppMain: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            padding: theme.spacing(0),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        persistentShiftAppMain: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        persistentShiftUnpaddedAppMain: {
            flexGrow: 1,
            padding: theme.spacing(0),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    })
);

export const AppMain: React.FC<AppMainProps> = ({ children, unpadded }) => {
    const { mainMenuLayout } = useUserSettings();
    const classes = useAppMainStyles();
    const [mainMenuOpen] = useMainMenuOpen();
    const className = clsx({
        [classes.responsiveAppMain]: mainMenuLayout === MainMenuLayout.Responsive && !unpadded,
        [classes.responsiveUnpaddedAppMain]: mainMenuLayout === MainMenuLayout.Responsive && unpadded,
        [classes.persistentAppMain]: mainMenuLayout === MainMenuLayout.Manual && !unpadded && !mainMenuOpen,
        [classes.persistentUnpaddedAppMain]: mainMenuLayout === MainMenuLayout.Manual && unpadded && !mainMenuOpen,
        [classes.persistentShiftAppMain]: mainMenuLayout === MainMenuLayout.Manual && !unpadded && mainMenuOpen,
        [classes.persistentShiftUnpaddedAppMain]: mainMenuLayout === MainMenuLayout.Manual && unpadded && mainMenuOpen,
    });
    return (
        <Box component="main" className={className}>
            {children}
        </Box>
    );
};

const useAppNavStyles = makeStyles((theme: Theme) =>
    createStyles({
        responsiveAppNav: {
            [theme.breakpoints.up("sm")]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        persistentAppNav: {
            width: drawerWidth,
            flexShrink: 0,
        },
        persistentShiftAppNav: {
            width: drawerWidth,
            flexShrink: 0,
        },
    })
);

export const AppNav: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { mainMenuLayout } = useUserSettings();
    const [mainMenuOpen] = useMainMenuOpen();
    const classes = useAppNavStyles();
    const className = clsx({
        [classes.responsiveAppNav]: mainMenuLayout === MainMenuLayout.Responsive,
        [classes.persistentAppNav]: mainMenuLayout === MainMenuLayout.Manual && !mainMenuOpen,
        [classes.persistentShiftAppNav]: mainMenuLayout === MainMenuLayout.Manual && mainMenuOpen,
    });
    return (
        <Box component="nav" className={className}>
            {children}
        </Box>
    );
};

const useAppDrawerStyles = makeStyles((theme: Theme) =>
    createStyles({
        responsivePaperAppDrawer: {
            width: drawerWidth,
        },
        persistentPaperAppDrawer: {
            width: drawerWidth,
        },
        drawerHeaderAppDrawer: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-end",
        },
    })
);

export const AppDrawer: React.FC<Omit<DrawerProps, "classes">> = ({ children, ...rest }) => {
    const { mainMenuLayout } = useUserSettings();
    const classes = useAppDrawerStyles();
    const [_, setMainMenuOpen] = useMainMenuOpen();

    return useMemo(() => {
        const className =
            mainMenuLayout === MainMenuLayout.Responsive
                ? classes.responsivePaperAppDrawer
                : classes.persistentPaperAppDrawer;

        const drawerCloseButton =
            mainMenuLayout === MainMenuLayout.Responsive ? (
                <>
                    <div className={classes.drawerHeaderAppDrawer}></div>
                    <Divider />
                </>
            ) : (
                <>
                    <div className={classes.drawerHeaderAppDrawer}>
                        <IconButton onClick={() => setMainMenuOpen(false)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                </>
            );

        return (
            <Drawer
                {...rest}
                classes={{
                    paper: className,
                }}
            >
                {drawerCloseButton}
                {children}
            </Drawer>
        );
    }, [
        children,
        classes.drawerHeaderAppDrawer,
        classes.persistentPaperAppDrawer,
        classes.responsivePaperAppDrawer,
        mainMenuLayout,
        rest,
        setMainMenuOpen,
    ]);
};
